module.exports = {
    CONTACT: 'contact',
    BLOG: 'updates',
    CPP_OVERVIEW: 'overview',
    WELCOME: '',
    DOCS: 'documentation',
    NOT_FOUND: '404',
    LICENSE_AGREEMENT: 'license',
    CPP_ELIGIBILITY: 'cppEligiblilty',
};
