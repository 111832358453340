import React from 'react';
import PropTypes from 'prop-types';

const Content = ({children}) => {
    return <div className="layout__content">{children}</div>;
};

Content.propTypes = {
    children: PropTypes.arrayOf(PropTypes.element),
};

export default Content;
