import React from 'react';
import PropTypes from 'prop-types';
import EGConnectivityIcon from '../assets/connectivity_logo_white.svg';

const Sidebar = ({activeIndex, logoLink, navLinks, show}) => {
    return (
        <div className={`side-bar ${show ? 'show' : ''}`}>
            <div className="side-bar-heading">
                <a href={logoLink}>
                    <img
                        className="header-logo"
                        src={EGConnectivityIcon}
                        alt="expedia group logo"
                    />
                </a>
            </div>
            <ul className="level0__ul">
                {navLinks.map(({node}, i) => (
                    <li
                        key={`l0-nav-${i}`}
                        className={activeIndex === i ? 'active' : ''}
                    >
                        {node}
                    </li>
                ))}
            </ul>
        </div>
    );
};

Sidebar.propTypes = {
    activeIndex: PropTypes.number.isRequired,
    show: PropTypes.bool.isRequired,
    logoLink: PropTypes.string.isRequired,
    navLinks: PropTypes.arrayOf(
        PropTypes.shape({
            node: PropTypes.element.isRequired,
        })
    ).isRequired,
    tutorialUrl: PropTypes.string,
    enableLogin: PropTypes.bool,
};

export default Sidebar;
