import React from 'react';
import PropTypes from 'prop-types';
import GreenEdge from '../../assets/angle_edge_green.svg';
import mobileEdgeMarkup from '../../assets/angle_edge_mobile.svg';
import './styles.less';

const Border = ({inverse}) => {
    return (
        <>
            <div
                className={`border-wrapper ${inverse ? 'inverse' : 'default'}`}
            >
                <div className="border-blue"></div>
                <div className="border-green">
                    <img alt="" src={GreenEdge} />
                </div>
            </div>
            <div className={`mobile-border ${inverse ? 'inverse' : 'default'}`}>
                <img alt="" src={mobileEdgeMarkup} />
            </div>
        </>
    );
};

Border.propTypes = {
    inverse: PropTypes.bool,
};

export default Border;
