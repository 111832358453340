import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {FormInput} from '@homeaway/react-form-components';
import {useRequiredTextInput} from '../../LeadsForm/hooks/formFields';
import Button from '../../UIToolkit/Button';
import {
    triggerEdapButtonClick,
    triggerEdapEvent,
    eventCategories,
} from '../../util/edap';
import superagent from 'superagent';
import {extPrefix} from '../../../../util/prefix';
import {ERROR_TYPES} from '../../util/constant';
import ErrorMessage from '../../util/ErrorMessage/ErrorMessage';
import {isValidEmail} from '../../../../util/validationRules';
import ReCAPTCHA from 'react-google-recaptcha';
import keys from '../../../../res/keys';
import {getEnv} from '../../../../util/getEnv';

const EligibilityCheck = ({translations}) => {
    const [eligibilityStatus, setEligibilityStatus] = useState(false);
    const [emailId, setEmailId] = useState('');
    const [errorType, setErrorType] = useState(null);
    const [emailIdProps] = useRequiredTextInput({
        label: translations['cppPage.eligibilityCheck.emailInput.label'],
        name: 'emailId',
        id: 'email-id',
    });

    const recaptchaRef = React.useRef(null);
    const [siteKey, setSiteKey] = useState('');

    useEffect(() => {
        const env = getEnv(window.location.host);
        const sitekey = keys[env].recaptcha.site_key_invisible;
        setSiteKey(sitekey);
    }, []);

    const submitForm = (recaptchaResponse) => {
        return superagent
            .post(`/${extPrefix}/v1/email/cppEligibility/support`)
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .timeout(6000)
            .send({
                emailAddress: emailId,
                recaptchaResponse,
            });
    };

    const handleRecaptchaChange = (recaptchaResponse) => {
        submitForm(recaptchaResponse)
            .then(() => {
                setErrorType(null);
                setEligibilityStatus(true);
                triggerEdapButtonClick(
                    eventCategories.CPP_ELIGIBILITY_CHECK,
                    'Eligibility Check'
                );
            })
            .catch(() => {
                setErrorType(ERROR_TYPES.SERVER_ERROR);
            });
    };

    const handleRecaptchaError = () => setErrorType(ERROR_TYPES.SERVER_ERROR);

    const checkEligibility = () => {
        // Quick fix, will later change email format error based on keystroke
        setErrorType(null);
        if (!isValidEmail(emailId)) {
            setErrorType(ERROR_TYPES.EMAIL_FORMAT_ERROR);
            triggerEdapEvent('generic.event', {
                eventcategory: eventCategories.CPP_ELIGIBILITY_CHECK,
                eventaction: 'Error in email format',
            });
        } else {
            recaptchaRef.current.execute();
        }
    };

    const handleEmailInputChange = (event) => {
        setEmailId(event.target.value);
    };

    const handleEmailInputFocus = () => {
        triggerEdapEvent('generic.event', {
            eventcategory: eventCategories.CPP_ELIGIBILITY_CHECK,
            eventaction: 'Focus on email input box for eligibility check',
        });
        return;
    };

    return (
        <>
            <div className="cpp-page__content__eligibility">
                <div className="eligibility-wrapper">
                    {eligibilityStatus === false && (
                        <div className="eligibility-checking-section">
                            <h3 className="section-heading">
                                {
                                    translations[
                                        'cppPage.eligibilityCheck.heading'
                                    ]
                                }
                            </h3>
                            <FormInput
                                {...emailIdProps}
                                onChange={handleEmailInputChange}
                                value={emailId}
                                onFocus={handleEmailInputFocus}
                                inputClassName={
                                    errorType === ERROR_TYPES.EMAIL_FORMAT_ERROR
                                        ? 'input-border-red'
                                        : ''
                                }
                            />
                            <div
                                className={
                                    errorType === ERROR_TYPES.EMAIL_FORMAT_ERROR
                                        ? ''
                                        : 'hidden'
                                }
                            >
                                <ErrorMessage
                                    message={
                                        translations[
                                            'cppPage.eligibilityCheck.emailFormat.error.message'
                                        ]
                                    }
                                    additionalClassName="email-format-error-wrapper"
                                />
                            </div>

                            <ReCAPTCHA
                                sitekey={siteKey}
                                size="invisible"
                                onChange={handleRecaptchaChange}
                                onErrored={handleRecaptchaError}
                                ref={recaptchaRef}
                            />

                            <Button
                                dataTestId="eligibility-check-button"
                                additionalClassNames="eligibility-check-button"
                                label={
                                    translations[
                                        'cppPage.eligibilityCheck.button.label'
                                    ]
                                }
                                link={{
                                    href: `#`,
                                }}
                                onClick={checkEligibility}
                            />
                            <div
                                className={
                                    errorType === ERROR_TYPES.SERVER_ERROR
                                        ? ''
                                        : 'hidden'
                                }
                            >
                                <ErrorMessage
                                    message={
                                        translations[
                                            'cppPage.eligibilityCheck.service.error.message'
                                        ]
                                    }
                                    additionalClassName="server-error-wrapper"
                                />
                            </div>
                            <p className="section-text">
                                {
                                    translations[
                                        'cppPage.eligibilityCheck.additionalInfo.text'
                                    ]
                                }
                            </p>
                        </div>
                    )}
                    {eligibilityStatus === true && (
                        <div className="eligibility-mail-success-section">
                            <h3 className="section-heading">
                                {
                                    translations[
                                        'cppPage.eligibilityCheck.success.message'
                                    ]
                                }
                            </h3>
                            <p className="section-text">
                                {
                                    translations[
                                        'cppPage.eligibilityCheck.success.additionalInfo'
                                    ]
                                }
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

EligibilityCheck.propTypes = {
    translations: PropTypes.object.isRequired,
};

export default EligibilityCheck;
