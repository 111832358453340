import React from 'react';
import {Link} from 'react-router-dom';
import {triggerEdapLinkClick, eventCategories} from './edap';

export default (content) => {
    const linkRegExp = /\[[^\]]+\|[^\]]+\]/g;
    const linksFound = content.match(linkRegExp);
    if (!linksFound) {
        return content;
    }
    const links =
        linksFound &&
        linksFound.map((link) => {
            const [label, href] = link.replace(/^\[|\]$/g, '').split('|');
            return {
                label,
                href,
            };
        });
    const contentChunks = content.split(linkRegExp);
    const formattedContent = [];
    contentChunks.forEach((chunk, i) => {
        formattedContent.push(chunk);
        if (links[i]) {
            const {href, label} = links[i];
            const handleClick = triggerEdapLinkClick(
                eventCategories.CONTENT_LINKS,
                `${label}|${href}]`
            );
            const linkElement = href.startsWith('http') ? (
                <a
                    target="__blank"
                    key={`link-${i}`}
                    href={href}
                    onClick={handleClick}
                >
                    {label}
                </a>
            ) : (
                <Link to={href} onClick={handleClick}>
                    {label}
                </Link>
            );
            formattedContent.push(linkElement);
        }
    });
    return formattedContent;
};
