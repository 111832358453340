import React, {useEffect} from 'react';
import superagent from 'superagent';
import ProgressBar from '../../UIToolkit/ProgressBar';
import {isEmpty} from '../../../../util/validationRules';
import {Button, ButtonPrimary} from '@homeaway/react-buttons';
import {extPrefix} from '../../../../util/prefix';
import {triggerEdapEvent, eventCategories} from '../../util/edap';
import pciResponses from '../../../../res/pciResponses';
import leadsFormStates from '../../../../res/leadsFormStates';
import allUserTypes from '../../../../res/userTypes';
import solutionTypeOptions from '../../../../res/solutionTypes';
import URLS from '../../../../util/urls';
import PropTypes from 'prop-types';
import {
    appStatePropType,
    userTypePropType,
    pciCompliancePropType,
    solutionPropType,
} from '../../util/propTypes';
import './styles.less';

const LeadsFormModalFooter = (props) => {
    const {
        translations,
        updateLeadsForm,
        appState,
        recaptchaRef,
        recaptchaResponse,
    } = props;

    if (!appState || appState === leadsFormStates.COMPLETE) {
        return null;
    }

    useEffect(() => {
        if (recaptchaResponse) {
            updateLeadsForm('loading', true);
            return submitForm()
                .then(() => {
                    updateLeadsForm('error', false);
                    updateLeadsForm('appState', leadsFormStates.COMPLETE);
                    updateLeadsForm('loading', false);
                })
                .catch(() => {
                    if (recaptchaRef.current) {
                        recaptchaRef.current.reset();
                    }
                    updateLeadsForm(
                        'error',
                        translations['leadsForm.errorMessage.processingError']
                    );
                    updateLeadsForm('loading', false);
                });
        }
    }, [recaptchaResponse]);

    const submitForm = () => {
        const leadsFormData = {
            propertyCount: props.propertyCount,
            firstName: props.firstName,
            lastName: props.lastName,
            jobTitle: props.jobTitle,
            emailAddress: props.emailAddress,
            phoneNumber: `+${props.countryCode} ${props.phoneNumber}`,
            companyName: props.companyName,
            website: URLS.format(props.website),
            addressCountry: props.addressCountry,
            addressLine1: props.addressLine1,
            addressLine2: props.addressLine2,
            city: props.city,
            state: props.state,
            zip: props.zip,
            solutionType: props.solutionType,
            otherDistributors: props.otherDistributors,
            userMessage: props.userMessage,
            pciCompliant: props.pciCompliant,
        };

        return superagent
            .post(`/${extPrefix}/api/submitLeadsForm`)
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .timeout(6000)
            .send({
                crumb: window.crumb,
                recaptchaResponse,
                leadsForm: leadsFormData,
            });
    };

    const handleBackClick = () => {
        triggerEdapEvent('generic.event', {
            eventcategory: eventCategories.LEADS_FORM,
            eventaction: 'Back button clicked',
        });
        let newAppState;
        switch (appState) {
            case leadsFormStates.CONTACT_INFO_NOTICE:
            case leadsFormStates.CONTACT_INFO:
                newAppState = leadsFormStates.USER_TYPE;
                break;
            case leadsFormStates.COMPANY_INFO:
                newAppState = leadsFormStates.CONTACT_INFO;
                updateLeadsForm('recaptchaResponse', null);
                break;

            default:
                newAppState = leadsFormStates.USER_TYPE;
                break;
        }
        updateLeadsForm('appState', newAppState);
    };

    const handleNextClick = () => {
        let {appState, updateLeadsForm} = props;
        triggerEdapEvent('generic.event', {
            eventcategory: eventCategories.LEADS_FORM,
            eventaction: `${
                appState === leadsFormStates.COMPANY_INFO ? 'Submit' : 'Next'
            } button clicked`,
        });
        if (appState === leadsFormStates.COMPANY_INFO) {
            recaptchaRef.current.execute();
        }

        let newAppState;
        switch (appState) {
            case leadsFormStates.USER_TYPE:
                newAppState =
                    props.userType === allUserTypes.CPSP
                        ? leadsFormStates.CONTACT_INFO
                        : leadsFormStates.COMPLETE;
                break;
            case leadsFormStates.CONTACT_INFO_NOTICE:
                newAppState = leadsFormStates.CONTACT_INFO;
                break;
            case leadsFormStates.CONTACT_INFO:
                newAppState = leadsFormStates.COMPANY_INFO;
                break;
        }
        return appState === leadsFormStates.COMPANY_INFO
            ? null
            : updateLeadsForm('appState', newAppState);
    };

    const validateContactInfo = () => {
        let requiredFields = [
            'firstName',
            'lastName',
            'jobTitle',
            'emailAddress',
            'phoneNumber',
            'companyName',
            'website',
            'propertyCount',
            'addressCountry',
            'addressLine1',
            'city',
            'state',
            'zip',
        ];
        let optionalFields = ['addressLine2'];
        let anyErrors = requiredFields
            .concat(optionalFields)
            .find((field) => props[`${field}Error`]);
        if (anyErrors) {
            return false;
        }
        return requiredFields.every((field) => !isEmpty(props[field]));
    };

    const validateCompanyInfo = () =>
        Object.values(solutionTypeOptions).includes(props.solutionType) &&
        !isEmpty(props.otherDistributors) &&
        Object.values(pciResponses).includes(props.pciCompliant);

    const validateUserType = () =>
        Object.values(allUserTypes).includes(props.userType);

    const isStateValid = (appState) => {
        let valid;
        switch (appState) {
            case leadsFormStates.USER_TYPE:
                valid = validateUserType();
                break;
            case leadsFormStates.CONTACT_INFO_NOTICE:
                valid = true;
                break;
            case leadsFormStates.CONTACT_INFO:
                valid = validateContactInfo();
                break;
            case leadsFormStates.COMPANY_INFO:
                valid = validateCompanyInfo();
                break;
            default:
                valid = false;
        }
        return valid;
    };

    const isFormValid = () => isStateValid(appState);

    const validateAllStates = () =>
        [
            leadsFormStates.USER_TYPE,
            leadsFormStates.CONTACT_INFO,
            leadsFormStates.COMPANY_INFO,
        ].map((state) => ({
            state,
            valid: isStateValid(state),
        }));

    return (
        <div className="leads-form-modal-footer">
            <div className="leads-form-modal-footer-content">
                <Button
                    disabled={appState === leadsFormStates.USER_TYPE}
                    label={translations['leadsForm.message.back']}
                    value="Back"
                    onClick={handleBackClick}
                />
                <ProgressBar
                    stateArray={validateAllStates()}
                    appState={appState}
                />
                <ButtonPrimary
                    disabled={!isFormValid()}
                    label={
                        appState === leadsFormStates.COMPANY_INFO
                            ? translations['leadsForm.message.submit']
                            : translations['leadsForm.message.next']
                    }
                    onClick={handleNextClick}
                    value={
                        appState === leadsFormStates.COMPANY_INFO
                            ? 'Submit'
                            : 'Next'
                    }
                />
            </div>
        </div>
    );
};

LeadsFormModalFooter.propTypes = {
    updateLeadsForm: PropTypes.func,
    translations: PropTypes.object,
    propertyCount: PropTypes.string,
    addressCountry: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    userType: userTypePropType,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    jobTitle: PropTypes.string,
    emailAddress: PropTypes.string,
    countryCode: PropTypes.string,
    phoneNumber: PropTypes.string,
    companyName: PropTypes.string,
    website: PropTypes.string,
    recaptchaResponse: PropTypes.string,
    appState: appStatePropType,
    pciCompliant: pciCompliancePropType,
    solutionType: solutionPropType,
    otherDistributors: PropTypes.string,
    userMessage: PropTypes.string,
    recaptchaRef: PropTypes.object,
};

export default LeadsFormModalFooter;
