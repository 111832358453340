import React from 'react';
import PropTypes from 'prop-types';
import EGLogo from '../assets/eg_logo.svg';
import {eventCategories, triggerEdapEvent} from '../util/edap';
import './styles.less';

const FOOTER_LINKS = [
    {
        value: 'Terms of use',
    },
    {
        value: 'Privacy policy',
    },
    {
        value: 'License agreement',
    },
    {
        value: 'Expedia group cookie policy',
    },
    {
        value: 'Government regulations',
    },
    {
        value: 'Partner central help',
    },
    {
        value: 'Contact us',
    },
];

const Footer = ({translations}) => {
    const handleLinkClick = (value) => () => {
        triggerEdapEvent(
            'generic.event',
            {
                eventcategory: eventCategories.FOOTER_LINKS,
                eventaction: `${value} link clicked`,
            },
            {}
        );
    };

    const renderLinks = () =>
        FOOTER_LINKS.map((link, i) => {
            const linkIndex = i + 1;
            return (
                <span key={`footer-link-${i}`}>
                    <a
                        onClick={handleLinkClick(link.value)}
                        className="footer-link"
                        href={translations[`footer.link${linkIndex}.link`]}
                        target="__blank"
                    >
                        {translations[`footer.link${linkIndex}.label`]}
                    </a>
                </span>
            );
        });

    return (
        <div className="footer">
            <div className="footer-content">
                <img src={EGLogo} alt="expedia group logo" />
                <div className="footer-links">{renderLinks()}</div>
                <div className="footer-text">{`© ${new Date().getFullYear()} ${
                    translations['footer.text']
                }`}</div>
            </div>
        </div>
    );
};

Footer.propTypes = {
    translations: PropTypes.object.isRequired,
};

export default Footer;
