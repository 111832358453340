import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './styles.less';
import {SVGIcon} from '@homeaway/react-svg';
import {ALERT__24, CLOSE__24} from '@homeaway/svg-defs';

export default class AlertBox extends Component {
    constructor(props) {
        super(props);
        this.closeAlert = this.closeAlert.bind(this);
    }

    closeAlert() {
        this.props.updateLeadsForm('error', null);
    }

    render() {
        let {message} = this.props;
        return (
            <div className="alert-box">
                <div className="alert-icon">
                    <SVGIcon markup={ALERT__24} />
                </div>
                <div className="alert-message">{message}</div>
                <button
                    className="alert-close-button"
                    onClick={this.closeAlert}
                >
                    <SVGIcon markup={CLOSE__24} />
                </button>
            </div>
        );
    }
}

AlertBox.propTypes = {
    message: PropTypes.string,
    updateLeadsForm: PropTypes.func,
};
