import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import './styles.less';

const Button = ({
    dataTestId,
    label,
    onClick,
    link,
    primary,
    additionalClassNames,
}) => {
    const {href, external, newTab} = link;
    const renderLink = () =>
        external ? (
            // eslint-disable-next-line react/jsx-no-target-blank
            <a
                tabIndex={0}
                href={href}
                target={newTab ? '_blank' : '_self'}
                onClick={onClick}
                className={`api-dev-portal__button ${
                    primary ? 'button--primary' : 'button--reg'
                } ${additionalClassNames}`}
                data-testid={dataTestId}
                rel={newTab && 'noreferrer'}
            >
                {label}
            </a>
        ) : (
            <Link
                to={href}
                onClick={onClick}
                className={`api-dev-portal__button ${
                    primary ? 'button--primary' : 'button--reg'
                } ${additionalClassNames}`}
                data-testid={dataTestId}
            >
                {label}
            </Link>
        );

    return link ? (
        renderLink()
    ) : (
        <button
            onClick={onClick}
            className={`api-dev-portal__button ${
                primary ? 'button--primary' : 'button--reg'
            } ${additionalClassNames}`}
            data-testid={dataTestId}
        >
            {label}
        </button>
    );
};

Button.propTypes = {
    primary: PropTypes.bool,
    label: PropTypes.string.isRequired,
    link: PropTypes.shape({
        href: PropTypes.string.isRequired,
        external: PropTypes.bool,
        newTab: PropTypes.bool,
    }),
    onClick: PropTypes.func,
    additionalClassNames: PropTypes.string,
    dataTestId: PropTypes.string,
};

export default Button;
