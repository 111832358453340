const appName = 'EG-PartnerPortal';

function triggerEdapEvent(eventName = '', eventData = {}, options = {}) {
    /* istanbul ignore if */
    if (typeof window === 'undefined') {
        return;
    }
    if (eventData.pagename) {
        eventData.pagename = `${appName}:${eventData.pagename}`;
    }
    window.edap.push((edapInstance) => {
        edapInstance.trigger(eventName, eventData, options);
    });
}

function triggerEdapPageView(pageName = '') {
    /* istanbul ignore if */
    if (typeof window === 'undefined') {
        return;
    }
    window.analyticsdatalayer.pagename = `${appName}:${pageName}`;
    window.edap.push((edapInstance) => {
        edapInstance.trigger('pageview');
    });
}

const triggerEdapLinkClick = (eventCategory, value) => () => {
    triggerEdapEvent('generic.event', {
        eventcategory: eventCategory,
        eventaction: `${value} link clicked`,
    });
};

const triggerEdapButtonClick = (eventCategory, value) => () => {
    triggerEdapEvent('generic.event', {
        eventcategory: eventCategory,
        eventaction: `${value} button clicked`,
    });
};

const eventCategories = {
    NAVIGATION: 'Navigation',
    CONTENT_LINKS: 'Content links',
    FOOTER_LINKS: 'Footer links',
    LEADS_FORM: 'Leads form',
    LANDING: 'Landing',
    CPP_ELIGIBILITY_CHECK: 'CPP Eligibility Check',
};

const eventPageName = {
    CPP_OVERVIEW_PAGE: 'CPP Overview Page',
};

export {
    triggerEdapPageView,
    triggerEdapEvent,
    triggerEdapLinkClick,
    triggerEdapButtonClick,
    eventCategories,
    eventPageName,
};
