import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {hot} from 'react-hot-loader';
import App from './App';

function ClientApp(props) {
    return (
        <BrowserRouter>
            <App
                translations={props.translations}
                prodTestConfig={props.prodTestConfig}
            />
        </BrowserRouter>
    );
}

ClientApp.propTypes = {
    translations: PropTypes.object.isRequired,
    prodTestConfig: PropTypes.object,
};

export default hot(module)(ClientApp);
