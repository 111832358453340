import React from 'react';
import PropTypes from 'prop-types';

const ContactInfoNotice = ({translations}) => {
    return (
        <div>
            <div className="leads-form-section-heading">
                {translations['leadsForm.contactInfoNotice.header']}
            </div>
            <p>{translations['leadsForm.contactInfoNotice.thanks']}</p>
            <p>
                {translations['leadsForm.contactInfoNotice.integrationsPaused']}
            </p>
            <p>{translations['leadsForm.contactInfoNotice.information']}</p>
        </div>
    );
};

ContactInfoNotice.propTypes = {
    translations: PropTypes.objectOf(PropTypes.string),
};

export default ContactInfoNotice;
