import React from 'react';
import PropTypes from 'prop-types';
import {AlertInfo} from '@homeaway/react-alerts';

import './styles.less';

function AlertMessage({title, message}) {
    return (
        <div className="alert-msg">
            <AlertInfo
                className="alert-content"
                title={title}
                msg={message}
                flairClassName="hidden"
            />
        </div>
    );
}

AlertMessage.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string.isRequired,
};

export default AlertMessage;
