import React from 'react';
import PropTypes from 'prop-types';
import Logger from '../../../universal/client-error-logging/client/logger';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError() {
        return {hasError: true};
    }

    componentDidCatch(error) {
        const {code} = this.props;

        Logger.logError({code, error});
    }

    render() {
        const {state, props} = this;
        const {fallbackView, children} = props;
        return state.hasError ? fallbackView : children;
    }
}

ErrorBoundary.propTypes = {
    fallbackView: PropTypes.node.isRequired,
    children: PropTypes.any.isRequired,
    code: PropTypes.string.isRequired,
};

export default ErrorBoundary;
