export const getDocsPageHostUrl = () => {
    if (typeof window !== 'undefined') {
        let {host, protocol} = window.location;
        if (
            host.includes('localhost') ||
            host.includes('127.0.0.1') ||
            host.includes('test')
        ) {
            host = 'test.developers.expediagroup.com';
        } else {
            host = 'developers.expediagroup.com';
        }

        return `${protocol}//${host}`;
    }
};
