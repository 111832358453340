import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import Footer from './Footer';
import Content from './Content';
import Sidebar from './Sidebar';
import {
    eventCategories,
    triggerEdapButtonClick,
    triggerEdapLinkClick,
} from '../util/edap';
import {extPrefix} from '../../../util/prefix';
import links from '../../../util/navLinks';
import {Link} from 'react-router-dom';

const Layout = ({
    header,
    content,
    translations,
    activeIndex,
    prodTestConfig,
}) => {
    const [tutorialUrl, setTutorialUrl] = useState('');
    const [enableLogin, setEnableLogin] = useState(false);
    const sidebarRef = useRef();
    const [showSidebar, setSidebarVisibility] = useState(false);
    const [animate, setAnimation] = useState(false);

    const welcomeLink = `/${extPrefix}${links.WELCOME}`;
    let documentationLink = `/${extPrefix}/${links.DOCS}`;

    if (prodTestConfig?.enableTutorial) {
        documentationLink = `${documentationLink}/?enableTutorial=${prodTestConfig.enableTutorial}`;
    }

    const navLinkItems = [
        {
            href: welcomeLink,
            label: translations['header.nav.products.label'],
            value: 'Welcome',
            external: true,
        },
        {
            href: documentationLink,
            label: translations['header.nav.documentation.label'],
            value: 'Documentation',
            external: true,
        },
        {
            href: `/${extPrefix}/${links.BLOG}`,
            label: translations['header.nav.latest.label'],
            value: 'Whats new',
            external: true,
        },
    ];
    {
        /** Do not change data-testid attr */
    }
    const navLinks = navLinkItems.map(
        ({label, href, value, external, newTab}) => ({
            node: external ? (
                // eslint-disable-next-line react/jsx-no-target-blank
                <a
                    target={newTab ? '_blank' : '_self'}
                    href={href}
                    onClick={triggerEdapLinkClick(
                        eventCategories.NAVIGATION,
                        value
                    )}
                    data-testid={`nav-${value
                        .toLowerCase()
                        .replace(/ /g, '-')}`}
                >
                    {label}
                </a>
            ) : (
                <Link
                    to={href}
                    onClick={triggerEdapLinkClick(
                        eventCategories.NAVIGATION,
                        value
                    )}
                    data-testid={`nav-${value
                        .toLowerCase()
                        .replace(/ /g, '-')}`}
                >
                    {label}
                </Link>
            ),
        })
    );

    const closeSidebar = () => {
        setSidebarVisibility(false);
    };

    // mobile view - Renders left section when user clicks menu button
    const handleMenuClick = () => {
        setSidebarVisibility(true);
        // animation is enabled once user starts interacting with the page.
        if (!animate) {
            setAnimation(true);
        }
    };

    const handleNavLinkClick = (e, i) => {
        triggerEdapLinkClick(
            eventCategories.NAVIGATION,
            navLinkItems[i].value
        )();
    };

    const handleSearchIconClick = (event) => {
        event.preventDefault();
        triggerEdapButtonClick(
            eventCategories.LANDING,
            'Header - Search icon clicked'
        )();
        window.open(`/${extPrefix}/${links.DOCS}/?search=true`, '_self');
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            let redirectUrl = '';
            let {protocol, host} = window.location;
            let loginFlag = false;
            if (host && (host.includes('localhost') || host.includes('test'))) {
                host = 'test.developers.expediagroup.com';
                loginFlag = true;
            } else {
                host = 'developers.expediagroup.com';
            }
            redirectUrl = `${protocol}//${host}/${extPrefix}/docs/avail_and_rate_apis/promotions/tutorial/promo_integration`;
            setTutorialUrl(redirectUrl);
            setEnableLogin(loginFlag);
        }
        // mobile view - Closes left section when user clicks outside it.
        const handleClickOutsideLeftSection = (e) => {
            const sidebarNode = sidebarRef.current;
            if (!sidebarNode || sidebarNode.contains(e.target)) {
                return;
            }
            closeSidebar();
        };
        document.addEventListener('mousedown', handleClickOutsideLeftSection);
        return () => {
            document.removeEventListener(
                'mousedown',
                handleClickOutsideLeftSection
            );
        };
    }, []);

    return (
        <>
            <div ref={sidebarRef}>
                <Sidebar
                    show={showSidebar}
                    logoLink={welcomeLink}
                    activeIndex={activeIndex}
                    navLinks={navLinks}
                    tutorialUrl={tutorialUrl}
                    enableLogin={enableLogin}
                />
            </div>
            {showSidebar && <div className="overlay" />}
            <div className="page-layout">
                <Header
                    activeIndex={activeIndex}
                    logoLink={welcomeLink}
                    navLinks={navLinks}
                    onMobileMenuClick={handleMenuClick}
                    onHeaderLinkClick={handleNavLinkClick}
                    onSearchIconClick={handleSearchIconClick}
                    tutorialUrl={tutorialUrl}
                    enableLogin={enableLogin}
                >
                    {header}
                </Header>
                <Content>{content}</Content>
                <Footer translations={translations} />
            </div>
        </>
    );
};

Layout.propTypes = {
    prodTestConfig: PropTypes.object,
    header: PropTypes.element,
    content: PropTypes.arrayOf(PropTypes.element).isRequired,
    translations: PropTypes.object.isRequired,
    activeIndex: PropTypes.number.isRequired,
};

export default Layout;
