import React, {useEffect} from 'react';
import InputTel from '@homeaway/react-input-tel';
import FormSelect from '@homeaway/react-form-select';
import {
    isEmpty,
    isValidEmail,
    isValidFormattedWebsite,
    isValidPropertyCount,
} from '../../../../util/validationRules';
import {countries} from '../../../../util/countries';
import {FormInput} from '@homeaway/react-form-components';
import {
    triggerEdapPageView,
    triggerEdapEvent,
    eventCategories,
} from '../../util/edap';
import {
    useTextInput,
    useRequiredTextInput,
    useSelect,
} from '../hooks/formFields';
import emptyOption from '../../../../res/emptyOption';
import PropTypes from 'prop-types';
import './styles.less';

const countryOptions = Object.keys(countries)
    .map((code) => ({
        value: countries[code],
        label: countries[code],
    }))
    .sort((countryOp1, countryOp2) => {
        return countryOp1.label.localeCompare(countryOp2.label);
    });

countryOptions.unshift(emptyOption);

const ContactInfo = ({
    translations,
    updateLeadsForm,
    firstName,
    firstNameError,
    lastName,
    lastNameError,
    jobTitle,
    jobTitleError,
    emailAddress,
    emailAddressError,
    country,
    phoneNumber,
    phoneNumberError,
    companyName,
    companyNameError,
    website,
    websiteError,
    propertyCount,
    propertyCountError,
    addressCountry,
    addressLine1,
    addressLine1Error,
    addressLine2,
    city,
    cityError,
    state,
    stateError,
    zip,
    zipError,
}) => {
    const handlePhoneFocus = () => {
        triggerEdapEvent('generic.event', {
            eventcategory: eventCategories.LEADS_FORM,
            eventaction: 'Form input focus - phoneNumber',
        });
    };

    const handlePhoneBlur = (e, phoneObject) => {
        let error = null;
        if (isEmpty(phoneObject.raw)) {
            error = `${translations['leadsForm.contactInfo.phoneNumber']} ${translations['leadsForm.errorMessage.empty']}`;
        }
        updateLeadsForm('phoneNumberError', error);
    };

    const handlePhoneChange = (e, phoneObject) => {
        updateLeadsForm('phoneNumber', phoneObject.raw);
    };

    const handleCountryCodeChange = (e, countryData) => {
        updateLeadsForm('country', countryData.cc);
        updateLeadsForm('countryCode', countryData.phoneCode);
        triggerEdapEvent('generic.event', {
            eventcategory: eventCategories.LEADS_FORM,
            eventaction: `Country code changed to ${countryData.phoneCode}`,
        });
    };

    const commonInputProps = {
        leadsFormUpdater: updateLeadsForm,
        translations,
    };

    const [firstNameProps] = useRequiredTextInput({
        ...commonInputProps,
        label: translations['leadsForm.contactInfo.firstName'],
        name: 'firstName',
    });

    const [lastNameProps] = useRequiredTextInput({
        ...commonInputProps,
        label: translations['leadsForm.contactInfo.lastName'],
        name: 'lastName',
    });

    const [jobTitleProps] = useRequiredTextInput({
        ...commonInputProps,
        label: translations['leadsForm.contactInfo.jobTitle'],
        name: 'jobTitle',
    });

    const [companyNameProps] = useRequiredTextInput({
        ...commonInputProps,
        label: translations['leadsForm.contactInfo.companyName'],
        name: 'companyName',
    });

    const [addressLine1Props] = useRequiredTextInput({
        ...commonInputProps,
        label: translations['leadsForm.contactInfo.addressLine1'],
        name: 'addressLine1',
    });

    const [addressLine2Props] = useTextInput({
        ...commonInputProps,
        label: translations['leadsForm.contactInfo.addressLine2'],
        id: translations['leadsForm.contactInfo.addressLine2'],
        name: 'addressLine2',
    });

    const [cityProps] = useRequiredTextInput({
        ...commonInputProps,
        label: translations['leadsForm.contactInfo.city'],
        name: 'city',
    });

    const [stateProps] = useRequiredTextInput({
        ...commonInputProps,
        label: translations['leadsForm.contactInfo.state'],
        name: 'state',
    });

    const [zipProps] = useRequiredTextInput({
        ...commonInputProps,
        label: translations['leadsForm.contactInfo.zip'],
        name: 'zip',
    });

    const [emailAddressProps] = useRequiredTextInput({
        ...commonInputProps,
        label: translations['leadsForm.contactInfo.emailAddress'],
        name: 'emailAddress',
        additionalValidator: isValidEmail,
    });

    const [websiteProps] = useRequiredTextInput({
        ...commonInputProps,
        label: translations['leadsForm.contactInfo.website'],
        name: 'website',
        additionalValidator: isValidFormattedWebsite,
    });

    const [propertyCountProps] = useRequiredTextInput({
        ...commonInputProps,
        label: translations['leadsForm.contactInfo.propertyCount'],
        name: 'propertyCount',
        id: translations['leadsForm.contactInfo.propertyCountId'],
        additionalValidator: isValidPropertyCount,
    });

    const [countryProps] = useSelect({
        ...commonInputProps,
        label: translations['leadsForm.contactInfo.addressCountry'],
        id: translations['leadsForm.contactInfo.addressCountry'],
        name: 'addressCountry',
    });

    useEffect(() => {
        triggerEdapPageView('LeadsForm-ContactInfo');
        updateLeadsForm(
            'addressCountry',
            translations['leadsForm.contactInfo.addressCountryDefault']
        );
    }, []);

    return (
        <div className="contact-info-wrapper">
            <div className="leads-form-section-heading">
                {translations['leadsForm.contactInfo.heading1']}
            </div>
            <div className="leads-form-instruction">
                <p>All fields are required except those marked as optional</p>
            </div>
            <div className="contact-info-section">
                <div className="leads-form-section-sub-heading">
                    {translations['leadsForm.contactInfo.section1.heading']}
                </div>
                <div className="eq-flex-row">
                    <FormInput
                        {...firstNameProps}
                        value={firstName}
                        errorMsg={firstNameError}
                    />
                    <FormInput
                        {...lastNameProps}
                        value={lastName}
                        errorMsg={lastNameError}
                    />
                </div>
                <div className="eq-flex-row">
                    <FormInput
                        {...jobTitleProps}
                        value={jobTitle}
                        errorMsg={jobTitleError}
                    />
                    <FormInput
                        {...emailAddressProps}
                        value={emailAddress}
                        errorMsg={emailAddressError}
                    />
                </div>
                <InputTel
                    phoneNumber={phoneNumber}
                    countryCode={country}
                    errorMsg={phoneNumberError}
                    labels={{
                        phoneNumber:
                            translations['leadsForm.contactInfo.phoneNumber'],
                        countryCode:
                            translations['leadsForm.contactInfo.countryCode'],
                    }}
                    onPhoneChange={handlePhoneChange}
                    onPhoneBlur={handlePhoneBlur}
                    onPhoneFocus={handlePhoneFocus}
                    onCountryCodeChange={handleCountryCodeChange}
                />
            </div>
            <div className="contact-info-section">
                <div className="leads-form-section-sub-heading">
                    {translations['leadsForm.contactInfo.section2.heading']}
                </div>
                <div className="eq-flex-row">
                    <FormInput
                        {...companyNameProps}
                        value={companyName}
                        errorMsg={companyNameError}
                    />
                    <FormInput
                        {...websiteProps}
                        value={website}
                        errorMsg={websiteError}
                    />
                </div>
                <div>
                    <FormInput
                        {...propertyCountProps}
                        value={propertyCount}
                        errorMsg={propertyCountError}
                    />
                </div>
                <div className="leads-form-section-sub-heading">
                    {
                        translations[
                            'leadsForm.contactInfo.companyAddress.heading'
                        ]
                    }
                </div>
                <div className="eq-flex-row">
                    <FormSelect
                        {...countryProps}
                        value={addressCountry}
                        items={countryOptions}
                    />
                </div>
                <FormInput
                    {...addressLine1Props}
                    value={addressLine1}
                    errorMsg={addressLine1Error}
                />
                <FormInput {...addressLine2Props} value={addressLine2} />
                <div className="eq-flex-row">
                    <FormInput
                        {...cityProps}
                        value={city}
                        errorMsg={cityError}
                    />
                    <FormInput
                        {...stateProps}
                        value={state}
                        errorMsg={stateError}
                    />
                </div>
                <div className="eq-flex-row">
                    <FormInput {...zipProps} value={zip} errorMsg={zipError} />
                </div>
            </div>
        </div>
    );
};

ContactInfo.propTypes = {
    translations: PropTypes.object,
    updateLeadsForm: PropTypes.func,
    firstName: PropTypes.string,
    firstNameError: PropTypes.string,
    lastName: PropTypes.string,
    lastNameError: PropTypes.string,
    jobTitle: PropTypes.string,
    jobTitleError: PropTypes.string,
    emailAddress: PropTypes.string,
    emailAddressError: PropTypes.string,
    phoneNumber: PropTypes.string,
    countryCode: PropTypes.string,
    country: PropTypes.string,
    phoneNumberError: PropTypes.string,
    companyName: PropTypes.string,
    companyNameError: PropTypes.string,
    website: PropTypes.string,
    websiteError: PropTypes.string,
    propertyCount: PropTypes.string,
    propertyCountError: PropTypes.string,
    addressCountry: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine1Error: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    cityError: PropTypes.string,
    state: PropTypes.string,
    stateError: PropTypes.string,
    zip: PropTypes.string,
    zipError: PropTypes.string,
};

export default ContactInfo;
