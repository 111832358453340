import 'core-js/es6/promise'; // For IE 11 support
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import ClientApp from './components/App/ClientApp';
import './favicon.ico';
import './XDID';

const data = window.__properties;

ReactDOM.hydrate(
    <ClientApp
        translations={data.translations}
        prodTestConfig={data.prodTestConfig}
    />,
    document.getElementById('root')
);
