import {isEmpty} from '../../../../util/validationRules';
import {triggerEdapEvent, eventCategories} from '../../util/edap';
import {MAX_LENGTH} from '../../../../res/inputLimits';

export const useTextInput = (inputOptions = {}) => {
    const {name, leadsFormUpdater, label, id} = inputOptions;

    const onChange = (event) => leadsFormUpdater(name, event.target.value);

    const onFocus = () => {
        triggerEdapEvent('generic.event', {
            eventcategory: eventCategories.LEADS_FORM,
            eventaction: `Form input focus - ${name}`,
        });
        leadsFormUpdater(`${name}Error`, '');
    };

    const props = {
        label,
        id,
        name,
        maxLength: MAX_LENGTH[`${name}`],
        onChange,
        onFocus,
    };

    return [props];
};

export const useRequiredTextInput = (inputOptions = {}) => {
    const {
        name,
        id,
        label,
        leadsFormUpdater,
        translations,
        additionalValidator,
    } = inputOptions;

    const onChange = (event) => leadsFormUpdater(name, event.target.value);

    const onBlur = (event) => {
        const {value, name, id} = event.target;
        let error = null;
        if (isEmpty(value)) {
            error = `${id} ${translations['leadsForm.errorMessage.empty']}`;
        } else if (additionalValidator) {
            const valid = additionalValidator(value);
            if (!valid) {
                error = `${id} ${translations['leadsForm.errorMessage.invalid']}`;
            }
        }
        leadsFormUpdater(`${name}Error`, error);
    };

    const onFocus = () => {
        triggerEdapEvent('generic.event', {
            eventcategory: eventCategories.LEADS_FORM,
            eventaction: `Form input focus - ${name}`,
        });
        leadsFormUpdater(`${name}Error`, '');
    };

    const props = {
        label: label,
        id: id || label,
        name,
        maxLength: MAX_LENGTH[`${name}`],
        onChange,
        onBlur,
        onFocus,
    };

    return [props];
};

export const useSelect = (inputOptions = {}) => {
    const {name, leadsFormUpdater, label, id} = inputOptions;

    const onChange = (event) => {
        const {name, value} = event.target;
        leadsFormUpdater(name, value);
        triggerEdapEvent('generic.event', {
            eventcategory: eventCategories.LEADS_FORM,
            eventaction: `Form input select - ${name} - ${value}`,
        });
    };

    const props = {
        name,
        label,
        id,
        onChange,
    };

    return [props];
};
