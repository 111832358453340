import React, {useEffect} from 'react';
import Tooltip from '../../UIToolkit/Tooltip';
import ReCAPTCHA from 'react-google-recaptcha';
import {Button} from '@homeaway/react-buttons';
import FormSelect from '@homeaway/react-form-select';
import {FormInput} from '@homeaway/react-form-components';
import {
    triggerEdapPageView,
    triggerEdapEvent,
    eventCategories,
} from '../../util/edap';
import solutionTypeOptions from '../../../../res/solutionTypes';
import pciResponseOptions from '../../../../res/pciResponses';
import emptyOption from '../../../../res/emptyOption';
import {
    useRequiredTextInput,
    useTextInput,
    useSelect,
} from '../hooks/formFields';
import PropTypes from 'prop-types';
import {pciCompliancePropType, solutionPropType} from '../../util/propTypes';
import './styles.less';

const CompanyInfo = (props) => {
    const {
        translations,
        updateLeadsForm,
        solutionType,
        otherDistributors,
        otherDistributorsError,
        pciCompliant,
        siteKey,
        recaptchaRef,
    } = props;

    const solutionTypeItems = [
        emptyOption,
        {
            value: solutionTypeOptions.CM,
            label: translations['leadsForm.companyInfo.solutionType_CM'],
        },
        {
            value: solutionTypeOptions.PMS,
            label: translations['leadsForm.companyInfo.solutionType_PMS'],
        },
        {
            value: solutionTypeOptions.CRS,
            label: translations['leadsForm.companyInfo.solutionType_CRS'],
        },
    ];

    const handleRecaptchaChange = (value) => {
        triggerEdapEvent('generic.event', {
            eventcategory: eventCategories.LEADS_FORM,
            eventaction: 'Recaptcha updated',
        });
        updateLeadsForm('error', false);
        updateLeadsForm('recaptchaResponse', value);
    };

    const handleRecaptchaError = () => {
        updateLeadsForm(
            'error',
            translations['leadsForm.errorMessage.processingError']
        );
    };

    const handlePCIClick = (buttonName) => () => {
        let newValue, action;
        if (buttonName === pciResponseOptions.YES) {
            if (pciCompliant === pciResponseOptions.YES) {
                newValue = '';
                action = 'Yes unselected';
            } else {
                newValue = pciResponseOptions.YES;
                action = 'Yes selected';
            }
        } else {
            if (pciCompliant === pciResponseOptions.NO) {
                newValue = '';
                action = 'No unselected';
            } else {
                newValue = pciResponseOptions.NO;
                action = 'No selected';
            }
        }
        triggerEdapEvent('generic.event', {
            eventcategory: eventCategories.LEADS_FORM,
            eventaction: `PCI Compliance - ${action}`,
        });
        updateLeadsForm('pciCompliant', newValue);
    };

    const [userMessageProps] = useTextInput({
        leadsFormUpdater: updateLeadsForm,
        label: translations[`leadsForm.companyInfo.userMessage`],
        id: translations[`leadsForm.companyInfo.userMessage`],
        name: 'userMessage',
    });

    const [otherDistributorsProps] = useRequiredTextInput({
        leadsFormUpdater: updateLeadsForm,
        translations,
        label: translations['leadsForm.companyInfo.otherDistributors'],
        name: 'otherDistributors',
    });

    const [solutionTypeProps] = useSelect({
        leadsFormUpdater: updateLeadsForm,
        translations,
        label: translations['leadsForm.companyInfo.solutionType'],
        id: translations['leadsForm.companyInfo.solutionType'],
        name: 'solutionType',
    });

    useEffect(() => {
        triggerEdapPageView('LeadsForm-CompanyInfo');
    }, []);

    return (
        <div className="company-info-wrapper">
            <div className="leads-form-section-heading">
                {`${translations['leadsForm.companyInfo.heading1']}${
                    props.firstName ? `, ${props.firstName}` : ''
                }!`}
                <br />
                {translations['leadsForm.companyInfo.heading2']}
            </div>
            <div className="leads-form-instruction">
                <p>All fields are required except those marked as optional</p>
            </div>
            <div>
                <FormSelect
                    {...solutionTypeProps}
                    value={solutionType}
                    items={solutionTypeItems}
                />
            </div>
            <FormInput
                {...otherDistributorsProps}
                value={otherDistributors}
                id={translations['leadsForm.companyInfo.otherDistributorsId']}
                errorMsg={otherDistributorsError}
            />
            <div className="leads-form-section-sub-heading pci-heading heading-with-tooltip">
                {translations['leadsForm.companyInfo.section2.heading']}
                <Tooltip
                    content={
                        translations['leadsForm.companyInfo.section2.tooltip']
                    }
                />
            </div>
            <div className="pci-compliant-options-wrapper">
                <Button
                    className={`${
                        pciCompliant === pciResponseOptions.YES && 'btn-primary'
                    }`}
                    label={
                        translations['leadsForm.companyInfo.section2.option1']
                    }
                    onClick={handlePCIClick(pciResponseOptions.YES)}
                    value={pciResponseOptions.YES}
                />
                <Button
                    className={`${
                        pciCompliant === pciResponseOptions.NO && 'btn-primary'
                    }`}
                    label={
                        translations['leadsForm.companyInfo.section2.option2']
                    }
                    onClick={handlePCIClick(pciResponseOptions.NO)}
                    value={pciResponseOptions.NO}
                />
            </div>
            <div className="leads-form-section-sub-heading">
                {translations['leadsForm.companyInfo.section3.heading']}
            </div>
            <div className="user-message-text-area-wrapper">
                <textarea
                    {...userMessageProps}
                    style={{width: '100%'}}
                    className="user-message-text-area form-control"
                    value={props.userMessage}
                />
            </div>
            <div className="invisible-recaptcha">
                <ReCAPTCHA
                    sitekey={siteKey}
                    size="invisible"
                    onErrored={handleRecaptchaError}
                    onChange={handleRecaptchaChange}
                    ref={recaptchaRef}
                />
            </div>
        </div>
    );
};

CompanyInfo.propTypes = {
    firstName: PropTypes.string,
    updateLeadsForm: PropTypes.func,
    solutionType: solutionPropType,
    translations: PropTypes.object,
    siteKey: PropTypes.string,
    otherDistributors: PropTypes.string,
    otherDistributorsError: PropTypes.string,
    pciCompliant: pciCompliancePropType,
    userMessage: PropTypes.string,
    recaptchaRef: PropTypes.object,
};

export default CompanyInfo;
