import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {ButtonPrimary} from '@homeaway/react-buttons';
import {
    triggerEdapPageView,
    triggerEdapEvent,
    eventCategories,
} from '../../util/edap';

import './styles.less';

const CurrentCustomerCheck = ({
    translations,
    onCurrentCustomer,
    onNewCustomer,
}) => {
    useEffect(() => {
        triggerEdapPageView('LeadsForm-Screener-Question');
    }, []);

    const onButtonClick = (value) => () => {
        triggerEdapEvent('generic.event', {
            eventcategory: eventCategories.LEADS_FORM,
            eventaction: `${value} button clicked`,
        });
    };
    const onExistingCustomerClick = onButtonClick('existing-customer');
    const onNewCustomerClick = onButtonClick('new-customer');

    return (
        <div className="cstmrcheck__wrapper">
            <h3 className="cstmrcheck__message">
                {translations['leadsForm.currentcustomercheck.message']}
            </h3>
            <div className="cstmrcheck__answer-wrapper">
                <ButtonPrimary
                    label={
                        translations[
                            'leadsForm.currentcustomercheck.btn.no.label'
                        ]
                    }
                    onClick={() => {
                        onExistingCustomerClick();
                        onCurrentCustomer();
                    }}
                />
                <ButtonPrimary
                    label={
                        translations[
                            'leadsForm.currentcustomercheck.btn.yes.label'
                        ]
                    }
                    onClick={() => {
                        onNewCustomerClick();
                        onNewCustomer();
                    }}
                />
            </div>
        </div>
    );
};

CurrentCustomerCheck.propTypes = {
    translations: PropTypes.object.isRequired,
    onCurrentCustomer: PropTypes.func.isRequired,
    onNewCustomer: PropTypes.func.isRequired,
};

export default CurrentCustomerCheck;
