const URIJS = require('uri-js');
const {sanitizeUrl} = require('@braintree/sanitize-url');

const format = (url) => {
    let uriOptions = URIJS.parse(url);
    if (!uriOptions.scheme) {
        uriOptions.scheme = 'https';
    }

    // for certain urls the library will parse it such that
    // there is no host and only a path.
    // Example: google.com
    // parses such that
    // {
    //     ...
    //     path: 'google.com',
    //     host: undefined
    //     ...
    // }
    // While an edge case we attempt to format the URL as the
    // user would expect
    if (!uriOptions.host) {
        const [host, ...path] = uriOptions.path.split('/');
        uriOptions.host = host;
        uriOptions.path = path.join('/');
        uriOptions.reference = 'absolute';
        uriOptions = URIJS.parse(URIJS.serialize(uriOptions));
    }

    return URIJS.serialize(uriOptions);
};

const sanitize = (url) => sanitizeUrl(url);

module.exports = {
    format,
    sanitize,
};
