export default {
    development: {
        recaptcha: {
            site_key: '6LfWYZQUAAAAAJai7ekdjZdPqQ_uep5gHLz4XCzf',
            site_key_invisible: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
        },
    },
    test: {
        recaptcha: {
            site_key: '6LfWYZQUAAAAAJai7ekdjZdPqQ_uep5gHLz4XCzf',
            site_key_invisible: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
        },
    },
    production: {
        recaptcha: {
            site_key: '6Lc4YK8UAAAAAGDwkiKubY8lu36eS67mrV03VzcL',
            site_key_invisible: '6Le1q60eAAAAAL3c8Jo0zkqh2e4i4ChmpFJnPra1',
        },
    },
};
