const {website, restrictNegativeValues} = require('./leadsFormValidators');
const validator = require('validator');
const URLS = require('./urls');

const isValid = ({error}) => {
    return error ? !error.details.length : true;
};

function isString(val) {
    return val && typeof val === 'string';
}

function isEmpty(val) {
    return !isString(val) || val.trim() === '';
}

function isValidEmail(email) {
    return email && validator.isEmail(email);
}

function isValidFormattedWebsite(websiteURL) {
    return isValidWebsite(URLS.format(websiteURL));
}

function isValidWebsite(websiteURL) {
    return isValid(website.validate(websiteURL));
}

function isValidPropertyCount(number) {
    return restrictNegativeValues(number);
}

module.exports = {
    isString,
    isEmpty,
    isValidEmail,
    isValidFormattedWebsite,
    isValidWebsite,
    isValidPropertyCount,
};
