/* istanbul ignore file */
// Ignoring this file for tests, because it is a basic clone of the EDAP one.

import XDIDHelper from './XDIDHelper';

(function XDIDSetterUpper(win) {
    const xdidHelper = new XDIDHelper(win.analyticsdatalayer);
    const visitorId = xdidHelper.getVisitorId();
    // eslint-disable-next-line prefer-destructuring
    const hostname = win.location.hostname;
    const egEnv = xdidHelper.adlExtractor('appenvironment', 'dev');
    const travelPixelParams = {
        guid: visitorId,
        serverPlatform: 'expedia',
        tpid: '',
        eapid: '',
        expUserId: '',
        amcv: '',
        inAuthId: '',
        emailHash: '',
    };

    xdidHelper.loadScript(
        'https://b.travel-assets.com/travel-pixel-js/0.0.1/app.js'
    );

    // eslint-disable-next-line no-param-reassign
    win.travelPixel = win.travelPixel || [];
    win.travelPixel.push((TravelPixelWaiter) => {
        const waiter = new TravelPixelWaiter(hostname, egEnv);
        waiter.loadXdidApi(travelPixelParams).then((ret) => {
            // eslint-disable-line dot-notation
            let xdid;

            // If the xdid was found on another site then it will exist in ret.v
            if (ret && ret.k && ret.k === 'xdid' && ret.v) {
                xdid = ret.v;

                // In the case where no other sites have an xdid then we need to
                // pull it from our own cookie (for now, should be done in library)
            } else if (!ret) {
                xdid = xdidHelper.getCookie('xdid');
            }

            // If I found an xdid then we need to send it to Keychain via EDAP
            if (xdid) {
                win.edap.push({
                    name: 'xdid.send',
                    data: {
                        xdid,
                    },
                });
            }
        });
    });
})(window);
