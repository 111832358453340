import React from 'react';
import PropTypes from 'prop-types';
import errorSvg from '../../assets/error.svg';
import './styles.less';

const ErrorMessage = ({message, additionalClassName}) => {
    return (
        <>
            <div className={'error-message-wrapper ' + additionalClassName}>
                <img
                    src={errorSvg}
                    className="img-error-icon"
                    alt="error-icon"
                />
                <p className="error-message">{message}</p>
            </div>
        </>
    );
};

ErrorMessage.propTypes = {
    message: PropTypes.string,
    additionalClassName: PropTypes.string,
};

export default ErrorMessage;
