import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {SVGIcon} from '@homeaway/react-svg';
import {ALERT__24, CHECK__12} from '@homeaway/svg-defs';
import './styles.less';

export default class ProgressBar extends Component {
    render() {
        let {stateArray, appState} = this.props;
        let currentIndex = stateArray.findIndex((e) => e.state === appState);
        let toRender = [];
        stateArray.forEach((e, i) => {
            if (e.state !== 'USER_TYPE') {
                toRender.push(
                    <div
                        key={`line-${i}`}
                        className={`progress-line ${
                            currentIndex >= i ? 'done' : ''
                        }`}
                    />
                );
            }
            let isSuccessful = e.valid && !e.error;
            toRender.push(
                <div
                    key={`circle-${i}`}
                    className={`text-brand progress-circle ${
                        e.state === appState && !e.error ? 'current' : ''
                    } ${
                        isSuccessful
                            ? 'bg--color-brand text-brand step-successful'
                            : ''
                    }`}
                >
                    {isSuccessful ? (
                        <div className="success-svg">
                            <SVGIcon markup={CHECK__12} />
                        </div>
                    ) : null}
                    {e.error ? (
                        <div className="alert-svg">
                            <SVGIcon markup={ALERT__24} />
                        </div>
                    ) : null}
                </div>
            );
        });
        return <div className="progress-bar-wrapper">{toRender}</div>;
    }
}

ProgressBar.propTypes = {
    stateArray: PropTypes.array,
    appState: PropTypes.string,
};
