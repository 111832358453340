import React from 'react';
import {slug} from 'github-slugger';
import copy from 'copy-to-clipboard';
import TopicIcon from '../../assets/doc-anchor-icon.svg';
import AttachmentIcon from '../../assets/attachment.svg';
import './styles.less';

const handleCopy = (title) => () => {
    copy(`${window.location.origin}${window.location.pathname}#${slug(title)}`);
};

const buildTitle = (children) => {
    switch (typeof children) {
        case 'object':
            return Array.isArray(children) &&
                children.every((c) => typeof c === 'string')
                ? [true, children.join('')]
                : [false, children];
        case 'string':
            return [true, children];
        default:
            return [false, children];
    }
};

// eslint-disable-next-line react/prop-types
const TopicTitle = ({className, children}) => {
    const [canUseAsTitle, title] = buildTitle(children);
    return canUseAsTitle ? (
        <span
            className={`documentation-topic__title header__font section__header ${
                className || ''
            }`}
            title={title}
        >
            <img alt="" src={TopicIcon} className="documentation-topic__icon" />
            <span className="text">{title}</span>
            <span className="copy-link-wrapper">
                <button className="copy-link" onClick={handleCopy(title)}>
                    <img alt="" src={AttachmentIcon} />
                    {'Copy link'}
                </button>
            </span>
        </span>
    ) : (
        <>{children}</>
    );
};

export default TopicTitle;
