import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Button, ButtonPrimary} from '@homeaway/react-buttons';
import {
    triggerEdapPageView,
    triggerEdapEvent,
    eventCategories,
} from '../../util/edap';

import './styles.less';

const CurrentCustomer = ({translations, onBack, onClose}) => {
    useEffect(() => {
        triggerEdapPageView('LeadsForm-Screener-Existing-Partner');
    }, []);
    const onButtonClick = (value) => () => {
        triggerEdapEvent('generic.event', {
            eventcategory: eventCategories.LEADS_FORM,
            eventaction: `${value} button clicked`,
        });
    };

    const onCloseClick = onButtonClick('close');
    const onBackClick = onButtonClick('back');

    return (
        <div className="crntcstmr__wrapper">
            <h3 className="crntcstmr__message">
                {translations['leadsForm.currentcustomer.message']}{' '}
                <a
                    className="crntcstmr__docs-link"
                    href={
                        translations['leadsForm.currentcustomer.docs.link.url']
                    }
                >
                    {translations['leadsForm.currentcustomer.docs.link.text']}
                </a>
            </h3>
            <div className="crntcstmr__answer-wrapper">
                <ButtonPrimary
                    label={
                        translations[
                            'leadsForm.currentcustomer.btn.close.label'
                        ]
                    }
                    onClick={() => {
                        onCloseClick();
                        onClose();
                    }}
                />
                <Button
                    className="crntcstmr__btn-back"
                    label={
                        translations['leadsForm.currentcustomer.btn.back.label']
                    }
                    onClick={() => {
                        onBackClick();
                        onBack();
                    }}
                />
            </div>
        </div>
    );
};

CurrentCustomer.propTypes = {
    translations: PropTypes.object.isRequired,
    onBack: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default CurrentCustomer;
