const solutionTypeOptions = require('../res/solutionTypes').default;
const pciResponses = require('../res/pciResponses').default;
const sanitizeHtml = require('sanitize-html');
const removeHTML = (value) =>
    sanitizeHtml(value, {
        allowedTags: [],
        allowedAttributes: [],
    });
const BaseJoi = require('@hapi/joi');
const URLs = require('./urls');
const {MAX_LENGTH} = require('../res/inputLimits');

const stripHTML = (joi) => ({
    name: 'string',
    base: joi.string(),
    language: {
        stripHTML: 'removing HTML tags resulted in an empty string',
    },
    rules: [
        {
            name: 'stripHTML',
            validate(params, value, state, options) {
                const sanitized = sanitizeHtml(value, {
                    allowedTags: [],
                    allowedAttributes: [],
                });

                if (sanitized) {
                    return sanitized;
                }

                return this.createError(
                    'string.stripHTML',
                    {value},
                    state,
                    options
                );
            },
        },
    ],
});

const sanitizeURL = (joi) => ({
    name: 'string',
    base: joi.string(),
    language: {
        sanitizeURL: 'unable to sanitize URL',
    },
    rules: [
        {
            name: 'sanitizeURL',
            validate(params, value, state, options) {
                const sanitized = [
                    decodeURI,
                    removeHTML,
                    URLs.sanitize,
                    URLs.format,
                ].reduce((url, sanitize) => sanitize(url), value);

                if (sanitized) {
                    return sanitized;
                }

                return this.createError(
                    'string.sanitizeURL',
                    {value},
                    state,
                    options
                );
            },
        },
    ],
});

const Joi = BaseJoi.extend(stripHTML).extend(sanitizeURL);
const countries = require('./countries').countries;
const requiredString = Joi.string()
    .trim()
    .normalize()
    .required()
    .min(1, 'utf8');

const optionalString = Joi.string()
    .trim()
    .normalize()
    .allow('')
    .empty('')
    .default('')
    .optional()
    .stripHTML();

const firstName = requiredString.stripHTML().max(MAX_LENGTH.firstName, 'utf8');
const lastName = requiredString.stripHTML().max(MAX_LENGTH.lastName, 'utf8');
const jobTitle = requiredString.stripHTML().max(MAX_LENGTH.jobTitle, 'utf8');
const emailAddress = requiredString
    .stripHTML()
    .max(MAX_LENGTH.emailAddreess, 'utf8')
    .email();

const website = requiredString
    .max(MAX_LENGTH.website, 'utf8')
    .uri({scheme: /^https/})
    .sanitizeURL();

const phoneNumber = requiredString
    .stripHTML()
    .max(MAX_LENGTH.phoneNumber, 'utf8');

const addressCountry = requiredString.valid(
    ...Object.keys(countries).map((key) => countries[key])
);
const addressLine1 = requiredString
    .stripHTML()
    .max(MAX_LENGTH.addressLine1, 'utf8');
const addressLine2 = optionalString.max(MAX_LENGTH.addressLine2, 'utf8');
const city = requiredString.max(MAX_LENGTH.city, 'utf8').stripHTML();
const state = requiredString.max(MAX_LENGTH.state, 'utf8').stripHTML();
const zip = requiredString.max(MAX_LENGTH.zip, 'utf8').stripHTML();
const companyName = requiredString
    .max(MAX_LENGTH.companyName, 'utf8')
    .stripHTML();
const userMessage = optionalString.max(MAX_LENGTH.userMessage, 'utf8');
const solutionType = requiredString.valid(
    ...Object.values(solutionTypeOptions)
);
const propertyCount = requiredString.max(MAX_LENGTH.propertyCount, 'utf8');

const restrictNegativeValues = (value) => (Number(value) < 0 ? false : true);

const otherDistributors = requiredString
    .max(MAX_LENGTH.otherDistributors, 'utf8')
    .stripHTML();
const pciCompliant = requiredString.valid(...Object.values(pciResponses));
const leadsForm = Joi.object({
    firstName,
    lastName,
    jobTitle,
    emailAddress,
    website,
    phoneNumber,
    propertyCount,
    otherDistributors,
    addressCountry,
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
    companyName,
    solutionType,
    pciCompliant,
    userMessage,
}).required();

module.exports = {
    firstName,
    lastName,
    jobTitle,
    emailAddress,
    website,
    phoneNumber,
    propertyCount,
    addressCountry,
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
    companyName,
    solutionType,
    otherDistributors,
    pciCompliant,
    userMessage,
    leadsForm,
    restrictNegativeValues,
};
