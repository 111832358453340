function getEnv(host) {
    if (host.endsWith('test.lodgingpartner.expedia.com')) {
        return 'test';
    }
    if (host === 'localhost:8080' || host === '127.0.0.1:8080') {
        return 'development';
    }
    if (
        host.endsWith('prod.lodgingpartner.expedia.com') ||
        host.endsWith('developers.expediagroup.com')
    ) {
        return 'production';
    }
    return 'test';
}

module.exports = {
    getEnv,
};
