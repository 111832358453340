export const MAX_LENGTH = {
    firstName: 40,
    lastName: 80,
    jobTitle: 128,
    emailAddreess: 80,
    phoneNumber: 40,
    website: 255,
    addressLine1: 255,
    addressLine2: 255,
    city: 40,
    state: 80,
    zip: 20,
    companyName: 255,
    propertyCount: 80,
    otherDistributors: 255,
    userMessage: 255,
};
