import React, {useEffect} from 'react';
import {SVGIcon} from '@homeaway/react-svg';
import {
    EG_HOTEL_V2__40,
    EG_ACCOUNT__40,
    EG_AIRPLANE_V2__40,
    EG_BEACH__40,
    EG_CHECK__40,
} from '@homeaway/eg-svg-defs';
import {
    triggerEdapPageView,
    triggerEdapEvent,
    eventCategories,
} from '../../util/edap';
import allUserTypes from '../../../../res/userTypes';
import PropTypes from 'prop-types';
import {userTypePropType} from '../../util/propTypes';
import './styles.less';

const UserType = ({userType, updateLeadsForm, translations}) => {
    const userTypes = [
        {
            value: allUserTypes.CPSP,
            label: translations['leadsForm.userType.cpsp'],
            icon: EG_ACCOUNT__40,
        },
        {
            value: allUserTypes.B2B,
            label: translations['leadsForm.userType.b2b'],
            icon: EG_AIRPLANE_V2__40,
        },
        {
            value: allUserTypes.ACTIVITIES,
            label: translations['leadsForm.userType.activities'],
            icon: EG_BEACH__40,
        },
        {
            value: allUserTypes.PM,
            label: translations['leadsForm.userType.pm'],
            icon: EG_HOTEL_V2__40,
        },
    ];

    useEffect(() => {
        triggerEdapPageView('LeadsForm-UserType');
    }, []);

    const handleUserTypeChange = (value) => () => {
        updateLeadsForm('userType', value);
        triggerEdapEvent('generic.event', {
            eventcategory: eventCategories.LEADS_FORM,
            eventaction: `Selected ${value}`,
        });
    };

    const renderUserTypes = () =>
        userTypes.map((userTypeOption, i) => (
            <div
                tabIndex={0}
                role="button"
                key={`userTypeOption-${i}`}
                className={`userType-option ${
                    userType === userTypeOption.value && 'selected'
                }`}
                onClick={handleUserTypeChange(userTypeOption.value)}
                onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                        handleUserTypeChange(userTypeOption.value)();
                    }
                }}
            >
                <div className="userType-icon">
                    <SVGIcon
                        markup={
                            userType === userTypeOption.value
                                ? EG_CHECK__40
                                : userTypeOption.icon
                        }
                    />
                </div>
                <div className="userType-label text-brand">
                    {userTypeOption.label}
                </div>
            </div>
        ));
    return (
        <div className="user-type-wrapper">
            <div className="section-heading">
                {translations['leadsForm.userType.heading']}
            </div>
            <div className="options-wrapper">{renderUserTypes()}</div>
        </div>
    );
};

UserType.propTypes = {
    translations: PropTypes.object,
    updateLeadsForm: PropTypes.func,
    userType: userTypePropType,
};

export default UserType;
