import React from 'react';
import Layout from '../PageLayout';
import PropTypes from 'prop-types';
import TopicTitle from './topic-title';

import './styles.less';

const Blog = ({translations}) => {
    return (
        <Layout
            activeIndex={2}
            translations={translations}
            header={null}
            content={
                <div className="blog__wrapper">
                    <section
                        className="blog__entry"
                        id="updated-expedia-collect-model-and-changes-to-api-specification"
                    >
                        <TopicTitle className="blog__title">
                            Updated Expedia Collect model and changes to API
                            specification
                        </TopicTitle>
                        <br />
                        <span className="blog__date">1 October 2024</span>
                        <p>
                            The updated Expedia Collect model is expected to
                            launch in the following markets on{' '}
                            <strong>8 October 2024</strong> (countries and
                            launch date are subject to change):
                        </p>
                        <ul>
                            <li>Cameroon</li>
                            <li>Christmas Island</li>
                            <li>Cote d&apos;ivoire</li>
                            <li>East Timor (Timor-Leste)</li>
                            <li>Libya</li>
                            <li>Marshall Islands</li>
                            <li>Morocco</li>
                            <li>
                                Saint Helena, Ascension and Tristan da Cunha
                            </li>
                            <li>Somalia</li>
                            <li>South Sudan</li>
                            <li>Turkmenistan</li>
                            <li>Tuvalu</li>
                            <li>Wallis and Futuna</li>
                            <li>Western Sahara</li>
                        </ul>
                        <p>
                            We will begin to communicate these updates with all
                            lodging partners from these markets. To accommodate
                            this new experience ahead of time, we{' '}
                            <strong>strongly recommend</strong> all connectivity
                            partners adopt/validate their reservation output or
                            display on respective system(s) with their
                            connectivity point-of-contact in Expedia Group.
                        </p>
                        <p>
                            <strong>
                                Connectivity partners should be aware in the
                                event where full adoption or verified validation
                                of the updated Expedia Collect model is not
                                completed ahead of market launches, this{' '}
                                <u>will negatively impact</u> properties&apos;
                                reservations and reconciliation experiences.
                            </strong>
                        </p>
                        <p>
                            The following is a list of markets where updated
                            Expedia Collect is currently live:
                        </p>
                        <ul>
                            <li>Albania</li>
                            <li>Algeria</li>
                            <li>Angola</li>
                            <li>Anguilla</li>
                            <li>Antigua and Barbuda</li>
                            <li>Argentina</li>
                            <li>Armenia</li>
                            <li>Aruba</li>
                            <li>Australia</li>
                            <li>Azerbaijan</li>
                            <li>Bahamas</li>
                            <li>Bangladesh</li>
                            <li>Barbados</li>
                            <li>Belarus</li>
                            <li>Belize</li>
                            <li>Benin</li>
                            <li>Bermuda</li>
                            <li>Bhutan</li>
                            <li>Bolivia</li>
                            <li>Bonaire, Sint Eustatius and Saba</li>
                            <li>Bosnia and Herzegovina</li>
                            <li>Botswana</li>
                            <li>British Virgin Islands</li>
                            <li>Brunei</li>
                            <li>Burkina Faso</li>
                            <li>Burundi</li>
                            <li>Cambodia</li>
                            <li>Cape Verde</li>
                            <li>Cayman Islands</li>
                            <li>Central African Republic</li>
                            <li>Chad</li>
                            <li>Chile</li>
                            <li>Cocos Islands</li>
                            <li>Colombia</li>
                            <li>Comoros</li>
                            <li>Cook Islands</li>
                            <li>Cuba</li>
                            <li>Curacao</li>
                            <li>Democratic Republic of the Congo</li>
                            <li>Djibouti</li>
                            <li>Dominica</li>
                            <li>Ecuador</li>
                            <li>Egypt</li>
                            <li>El Salvador</li>
                            <li>Equatorial Guinea</li>
                            <li>Eritrea</li>
                            <li>Eswatini</li>
                            <li>Ethiopia</li>
                            <li>Falkland Islands</li>
                            <li>Federated States of Micronesia</li>
                            <li>French Guiana</li>
                            <li>French Polynesia</li>
                            <li>Gabon</li>
                            <li>Gambia</li>
                            <li>
                                GCC (United Arab Emirates, Saudi Arabia,
                                Bahrain, Kuwait, Oman, Qatar)
                            </li>
                            <li>Ghana</li>
                            <li>Georgia</li>
                            <li>Grenada</li>
                            <li>Guatemala</li>
                            <li>Guinea</li>
                            <li>Guinea-Bissau</li>
                            <li>Guyana</li>
                            <li>Haiti</li>
                            <li>Honduras</li>
                            <li>Hong Kong SAR</li>
                            <li>Iceland</li>
                            <li>Indonesia</li>
                            <li>Israel</li>
                            <li>Japan</li>
                            <li>Kazakhstan</li>
                            <li>Kenya</li>
                            <li>Kiribati</li>
                            <li>Kyrgyzstan</li>
                            <li>Laos</li>
                            <li>Lesotho</li>
                            <li>Liberia</li>
                            <li>Macau SAR</li>
                            <li>Madagascar</li>
                            <li>Malawi</li>
                            <li>Malaysia</li>
                            <li>Mali</li>
                            <li>Mauritania</li>
                            <li>Mayotte</li>
                            <li>Mexico</li>
                            <li>Moldova</li>
                            <li>Mongolia</li>
                            <li>Montenegro</li>
                            <li>Montserrat</li>
                            <li>Mozambique</li>
                            <li>Myanmar</li>
                            <li>Namibia</li>
                            <li>Nauru</li>
                            <li>Nepal</li>
                            <li>New Caledonia</li>
                            <li>New Zealand</li>
                            <li>Nicaragua</li>
                            <li>Niger</li>
                            <li>Niue</li>
                            <li>Norfolk Island</li>
                            <li>North Macedonia</li>
                            <li>Norway</li>
                            <li>Pakistan</li>
                            <li>Palau</li>
                            <li>Panama</li>
                            <li>Papua New Guinea</li>
                            <li>Paraguay</li>
                            <li>Peru</li>
                            <li>Republic of the Congo</li>
                            <li>Russia</li>
                            <li>Rwanda</li>
                            <li>Samoa</li>
                            <li>Sao Tome and Principe</li>
                            <li>Senegal</li>
                            <li>Serbia</li>
                            <li>Seychelles</li>
                            <li>Sierra Leone</li>
                            <li>Singapore</li>
                            <li>Solomon Islands</li>
                            <li>South Africa</li>
                            <li>South Korea</li>
                            <li>St. Barthelemy</li>
                            <li>St. Kitts and Nevis</li>
                            <li>St. Maarten</li>
                            <li>St. Martin</li>
                            <li>St. Vincent and the Grenadines</li>
                            <li>Sudan</li>
                            <li>Suriname</li>
                            <li>Tajikistan</li>
                            <li>Tanzania</li>
                            <li>Thailand</li>
                            <li>Togo</li>
                            <li>Tonga</li>
                            <li>Trinidad and Tobago</li>
                            <li>Tunisia</li>
                            <li>Turkey</li>
                            <li>Uganda</li>
                            <li>Ukraine</li>
                            <li>Uruguay</li>
                            <li>Uzbekistan</li>
                            <li>Vanuatu</li>
                            <li>Venezuela</li>
                            <li>Vietnam</li>
                            <li>Zimbabwe</li>
                        </ul>
                        <p>
                            <strong>Frequently asked questions (FAQs)</strong>
                        </p>
                        <ul>
                            <li>
                                <p>
                                    <strong>
                                        What is updated Expedia Collect and its
                                        key benefits?
                                    </strong>
                                </p>
                                <p>
                                    Updated Expedia Collect provides more
                                    transparency and critical information on the
                                    pricing breakdown and amounts involved, such
                                    as the exact amount for properties to
                                    collect from Expedia Group, and vice versa
                                    the remittance amount to Expedia Group,
                                    amongst other details. In short, updated
                                    Expedia Collect greatly improves
                                    properties&apos; billing and remittance
                                    experiences.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>
                                        The updated Expedia Collect experience
                                        will replace the Net rates provided by
                                        Expedia Collect reservations with the
                                        full reservation amount (i.e. total
                                        amount paid by the traveler) regardless
                                        of remittance type a property is on
                                        (e.g. Gross or Net).
                                    </strong>
                                </p>
                                <p>
                                    Eligible connectivity partners participating
                                    in the{' '}
                                    <a href="https://developers.expediagroup.com/supply/lodging/cpp/overview">
                                        Connectivity Partner Program{' '}
                                    </a>
                                    may also be recognized on full adoption as
                                    well as communicating with properties on
                                    updated Expedia Collect.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>
                                        What if I don&apos;t work with or
                                        partially work with properties in these
                                        markets?
                                    </strong>
                                </p>
                                <p>
                                    Updated Expedia Collect is a global
                                    initiative with additional markets planned
                                    for 2023 and beyond. We strongly recommend a
                                    complete adoption or validation ahead of
                                    time to ensure a continued smooth experience
                                    for properties.
                                </p>
                                <p>
                                    While newly introduced fields are only
                                    available for properties in markets where
                                    updated Expedia Collect is live, your system
                                    should be able to handle reservations both
                                    with and without the enhancements.
                                </p>
                                <p>
                                    Please contact your connectivity
                                    point-of-contact in Expedia Group to
                                    ascertain how reservations would be
                                    displayed in these different scenarios.
                                    Alternatively, you may also refer to the
                                    respective Booking API (either{' '}
                                    <a href="https://developers.expediagroup.com/supply/lodging/docs/booking_apis/booking_retrieval/getting_started/introduction/">
                                        Booking Retrieval{' '}
                                    </a>
                                    OR{' '}
                                    <a href="https://developers.expediagroup.com/supply/lodging/docs/booking_apis/booking_notification/getting_started/introduction/">
                                        Booking Notification
                                    </a>
                                    ) to understand these differences.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>
                                        What if I am unable to adopt the
                                        enhancements?
                                    </strong>
                                </p>
                                <p>
                                    If for unforeseen circumstances where
                                    verified adoption/validation of updated
                                    Expedia Collect is not possible for
                                    properties in upcoming/launched markets,
                                    remittance instructions are accessible from
                                    the SpecialRequest attribute (i.e.
                                    &lt;SpecialRequest code=&quot;5&quot;&gt;,
                                    see here for Booking Retrieval or Booking
                                    Notification) in text form regardless of
                                    adoption status.
                                </p>
                                <p>
                                    We are still required to pass along the base
                                    rate inclusive of compensation and
                                    applicable taxes/fees applied in the Booking
                                    XML payload. Additional details will always
                                    be available in Expedia Partner Central if
                                    needed.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>
                                        What is the recommended timeline, and
                                        what does the certification process
                                        involve?
                                    </strong>
                                </p>
                                <p>
                                    We recommend full completion of
                                    adoption/validation minimally{' '}
                                    <strong>14 working days prior </strong>
                                    to launch date in ensuring sufficient time
                                    for respective teams to holistically review
                                    and provide guidance.
                                </p>
                                <p>
                                    The adoption/validation process consists of
                                    test reservations with variations of newly
                                    introduced fields. Please contact your
                                    connectivity point-of-contact in Expedia
                                    Group on any testing requirements.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>
                                        If I had adopted these enhancements
                                        previously, what action should I take?
                                    </strong>
                                </p>
                                <p>
                                    If the certification process had been
                                    verified with your connectivity
                                    point-of-contact in Expedia Group, there are
                                    no further actions required. Connected
                                    properties can leverage on the updated
                                    Expedia Collect experience as soon as it is
                                    launched in additional markets.
                                </p>
                            </li>
                        </ul>
                    </section>
                    <section
                        className="blog__entry"
                        id="introducing-aggregate-review-scores"
                    >
                        <TopicTitle className="blog__title">
                            Introducing aggregate review scores
                        </TopicTitle>
                        <br />
                        <span className="blog__date">22 April 2024</span>
                        <p>
                            We are thrilled to announce that we have improved
                            the ability to manage post-booking review
                            communication for lodging partners through updates
                            to the reviews capability of our Lodging Supply
                            GraphQL API. With this release, lodging partners
                            will gain deep insight into aggregate review scores
                            for their properties that will help them improve
                            their performance and stand out in our marketplace.
                        </p>
                        <p>
                            <strong>
                                What’s new for the reviews capability?
                            </strong>
                        </p>
                        <p>
                            You can now query the average review score for a
                            property, including all rating categories, and you
                            can filter reviews by one or more Expedia Group
                            points of sale.
                        </p>
                        <p>
                            <strong>Want to learn more?</strong>
                        </p>
                        <p>
                            Explore documentation:{' '}
                            <a href="https://developers.expediagroup.com/supply/lodging/docs/property_mgmt_apis/reviews/getting_started/intro/">
                                Reviews via API{' '}
                            </a>
                        </p>
                    </section>
                    <section
                        className="blog__entry"
                        id="introducing-messaging-via-api"
                    >
                        <TopicTitle className="blog__title">
                            Introducing messaging via API
                        </TopicTitle>
                        <br />
                        <span className="blog__date">27 March 2024</span>
                        <p>
                            We’re excited to announce the release of the
                            messaging capability in our Lodging Supply GraphQL
                            API. This provides software providers with a set of
                            features that will greatly simplify lodging
                            partners&apos; ability to manage traveler
                            communication and increase traveler trust.
                        </p>
                        <p>
                            <strong>
                                What can you expect from messaging via API?
                            </strong>
                        </p>
                        <ul>
                            <li>Retrieve message threads for a property</li>
                            <li>Retrieve a message thread or message by ID</li>
                            <li>Add a message to a message thread</li>
                        </ul>
                        <p>
                            <strong>Ready to get started? </strong>
                        </p>
                        <p>
                            Explore documentation:{' '}
                            <a href="https://developers.expediagroup.com/supply/lodging/docs/property_mgmt_apis/messaging/getting_started/intro/">
                                Messaging via API{' '}
                            </a>
                        </p>
                    </section>
                    <section
                        className="blog__entry"
                        id="announcing-2025-connectivity-partner-program-benefits-and-qualification-metrics"
                    >
                        <TopicTitle className="blog__title">
                            Announcing 2025 Connectivity Partner Program
                            benefits and qualification metrics
                        </TopicTitle>
                        <br />
                        <span className="blog__date">1 December 2023</span>
                        <p>
                            We are excited to announce the new qualification
                            metrics and benefits of Expedia Group&apos;s 2025
                            Connectivity Partner Program to give our
                            connectivity partners a head start on working
                            towards securing Elite or Preferred status.
                        </p>
                        <p>
                            Running for the fourth consecutive year, the Program
                            is designed to recognize, promote and reward top
                            connectivity partners in the Expedia Group
                            marketplace. We made some changes to our
                            qualification metrics to help our partners grow and
                            achieve great results. And the reward is better than
                            ever before!
                        </p>
                        <p>
                            <a href="https://developers.expediagroup.com/supply/lodging/cpp/overview">
                                See qualification metrics we&apos;re measuring
                                in 2024 for achieving a status in 2025.
                            </a>
                        </p>
                        <p>
                            Don&apos;t miss your chance to participate in the
                            Connectivity Partner Program. Contact your Account
                            Manager today for details or email us at{' '}
                            <a href="mailto:connectivitypartner@expediagroup.com">
                                connectivitypartner@expediagroup.com
                            </a>
                            .
                        </p>
                    </section>
                    <section
                        className="blog__entry"
                        id="introducing-reviews-via-api"
                    >
                        <TopicTitle className="blog__title">
                            Introducing reviews via API
                        </TopicTitle>
                        <br />
                        <span className="blog__date">20 July 2023</span>
                        <p>
                            The release of the reviews capability of our GraphQL
                            API will bring a comprehensive set of tools to
                            connectivity providers looking to streamline their
                            reputation management process. This capability will
                            allow lodging partners to easily manage and gain
                            insights from their reviews.
                        </p>
                        <p>
                            <strong>
                                What can you expect from reviews via API?{' '}
                            </strong>
                        </p>
                        <ul>
                            <li>
                                Retrieve reviews for multiple properties by
                                property and unit ID.
                            </li>
                            <li>
                                Easily respond to traveler reviews within
                                software for a given property.
                            </li>
                            <li>
                                Receive real-time notifications when a traveler
                                leaves a review for a given property.
                            </li>
                        </ul>
                        <p>
                            Refer to the{' '}
                            <a href="https://developers.expediagroup.com/supply/lodging/docs/property_mgmt_apis/reviews/getting_started/intro/">
                                documentation{' '}
                            </a>
                            for full details.
                        </p>
                    </section>
                    <section
                        className="blog__entry"
                        id="announcing-2024-connectivity-partner-program-benefits-and-qualification-metrics"
                    >
                        <TopicTitle className="blog__title">
                            Announcing 2024 Connectivity Partner Program
                            benefits and qualification metrics
                        </TopicTitle>
                        <br />
                        <span className="blog__date">7 December 2022</span>
                        <p>
                            We are excited to announce the new qualification
                            metrics and benefits of Expedia Group&apos;s 2024
                            Connectivity Partner Program to give our
                            connectivity partners a head start on working
                            towards securing Elite or Preferred status.
                        </p>
                        <p>
                            Running for the third consecutive year, the Program
                            is designed to recognize, promote and reward top
                            connectivity partners in the Expedia Group
                            marketplace. We made some changes to our
                            qualification metrics to help our partners grow and
                            achieve great results. And the reward is better than
                            ever before!
                        </p>
                        <p>
                            <a href="https://developers.expediagroup.com/supply/lodging/cpp/overview">
                                See qualification metrics and benefits for 2024.
                            </a>
                        </p>
                        <p>
                            Our 2022 Elite partners endorsed the Program for its
                            market-leading quality and engagement:
                        </p>
                        <p>
                            &quot;Prefer this program to others in the
                            industry.&quot; - Sabre
                        </p>
                        <p>
                            &quot;In recent years, I feel like even more care
                            and attention is provided with the introduction of
                            the partner program. Where other providers seem to
                            have slacked off in their engagement with us,
                            Expedia has stepped it up.&quot; - SHR
                        </p>
                        <p>
                            Don&apos;t miss your chance to participate in the
                            Connectivity Partner Program. Contact your Account
                            Manager today for details or email us at{' '}
                            <a href="mailto:connectivitypartner@expediagroup.com">
                                connectivitypartner@expediagroup.com
                            </a>
                            .
                        </p>
                    </section>
                    <section
                        className="blog__entry"
                        id="introducing_property_status_via_api_and_compliance_via_api"
                    >
                        <TopicTitle className="blog__title">
                            Introducing property status via API and compliance
                            via API
                        </TopicTitle>
                        <br />
                        <span className="blog__date">8 November 2022</span>
                        <p>
                            The bundled release of property status and
                            compliance within our Lodging Supply GraphQL API
                            brings a robust set of capabilities to software
                            providers looking to onboard and validate their
                            properties faster. These new capabilities will
                            automate the process of checking active property
                            status and retrieving content and compliance
                            requirements in your software.
                        </p>
                        <p>
                            <strong>
                                What can you expect from property status via
                                API?
                            </strong>
                        </p>
                        <ol>
                            <li>
                                <strong>Go live</strong> with new properties
                                faster
                            </li>
                            <li>
                                <strong>Reduce</strong> call volume to your
                                support teams about questions
                            </li>
                            <li>
                                Provide actionable tools so partners can{' '}
                                <strong>self-serve</strong>
                            </li>
                            <li>
                                Allow partners to{' '}
                                <strong>maintain visibility</strong> into the
                                current state of their listings
                            </li>
                        </ol>
                        <p>
                            <strong>
                                What can you expect from compliance via API?
                            </strong>
                        </p>
                        <ol>
                            <li>
                                <strong>Manage</strong> Expedia Group regulatory
                                information in one place within software for a
                                given property or jurisdiction (excluding Japan)
                            </li>
                            <li>
                                <strong>Provide real-time requirements</strong>{' '}
                                for a given property or jurisdiction (excluding
                                Japan)
                            </li>
                            <li>
                                <strong>Assist in validating status</strong>{' '}
                                against Expedia Group requirements automatically
                            </li>
                            <li>
                                <strong>Increase operational efficiency</strong>{' '}
                                by streamlining and enhancing the Expedia Group
                                onboarding process
                            </li>
                        </ol>
                        <p>
                            <strong>Ready to get started? </strong>
                        </p>
                        <p>
                            Explore documentation:{' '}
                            <a href="https://developers.expediagroup.com/supply/lodging/docs/property_mgmt_apis/property_status/getting_started/intro/">
                                Property status via API{' '}
                            </a>
                            and{' '}
                            <a href="https://developers.expediagroup.com/supply/lodging/docs/property_mgmt_apis/compliance/getting_started/intro/">
                                Compliance via API
                            </a>
                            .
                        </p>
                    </section>
                    <section
                        className="blog__entry"
                        id="quarterly_performance_scorecards_are_now_available_online"
                    >
                        <TopicTitle className="blog__title">
                            Quarterly performance scorecards are now available
                            online
                        </TopicTitle>
                        <br />
                        <span className="blog__date">28 October 2022</span>
                        <p>
                            We are making progress on improving the experience
                            for our connectivity partners by making it easier to
                            access performance metrics with Expedia Group.
                            Starting today, quarterly scorecards are available
                            online and can be accessed at any time!
                        </p>
                        <p>How to access quarterly performance scorecards:</p>
                        <ol>
                            <li>
                                Go to{' '}
                                <a href="https://developers.expediagroup.com/supply/lodging/scorecard">
                                    https://developers.expediagroup.com/supply/lodging/scorecard
                                </a>
                            </li>
                            <li>Enter SYS_ username and password</li>
                            <li>
                                If asked, complete the two-factor
                                authentication, which is tied to the system
                                administrator’s email and phone number
                            </li>
                            <li>Review your Q3 scorecard</li>
                        </ol>
                        <p>
                            Your online quarterly scorecard will be updated a
                            few weeks after the end of each quarter, and data
                            from previous quarters will be available to allow
                            partners to compare their performance metrics over
                            time. We recommend partners bookmark the{' '}
                            <a href="https://developers.expediagroup.com/supply/lodging/scorecard/">
                                scorecard login page
                            </a>{' '}
                            for future reference.
                        </p>
                        <p>
                            As we approach the end of the year, we encourage all
                            eligible partners to review the{' '}
                            <a href="https://developers.expediagroup.com/supply/lodging/cpp/overview">
                                2023 Connectivity Partner Program qualifying
                                metrics
                            </a>{' '}
                            and use scorecard data to track their progress
                            toward earning status next year.
                        </p>
                        <p>
                            Partners who do not have access to their system
                            login information or have multiple system logins and
                            are unable to access their online scorecard can
                            email us at{' '}
                            <a href="mailto:connectivitypartner@expediagroup.com">
                                connectivitypartner@expediagroup.com
                            </a>{' '}
                            for support.
                        </p>
                    </section>
                    <section
                        className="blog__entry"
                        id="update_to_connectivity_partner_program_2023_qualification_metrics"
                    >
                        <TopicTitle className="blog__title">
                            Update to Connectivity Partner Program 2023
                            qualification metrics
                        </TopicTitle>
                        <br />
                        <span className="blog__date">15 August 2022</span>
                        <p>
                            We are updating two qualification metrics for the
                            2023 Connectivity Partner Program out of an
                            abundance of concern for isolated data issues we
                            have identified.
                        </p>
                        <ul>
                            <li>
                                <strong>Connectivity activation time</strong>{' '}
                                will not be measured during Q1 and Q2 2022 as a
                                performance metric to qualify for the 2023
                                Connectivity Partner Program. However, we have
                                corrected the issue and connectivity activation
                                time will be measured for Q3 and Q4 2022.
                            </li>
                            <li>
                                <strong>API usage</strong> will no longer be
                                counted as a performance metric to qualify for
                                the 2023 Connectivity Partner Program.
                            </li>
                        </ul>
                        <p>
                            Learn more about the Connectivity Partner Program
                            and qualification metrics{' '}
                            <a href="https://developers.expediagroup.com/supply/lodging/cpp/overview">
                                here
                            </a>
                            .
                        </p>
                    </section>
                    <section
                        className="blog__entry"
                        id="new-search-and-sort-factors-coming-soon-to-expedia-group-sites"
                    >
                        <TopicTitle className="blog__title">
                            New search and sort factors coming soon to Expedia
                            Group sites
                        </TopicTitle>
                        <br />
                        <span className="blog__date">24 June 2022</span>
                        <p>
                            Our shared ambition is to deliver great experiences
                            across the entire traveler journey on Expedia Group.
                            To help achieve it, we are taking the first steps to
                            reimagine our marketplace. As a valued connectivity
                            partner, we are excited to work with you to bring
                            this vision to life.
                        </p>
                        <ul>
                            <li>
                                <strong>
                                    Making the traveler experience a key driver
                                    of visibility:
                                </strong>{' '}
                                Starting in August, properties’ position in
                                search results will be impacted by factors
                                related to the strength of their offer and the
                                guest experience they provide.
                            </li>
                            <li>
                                <strong>
                                    Adding new tools in Partner Central:
                                </strong>{' '}
                                Properties can now view personalized
                                opportunities and recommendations to improve
                                their search and sort factors in Partner
                                Central.{' '}
                                <a
                                    href="https://welcome.expediagroup.com/en/tools/improve-the-visibility-of-your-property"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Learn more here.
                                </a>
                            </li>
                        </ul>
                        <p>
                            Contact{' '}
                            <a href="mailto:connectivitypartner@expediagroup.com">
                                connectivitypartner@expediagroup.com
                            </a>{' '}
                            for more information about how our new search and
                            sort factors may impact your connected properties’
                            performance on Expedia Group.
                        </p>
                    </section>
                    <section
                        className="blog__entry"
                        id="introducing-new-brand-guidelines"
                    >
                        <TopicTitle className="blog__title">
                            Introducing new brand guidelines
                        </TopicTitle>
                        <br />
                        <span className="blog__date">21 April 2022</span>
                        <p>
                            Here at Expedia Group, we’re proud to have one the
                            strongest brand families in the travel industry.
                            After all, we’ve spent countless hours meticulously
                            crafting each brand and its reputation. But to
                            maintain our position in the industry, it’s
                            important that our brands’ visual and written
                            identities are consistent, even on our connectivity
                            partners’ websites and marketing materials.
                        </p>
                        <p>
                            To help you use our brands correctly when displaying
                            them alongside your own, we’ve developed a new set
                            of brand guidelines.
                        </p>
                        <p>
                            <strong>About the guide</strong>
                        </p>
                        <p>
                            Our new guide outlines how to use Expedia Group and
                            our family of brands’ logos in a variety of contexts
                            with visual examples.
                        </p>
                        <p>
                            Download the updated Expedia Group Brand Guidelines{' '}
                            <a href="https://brandfolder.com/s/r48h2kwb44rvtg96vkxj6jhr">
                                here
                            </a>
                            .
                        </p>
                        <p>
                            <strong>Questions?</strong>
                        </p>
                        <p>
                            If you have any questions about the guide, please
                            reach out to your account manager.
                        </p>
                        <p>
                            <strong>Agreement</strong>
                        </p>
                        <p>
                            By using the Expedia Group family trademarks and
                            resources, you agree to follow the direction in our
                            brand guidelines, our Terms of Use, and all other
                            Expedia Group rules and policies.
                        </p>
                    </section>
                    <section
                        className="blog__entry"
                        id="enhanced-reservation-retrieval-functionality-and-new-tutorial"
                    >
                        <TopicTitle className="blog__title">
                            Enhanced reservation retrieval functionality and new
                            tutorial
                        </TopicTitle>
                        <br />
                        <span className="blog__date">14 March 2022</span>
                        <p>
                            We are excited to announce enhanced functionality
                            and tooling that will improve your experience with
                            the reservation retrieval feature of the Lodging
                            Supply GraphQL API. With this latest release, we
                            continue to expand the functionality of our
                            reservation capabilities available on the Lodging
                            Supply GraphQL API in order to improve the usability
                            and enhance the product experience.
                        </p>
                        <p>
                            <strong>
                                What are the reservation retrieval feature
                                enhancements?
                            </strong>
                        </p>
                        <ul>
                            <li>
                                <strong>Enhanced querying capability</strong> –
                                You can query by specifying one or more
                                reservation IDs, giving you more flexibility
                                during the reservation retrieval process.
                            </li>
                            <li>
                                <strong>
                                    Eligibility check for reconciliation
                                </strong>{' '}
                                – This feature enables partners to determine
                                reconciliation actions that are available for
                                their reservations. It is intended to function
                                alongside our reservation update capability,
                                which will allow partners to reconcile
                                reservations via GraphQL and will be available
                                later this year.
                            </li>
                        </ul>
                        <p>
                            To review the detailed technical documentation,
                            please visit{' '}
                            <a href="https://developers.expediagroup.com/supply/lodging/docs/booking_apis/reservations/getting_started/intro/">
                                this page
                            </a>
                            .
                        </p>
                        <p>
                            <strong>
                                What is the new reservation retrieval tutorial?
                            </strong>
                        </p>
                        <p>
                            The new reservation retrieval tutorial will allow
                            you to explore the capability and basic
                            functionality in a self-paced environment. You will
                            find information to get started with authentication,
                            endpoint, and queries.{' '}
                            <a href="https://developers.expediagroup.com/supply/lodging/tools/tutorials/reservations_retrieval">
                                Check out the new tutorial here.
                            </a>
                        </p>
                        <p>
                            <strong>What if I have questions? </strong>
                        </p>
                        <p>
                            If you have reviewed the new tools and documentation
                            and still have questions, please reach out to your
                            Technical Relationship Manager.
                        </p>
                    </section>
                    <section
                        className="blog__entry"
                        id="discover-new-self-service-tools"
                    >
                        <TopicTitle className="blog__title">
                            Discover new self-service tools
                        </TopicTitle>
                        <br />
                        <span className="blog__date">16 February 2022</span>
                        <p>
                            We are excited to announce a suite of new tools
                            offered through EG connectivity hub:{' '}
                            <a href="https://developers.expediagroup.com/supply/lodging/tools/tutorials/graphql/welcome/">
                                GraphQL tutorial
                            </a>{' '}
                            and{' '}
                            <a href="https://developers.expediagroup.com/supply/lodging/tools/tutorials/promotions">
                                promotions tutorial
                            </a>
                            . These tools can help streamline your integration
                            process and allow you to develop at your own speed
                            through a self-paced learning environment.
                        </p>
                        <p>
                            <strong>
                                What can I expect from the GraphQL tutorial and
                                promotions tutorial?
                            </strong>
                        </p>
                        <ul>
                            <li>
                                The GraphQL tutorial introduces GraphQL concepts
                                to help developers streamline the integration
                                process.
                            </li>
                            <li>
                                The promotions tutorial introduces the
                                promotions capability to developers and assists
                                in code generation.
                            </li>
                        </ul>
                        <p>
                            <strong>
                                Do I still need to use technical documentation?
                            </strong>
                        </p>
                        <p>
                            Yes! The technical{' '}
                            <a href="https://developers.expediagroup.com/supply/lodging/docs/">
                                documentation
                            </a>{' '}
                            on connectivity hub is crucial to the success of
                            your integration. These new tools are meant to
                            complement the documentation and do not replace it.
                        </p>
                        <p>
                            <strong>What if I still have questions?</strong>
                        </p>
                        <p>
                            If you have reviewed the new tools and documentation
                            and still have questions, please reach out to your
                            Technical Relationship Manager.
                        </p>
                    </section>
                    <section
                        className="blog__entry"
                        id="2023-connectivity-partner-program-benefits-and-qualification-metrics"
                    >
                        <TopicTitle className="blog__title">
                            2023 Connectivity Partner Program benefits and
                            qualification metrics
                        </TopicTitle>
                        <br />
                        <span className="blog__date">5 January 2022</span>
                        <p>
                            Expedia Group’s Connectivity Partner Program is
                            designed to recognize our top connectivity partners
                            and reward them with exclusive benefits. Today, we
                            are pleased to announce the details of our 2023
                            program, giving ample time for our connectivity
                            partners to work toward Elite or Preferred status.
                        </p>
                        <p>
                            For the 2023 Connectivity Partner Program, both
                            Elite and Preferred partners will be eligible for
                            the following potential benefits, unless otherwise
                            noted.
                        </p>
                        <p>
                            <strong>
                                2023 Connectivity Partner Program benefits
                                <sup>1</sup>:
                            </strong>
                        </p>
                        <ul>
                            <li>
                                <strong>Business & financial incentives</strong>
                                <ul>
                                    <li>
                                        Enhanced account management and
                                        technical support
                                    </li>
                                    <li>Quarterly business reviews</li>
                                    <li>
                                        Exclusive financial incentive program
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>
                                    Product collaboration & first access{' '}
                                </strong>
                                <ul>
                                    <li>
                                        Early access to APIs and capabilities
                                        <sup>2</sup>
                                    </li>
                                    <li>Product reviews</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Premium promotion</strong>
                                <ul>
                                    <li>
                                        Promotion as Preferred or Elite
                                        Connectivity Partner
                                    </li>
                                    <li>
                                        Tailored marketing opportunities (Elite
                                        only){' '}
                                    </li>
                                    <li>
                                        Premium event invitation (Elite only)
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <p>
                            <strong>How to qualify</strong>
                        </p>
                        <p>
                            Connectivity partners can secure Elite or Preferred
                            status in the 2023 Connectivity Partner Program by
                            reaching specific goals and business and technical
                            metrics under the categories listed.<sup>3</sup> For
                            the 2023 program, we’ve created the traveler
                            experience category, which includes property
                            optimization initiatives intended to improve the
                            performance of our mutual lodging partners and
                            deliver better experiences for travelers. The full
                            list of 2023 program qualifying metrics are below.
                        </p>
                        <p>
                            <strong>
                                2023 Connectivity Partner Program qualifying
                                metrics
                            </strong>
                        </p>
                        <table className="table table-responsive">
                            <tbody>
                                <tr>
                                    <th>
                                        <strong>Category</strong>
                                    </th>
                                    <th>
                                        <strong>Metric</strong>
                                    </th>
                                </tr>
                                <tr>
                                    <td>Booking value</td>
                                    <td>Stayed room nights</td>
                                </tr>
                                <tr>
                                    <td>Traveler experience</td>
                                    <td>Property optimization initiatives</td>
                                </tr>
                                <tr>
                                    <td rowSpan="2">Engagement quality</td>
                                    <td>Connectivity survey participation</td>
                                </tr>
                                <tr>
                                    <td>Leadership level engagement</td>
                                </tr>
                                <tr>
                                    <td rowSpan="3">Connection experience</td>
                                    <td>API/capability certification</td>
                                </tr>
                                <tr>
                                    <td>New API/capability adoption speed</td>
                                </tr>
                                <tr>
                                    <td>API success rate </td>
                                </tr>
                                <tr>
                                    <td>Onboarding experience</td>
                                    <td>Connectivity activation time</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            <strong>
                                2023 Connectivity Partner Program minimum
                                requirements:
                            </strong>
                        </p>
                        <ul>
                            <li>
                                System type: Channel Manager, Property
                                Management System (PMS), and/or Central
                                Reservation System (CRS)
                            </li>
                            <li>
                                Expedia Group API license agreement acceptance
                            </li>
                            <li>
                                Technical requirements:
                                <ul>
                                    <li>
                                        Availability/Rates API with support for
                                        Expedia Traveler Preference (ETP)
                                    </li>
                                    <li>
                                        Booking Retrieval/Notification API with
                                        support for Expedia Virtual Cards (EVC)
                                        and Value Add Promotions (VAP)
                                    </li>
                                    <li>PCI compliance (where applicable)</li>
                                </ul>
                            </li>
                        </ul>
                        <p>
                            <strong>Timeline</strong>
                        </p>
                        <p>
                            The 2023 Connectivity Partner Program will operate
                            on an annual basis according to the timeline listed
                            below.
                        </p>
                        <ul>
                            <li>
                                January - December 2022: Qualification
                                measurement period for 2023
                            </li>
                            <li>
                                January 2023: Elite and Preferred partners
                                announced for 2023
                            </li>
                        </ul>
                        <p>
                            Contact your Connectivity Account Manager to
                            understand your company’s standing and any action
                            needed to qualify for the 2023 program.
                        </p>
                        <p>
                            View the current list of Elite and Preferred
                            Connectivity Partners on{' '}
                            <a href="https://welcome.expediagroup.com/en/tools/find-recommended-connectivity-provider-for-expedia">
                                Welcome
                            </a>
                            .
                        </p>
                        <p>
                            <sup>1</sup>The list of benefits offered in the
                            program is subject to change by Expedia and benefits
                            with additional qualifying criteria or availability
                            considerations are noted.
                            <br />
                            <sup>2</sup>Expedia will determine in its sole
                            discretion whether any API or capability is offered
                            to connectivity partners qualified to receive this
                            benefit.
                            <br />
                            <sup>3</sup>Expedia will determine in its sole
                            discretion whether connectivity partners are
                            accepted or can remain in the program.
                        </p>
                    </section>
                    <section
                        className="blog__entry"
                        id="announcing-benefits-for-the-2022-connectivity-partner-program"
                    >
                        <TopicTitle className="blog__title">
                            Announcing benefits for the 2022 Connectivity
                            Partner Program
                        </TopicTitle>
                        <br />
                        <span className="blog__date">13 September 2021</span>
                        <p>
                            Expedia Group’s Connectivity Partner Program is
                            designed to reward and recognize our top
                            connectivity partners. Our inaugural program
                            launched in 2021 and we look forward to continuing
                            the momentum into the next year.
                        </p>
                        <p>
                            <strong>
                                Program benefits for 2022<sup>1</sup>
                            </strong>
                        </p>
                        <p>
                            Expedia Group Preferred and Elite Partners gain
                            access to:
                        </p>
                        <ul>
                            <li>
                                <strong>Business advantages</strong>
                                <ul>
                                    <li>
                                        Financial benefits<sup>2</sup> (Elite
                                        only)
                                    </li>
                                    <li>Priority access to support teams</li>
                                    <li>In-depth performance reviews</li>
                                    <li>
                                        Key insights & optimization
                                        recommendations
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>
                                    Product collaboration & first access{' '}
                                </strong>
                                <ul>
                                    <li>Product roadmap previews</li>
                                    <li>Early access to APIs</li>
                                    <li>
                                        Pilot invitations<sup>3</sup>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Premium promotion</strong>
                                <ul>
                                    <li>
                                        Promotion as Preferred or Elite
                                        Connectivity Partner
                                    </li>
                                    <li>
                                        Exclusive co-marketing opportunities
                                        (Elite only){' '}
                                    </li>
                                    <li>
                                        Special guest at EG Partner events
                                        <sup>3</sup> (Elite only)
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <p>
                            <sup>1</sup>List of benefits is subject to change
                            <br />
                            <sup>2</sup>Subject to qualification
                            <br />
                            <sup>3</sup>Subject to availability
                        </p>
                        <p>
                            <strong>How to qualify</strong>
                        </p>
                        <p>
                            Connectivity providers can secure Elite or Preferred
                            Partner status by meeting specific goals and
                            business metrics under the categories listed. Please
                            work with your connectivity account manager to get
                            details on any actions needed to help you qualify.
                        </p>
                        <p>
                            <strong>
                                Program qualification criteria for 2022
                            </strong>
                        </p>
                        <ul>
                            <li>Production: Stayed room nights</li>
                            <li>
                                Connection experience:
                                <ul>
                                    <li>API / feature certification</li>
                                    <li>New API / feature adoption speed</li>
                                    <li>PCI compliance</li>
                                    <li>API success rates</li>
                                    <li>API usage</li>
                                </ul>
                            </li>
                            <li>
                                Onboarding experience: Connectivity activation
                                time
                            </li>
                            <li>
                                Property experience: Property optimization
                                initiatives
                            </li>
                            <li>
                                Engagement:
                                <ul>
                                    <li>Leadership level engagement</li>
                                    <li>Survey participation</li>
                                </ul>
                            </li>
                        </ul>
                        <p>
                            <strong>Timeline</strong>
                        </p>
                        <p>
                            The 2022 program qualification period will end in
                            December 2021. The program will operate annually,
                            running from January – December 2022.
                        </p>
                        <p>Upcoming 2022 program dates:</p>
                        <ul>
                            <li>
                                July – December 2021 — Program qualification
                                measurement period for 2022
                            </li>
                            <li>
                                Est. January 2022 – Elite and Preferred partners
                                announced for 2022 program
                            </li>
                        </ul>
                        <p>
                            Contact your Connectivity Account Manager if you
                            have any questions on the 2022 program benefits and
                            the qualification criteria.
                        </p>
                    </section>
                    <section
                        className="blog__entry"
                        id="announcing-our-2022-connectivity-partner-program"
                    >
                        <TopicTitle className="blog__title">
                            Announcing our 2022 Connectivity Partner Program
                        </TopicTitle>
                        <br />
                        <span className="blog__date">22 June 2021</span>
                        <p>
                            Expedia Group’s Connectivity Partner Program is
                            designed to reward and recognize our top
                            connectivity partners. Our inaugural program
                            launched in 2021 and we look forward to continuing
                            the momentum into next year.
                        </p>
                        <p>
                            <strong>What’s changed?</strong>
                        </p>
                        <p>
                            Connectivity providers could be eligible for Elite
                            or Preferred program status based on a variety of
                            metrics. For the 2022 program, we’ve updated the
                            qualification metrics based on feedback from
                            connectivity providers and business stakeholders.
                        </p>
                        <ul>
                            <li>
                                <p>
                                    <strong>Improved transparency</strong> with
                                    opportunity to earn Preferred Partner status
                                    with clear minimum qualifying metric
                                    thresholds.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Introduced a new optimization initiative
                                    metric{' '}
                                    <strong>
                                        tailored for your connected properties
                                    </strong>{' '}
                                    and developed by your account manager.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>
                                        Reward your efforts for property-level
                                        performance
                                    </strong>{' '}
                                    with new API success rates and usage
                                    metrics.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>
                                        Simplified production metrics
                                    </strong>{' '}
                                    by focusing on stayed room nights only.
                                </p>
                            </li>
                        </ul>
                        <p>
                            <strong>How to qualify</strong>
                        </p>
                        <p>
                            We’re sharing high-level 2022 qualification metrics
                            to provide connectivity partners as much time as
                            possible to work toward goals of securing Elite or
                            Preferred status. Please work with your account
                            manager to get details on your account and any
                            action needed to help you qualify.
                        </p>
                        <p>
                            <strong>
                                See the full list of 2022 qualification criteria
                                below
                            </strong>{' '}
                            (updated or new metrics indicated by *).
                        </p>
                        <ul>
                            <li>
                                <p>Production: Stayed room nights*</p>
                            </li>
                            <li>
                                <p>Connection experience:</p>
                                <ul>
                                    <li>
                                        <p>API / feature certification</p>
                                    </li>
                                    <li>
                                        <p>New API / feature adoption speed</p>
                                    </li>
                                    <li>
                                        <p>PCI compliance</p>
                                    </li>
                                    <li>
                                        <p>API success rates*</p>
                                    </li>
                                    <li>
                                        <p>API usage*</p>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p>
                                    Onboarding experience: Connectivity
                                    activation time
                                </p>
                            </li>
                            <li>
                                <p>
                                    Property experience: Property optimization
                                    initiatives*
                                </p>
                            </li>
                            <li>
                                <p>Engagement:</p>
                                <ul>
                                    <li>
                                        <p>Leadership level engagement</p>
                                    </li>
                                    <li>
                                        <p>Survey participation</p>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <p>
                            <strong>Timeline</strong>
                        </p>
                        <p>
                            The 2022 program qualification period will end in
                            December 2021. The program will operate annually,
                            running from January – December 2022.
                        </p>
                        <p>Key 2022 program dates:</p>
                        <ul>
                            <li>
                                <p>June 2021 — Program criteria announced</p>
                            </li>
                            <li>
                                <p>
                                    July – December 2021 — Program qualification
                                    measurement period for 2022
                                </p>
                            </li>
                            <li>
                                <p>
                                    Est. January 2022 – Elite and Preferred
                                    partners announced for 2022 program
                                </p>
                            </li>
                        </ul>
                        <p>
                            <strong>Benefits</strong>
                        </p>
                        <p>
                            Connectivity providers who qualify for Elite or
                            Preferred program status will be eligible to access
                            a variety of benefits as a part of the program.
                            Specific details of the 2022 program benefits will
                            be communicated at a later point, but broadly the
                            benefits will include:
                        </p>
                        <ul>
                            <li>
                                <p>
                                    Business advantages and financial benefits
                                </p>
                            </li>
                            <li>
                                <p>Product collaboration and first access</p>
                            </li>
                            <li>
                                <p>Premium promotion</p>
                            </li>
                        </ul>
                        <p>
                            Don’t miss your chance to qualify for Elite or
                            Preferred status next year!
                        </p>
                        <p>
                            Contact your Connectivity Account Manager to get
                            more information on the 2022 program and the
                            qualification details.
                        </p>
                    </section>
                    <section
                        className="blog__entry"
                        id="introducing-reservation-retrieval-via-api"
                    >
                        <TopicTitle className="blog__title">
                            Introducing reservation retrieval via API
                        </TopicTitle>
                        <br />
                        <span className="blog__date">4 May 2021</span>
                        <p>
                            The launch of reservation retrieval via API will
                            make it easier for properties to migrate onto your
                            connectivity solution from another, allowing them to
                            seamlessly map existing reservations to your system.
                            This will also benefit properties by enabling them
                            to retrieve booked and cancelled reservations
                            through a check out date range query of Expedia
                            Group point-of-sale reservations directly in your
                            connectivity solution.
                        </p>
                        <p>
                            What can you expect from reservation retrieval via
                            API?
                        </p>
                        <ul>
                            <li>
                                <p>
                                    Make it easier for properties to view, read
                                    and retrieve reservation information
                                </p>
                            </li>
                            <li>
                                <p>
                                    Streamline new property onboarding to your
                                    connectivity solution
                                </p>
                            </li>
                            <li>
                                <p>
                                    Remove manual effort required to import and
                                    manage a property’s existing reservations
                                </p>
                            </li>
                        </ul>
                        <p>
                            Ultimately, reservations retrieval via API will help
                            you show immediate value to allow new properties and
                            help them to get up and running on your connectivity
                            solution faster.
                        </p>
                        <p>Ready to get started?</p>
                        <p>
                            Explore the documentation{' '}
                            <a href="https://developers.expediagroup.com/supply/lodging/docs/booking_apis/reservations/getting_started/intro/">
                                here
                            </a>{' '}
                            or download the{' '}
                            <a href="/supply/lodging/docs/file/EGReservations_Launch_Kit.pdf">
                                launch kit
                            </a>
                            .
                        </p>
                    </section>
                    <section
                        className="blog__entry"
                        id="custom-cancellation-policies-are-here"
                    >
                        <TopicTitle className="blog__title">
                            Custom cancellation policies are here
                        </TopicTitle>
                        <br />
                        <span className="blog__date">22 February 2021</span>
                        <p>
                            We’ve heard feedback from you and your properties
                            that more flexibility is needed when it comes to
                            cancellation policies. To provide your properties
                            with this flexibility, especially in uncertain
                            times, we have developed the option to move from two
                            to three-tiered cancellation policies within the
                            Product API and it’s ready for you to integrate.
                        </p>
                        <p>
                            With custom cancellation policies, your properties
                            are not only able to maximize revenue by offering
                            more options for all traveler preferences but also
                            gain traveler trust, as traveler plans may change.
                        </p>
                        <p>How it works:</p>
                        <ul>
                            <li>
                                <p>
                                    Your properties now have the option to
                                    specify up to three cancellation penalty
                                    windows per rate plan. This can be performed
                                    via Rate Plan POST/create, Rate Plan
                                    PUT/full overlay, and Rate Plan
                                    PATCH/partial update.
                                </p>
                            </li>
                            <li>
                                <p>
                                    To encourage travelers to book during these
                                    uncertain times, we highly recommend you
                                    simplify your policies and offer flexible,
                                    refundable rate plans with 24-hour
                                    cancellation policy.
                                </p>
                            </li>
                        </ul>
                        <p>
                            If you’re interested in updating your Product API
                            integration with these enhancements, visit the
                            “Cancellation and change policy” section of{' '}
                            <a href="https://developers.expediagroup.com/supply/lodging/docs/property_mgmt_apis/product/getting_started/requirements-best-practices/">
                                Requirements and best practices
                            </a>{' '}
                            in the Product API documentation to get started.
                        </p>
                        <p>
                            If you have any questions or need additional help,
                            please contact your Account Manager.
                        </p>
                    </section>
                    <section
                        className="blog__entry"
                        id="enhancements-to-expedia-quick-connect-availability-and-rates-api"
                    >
                        <TopicTitle className="blog__title">
                            Enhancements to Expedia Quick Connect Availability
                            and Rates API
                        </TopicTitle>
                        <br />
                        <span className="blog__date">1 December 2020</span>
                        <p>
                            <strong>
                                Enabling multiple requests on the same property
                            </strong>
                        </p>
                        <p>
                            Previously, Expedia Quick Connect (EQC) only allowed
                            updates to availability and rates on one integrated
                            connection at a time for each property. We’re
                            pleased to announce that now, Expedia Quick Connect
                            partners can send multiple, concurrent requests to
                            update availability and rates on the same hotel.
                        </p>
                        <p>Benefits to this change:</p>
                        <ul>
                            <li>
                                <p>Faster processing time</p>
                            </li>
                            <li>
                                <p>Increased capacity</p>
                            </li>
                            <li>
                                <p>More accurate, real-time data</p>
                            </li>
                            <li>
                                <p>Lowered risk of error</p>
                            </li>
                        </ul>
                        <p>
                            If you are not queuing up Availability and Rates
                            requests for the same property in your current EQC
                            interface, <u>then no action</u> is needed to
                            leverage this functionality. If you are queuing
                            messages per property, you may want to remove this
                            logic to take advantage of this enhancement.
                        </p>
                        <p>
                            <em>
                                <strong>Note:</strong> Do not perform more than
                                one simultaneous request against the same
                                product and date. For any given product on a
                                particular day, do not attempt to send two
                                concurrent messages. Always wait for a message
                                to be responded by Expedia before sending a
                                subsequent message.
                            </em>
                        </p>
                        <p>
                            <strong>
                                Increasing the number of updates sent in
                                messages
                            </strong>
                        </p>
                        <p>
                            Previously, Expedia Quick Connect (EQC) Availability
                            and Rates API only allowed 3,000 updates to be sent
                            in one message. We have extended the number of
                            updates to 5,000 to increase efficiency and allow
                            for quicker processing.
                        </p>
                        <p>
                            To take advantage of this enhancement in updates
                            that can be sent through the API, you will need to
                            make integration changes that maximize for this new
                            capacity.
                        </p>
                        <p>
                            For more information, take a look at{' '}
                            <a href="https://developers.expediagroup.com/supply/lodging/docs/avail_and_rate_apis/avail_rates/getting_started/introduction/">
                                the documentation
                            </a>
                            .
                        </p>
                    </section>
                    <section
                        className="blog__entry"
                        id="expedia-group-connectivity-partner-program-benefits-launching-q2-2021"
                    >
                        <TopicTitle className="blog__title">
                            Expedia Group Connectivity Partner Program benefits
                            launching Q2 2021
                        </TopicTitle>
                        <br />
                        <span className="blog__date">17 November 2020</span>
                        <p>
                            To recognize top lodging connectivity partners,
                            we’re excited to announce the upcoming launch of the
                            Expedia Group Connectivity Partner Program. To gain
                            access to exclusive benefits, connectivity partners
                            can qualify based on their standing across various
                            engagement quality and technical criteria metrics.
                        </p>
                        <p>
                            Expedia Group is releasing the program criteria and
                            benefits outlined below to provide time for partners
                            to prepare before the full program launch in April
                            2021. The program provides generous benefits for two
                            qualifying tiers, which may include business
                            advantages, special product access, collaboration,
                            and premium promotion through co-marketing.
                        </p>
                        <p>
                            See below for an overview of program benefits and
                            qualifications, along with a{' '}
                            <a href="https://www.youtube.com/watch?v=A6PVXi8aWZA&feature=youtu.be">
                                short video
                            </a>{' '}
                            outlining complete program details.{' '}
                            <strong>
                                Contact your Connectivity Account Manager to
                                understand what you need to focus on to qualify.
                            </strong>
                        </p>
                        <p>
                            <strong>Program Qualifications</strong>
                        </p>
                        <p>
                            Connectivity providers will be eligible for Elite or
                            Preferred program status based on a variety of
                            metrics. However, Expedia will determine in its sole
                            discretion whether connectivity partners are
                            accepted or can remain in the program.
                        </p>
                        <p>Metrics focus on:</p>
                        <ul>
                            <li>
                                <p>Partnership value</p>
                            </li>
                            <li>
                                <p>Engagement quality</p>
                            </li>
                            <li>
                                <p>Connection quality</p>
                            </li>
                            <li>
                                <p>Property quality</p>
                            </li>
                            <li>
                                <p>Onboarding quality</p>
                            </li>
                        </ul>
                        <p>
                            <strong>
                                Exclusive Benefits<sup>1</sup>
                            </strong>
                        </p>
                        <p>
                            Expedia Group Preferred and Elite Partners gain
                            access to:
                        </p>
                        <ul>
                            <li>
                                <p>
                                    <strong>Business advantages</strong>
                                </p>
                                <ul>
                                    <li>
                                        <p>
                                            Financial incentives<sup>2</sup>{' '}
                                            (Elite only)
                                        </p>
                                    </li>
                                    <li>
                                        <p>Priority access to support teams</p>
                                    </li>
                                    <li>
                                        <p>
                                            In-depth performance reviews (Elite
                                            only){' '}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Key insights & optimization
                                            recommendations (Elite only)
                                        </p>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p>
                                    <strong>
                                        Product Collaboration & First Access{' '}
                                    </strong>
                                </p>
                                <ul>
                                    <li>
                                        <p>Product roadmap previews </p>
                                    </li>
                                    <li>
                                        <p>Early access to APIs </p>
                                    </li>
                                    <li>
                                        <p>
                                            Pilot invitations<sup>3</sup>
                                        </p>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p>
                                    <strong>Premium Promotion </strong>
                                </p>
                                <ul>
                                    <li>
                                        <p>
                                            Promotion as Preferred or Elite
                                            Connectivity Partner
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Exclusive co-marketing opportunities
                                            (Elite only){' '}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Special guest at EG Partner events
                                            <sup>3</sup> (Elite only)
                                        </p>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <p>
                            <sup>1</sup>List of benefits is subject to change
                            <br />
                            <sup>2</sup>Subject to qualification
                            <br />
                            <sup>3</sup>As available
                        </p>
                        <p>
                            <strong>
                                Watch this{' '}
                                <a href="https://www.youtube.com/watch?v=A6PVXi8aWZA&feature=youtu.be">
                                    short video
                                </a>{' '}
                                for program details.
                            </strong>
                        </p>
                        <p>
                            Contact your Connectivity Account Manager to
                            understand your company’s standing and any action
                            needed to be eligible.
                        </p>
                    </section>
                    <section
                        className="blog__entry"
                        id="introducing-promotions-via-api"
                    >
                        <TopicTitle className="blog__title">
                            Introducing promotions via API
                        </TopicTitle>
                        <br />
                        <span className="blog__date">9 November 2020</span>
                        <p>
                            The launch of Promotions within the GraphQL API will
                            enable properties to manage their own promotions
                            within your software. Promotions via API will
                            streamline the process for properties to set up the
                            most widely used promotion types and unlock the
                            ability to manage promotions at scale.
                        </p>
                        <p>
                            <strong>
                                What can you expect from promotions via API?
                            </strong>
                        </p>
                        <p>
                            The Promotions capabilities within the GraphQL API
                            provide provide properties with the ability to
                        </p>
                        <ul>
                            <li>
                                <p>Create promotions based on</p>
                                <ul>
                                    <li>
                                        <p>
                                            A single discount that is applied to
                                            all nights of stay
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            A multi-night promotion to reward
                                            those who have longer stays
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Day of the week promotions to manage
                                            occupancy fluctuations with deals
                                            for certain days only
                                        </p>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p>
                                    Offer same day deals for a one-time discount
                                    off any room
                                </p>
                            </li>
                            <li>
                                <p>
                                    Offer early booking discounts to incentivize
                                    guests to book early
                                </p>
                            </li>
                        </ul>
                        <p>
                            With promotions via API, properties will be able to
                            create, modify, and query for one or more promotions
                            that are active within your platform. Note that any
                            promotion type not included above will continue to
                            be managed through Expedia Group’s Partner Central.
                            We will explore prioritizing additional API
                            functionality and expand supported promotion types
                            in the future.
                        </p>
                        <p>
                            <strong>
                                Important: Which property types are supported by
                                promotions via API?
                            </strong>
                        </p>
                        <p>
                            Please note that large chains are <em>not</em>{' '}
                            supported by promotions via API. Except for large
                            chains, all other property types can take advantage
                            of promotions via API within your platform once you
                            integrate.
                        </p>
                        <p>
                            <strong>
                                Promotions via API is supported by the GraphQL
                                Explorer
                            </strong>
                        </p>
                        <p>
                            Promotions via API is a capability within our
                            GraphQL API, which includes other tools like the
                            GraphQL explorer. The explorer is designed to help
                            you quickly familiarize yourself with the GraphQL
                            API and allows you to execute queries against test
                            properties. To understand the performance of
                            properties in production, you will need to engage
                            with your Account Manager.
                        </p>
                        <p>
                            <strong>Ready to get started?</strong>
                        </p>
                        <p>
                            Explore the documentation{' '}
                            <a href="https://developers.expediagroup.com/supply/lodging/docs/avail_and_rate_apis/promotions/getting_started/intro/">
                                here
                            </a>
                            , or download the{' '}
                            <a href="/supply/lodging/docs/file/EGPromotions_Provider_Handbook.pdf">
                                Provider Handbook and Developer Guide
                            </a>
                            .
                        </p>
                    </section>
                    <section
                        className="blog__entry"
                        id="pci-compliance-requirements-at-expedia-group"
                    >
                        <TopicTitle className="blog__title">
                            PCI compliance requirements at Expedia Group
                        </TopicTitle>
                        <br />
                        <span className="blog__date">6 October 2020</span>
                        <p>
                            Expedia Group is committed to the secure handling
                            and transfer of guest payment card information. We
                            fully comply with PCI standards and also require
                            that connectivity providers who partner with us
                            comply with industry standards before we share any
                            payment card information with their systems. In this
                            post, we answer a few common questions to help
                            demystify PCI compliance, and help you understand
                            how industry regulations may impact your connection
                            to Expedia Group. For more information, please
                            contact your Expedia Group account manager.
                        </p>
                        <p>
                            <strong>What is PCI compliance?</strong>
                        </p>
                        <p>
                            The{' '}
                            <a href="https://www.pcisecuritystandards.org/pci_security/">
                                Payment Card Industry
                            </a>{' '}
                            Data Security Standard (PCI DSS) council was
                            established in 2006 by five major credit card
                            brands. The council established a set of 12 specific
                            requirements to meet six different goals,{' '}
                            <a href="http://www.onlinetech.com/resources/references/what-is-pci-compliance">
                                including
                            </a>{' '}
                            building and maintaining a secure network,
                            implementing strong access control measures, and
                            protecting cardholder data. All companies that
                            accept credit card payment information must be PCI
                            compliant and provide Expedia with an Attestation of
                            Compliance (AOC)
                        </p>
                        <p>
                            <strong>What is an AOC?</strong>
                        </p>
                        <p>
                            The{' '}
                            <a href="https://www.pcisecuritystandards.org/documents/PCI_DSS_Glossary_v3-2.pdf">
                                Attestation of Compliance (AOC)
                            </a>{' '}
                            is defined by the council as:
                        </p>

                        <blockquote>
                            <p>
                                A form for merchants and service providers to
                                attest to the results of a PCI DSS assessment,
                                as documented in the Self-Assessment
                                Questionnaire or Report on Compliance.
                            </p>
                        </blockquote>
                        <p>
                            In other words, the AOC is proof that you comply
                            with industry standards. Expedia Group must have a
                            copy of a current and valid AOC on file for every
                            connectivity provider.
                        </p>
                        <p>
                            <strong>
                                How frequent should I provide an AOC to Expedia
                                Group?
                            </strong>
                        </p>
                        <p>
                            Once a year. We undergo a yearly audit with all
                            connectivity partners to ensure they continue to
                            comply with industry standards.
                        </p>

                        <p>
                            <strong>
                                What is TLS and is it different than PCI
                                compliance?
                            </strong>
                        </p>
                        <p>
                            Transport Layer Security (TLS) is a cryptographic
                            protocol used to establish a secure communications
                            channel between systems. To partner with Expedia
                            Group, you must be TLS 1.2 or higher
                        </p>
                        <p>
                            <strong>
                                What happens if I can’t confirm my compliance
                                with industry standards?
                            </strong>
                        </p>
                        <p>
                            If you are interested in working with Expedia Group,
                            you must provide proof of compliance in order to
                            partner with us.
                        </p>
                        <p>
                            Connectivity partners must meet the following
                            security requirements to receive payment card
                            information from Expedia Group:
                        </p>
                        <ul>
                            <li>
                                <p>
                                    Have a current and valid AOC on file with
                                    Expedia Group
                                </p>
                            </li>
                            <li>
                                <p>TLS 1.2 or higher</p>
                            </li>
                        </ul>
                        <p>
                            Failure to meet either of these requirements will
                            result in Expedia Group blocking all payment card
                            information from being shared. This means that all
                            reservations shared between Expedia Group and the
                            connectivity partner will not include payment
                            information.
                        </p>
                        <p>
                            <strong>Questions?</strong>
                        </p>
                        <p>
                            The security of our customers and their data is a
                            key priority for us and something we take seriously.
                            Expedia Group is committed to working with each of
                            our connectivity providers to ensure the systems we
                            use to share information are safe and secure. If you
                            have questions about your connection or about the
                            type of information that Expedia Group is sharing,
                            or not sharing, with your system – please contact
                            your dedicated account manager.
                        </p>
                    </section>

                    <section
                        className="blog__entry"
                        id="introducing-the-new-expedia-group-connectivity-hub"
                    >
                        <TopicTitle className="blog__title">
                            Introducing: the new Expedia Group connectivity hub
                        </TopicTitle>
                        <br />
                        <span className="blog__date">14 September 2020</span>
                        <p>Welcome!</p>
                        <p>
                            We are excited to share the first phase of our new
                            approach to connectivity, the Expedia Group
                            connectivity hub. In the future, the connectivity
                            hub will be your one-stop-shop for all things
                            connecting your lodging partners. The start to this
                            iterative approach is a new look and feel of the
                            website and an enhanced documentation structure for
                            our APIs-- but there&#x27;s much more to come!
                        </p>
                        <p>
                            We’ve heard your feedback and appreciate your
                            participation to help influence our product and
                            feature roadmap. The ongoing enhancement plan for
                            Expedia Group connectivity hub includes ways for us
                            to better support your ability to integrate with us,
                            as well as provide the best-in-class product
                            offerings for your lodging partners.
                        </p>
                        <p>
                            Over the coming months, you will notice new features
                            in the connectivity hub that are based on the things
                            you ranked most important in our recent survey as
                            well as direct feedback from our conversations with
                            you. For example, coming very soon will be the
                            number one requested tool—a GraphQL integration
                            playground. With this self-service sandbox, you or
                            your developers will be able to test GraphQL
                            integrations up front and make any necessary edits
                            in the testing environment before going live. And
                            that’s just to start!
                        </p>
                        <p>
                            We hope you’re as excited as we are about both the
                            new site and the things to come. Please take a look
                            around and most importantly, stay tuned for more!
                        </p>
                        <p>Questions or feedback?</p>
                        <p>
                            We want to hear from you! Please feel free to reach
                            out to your account manager!
                        </p>
                    </section>
                    <section
                        className="blog__entry"
                        id="covid-19-revive-and-relief"
                    >
                        <TopicTitle className="blog__title">
                            COVID-19: Revive and Relief
                        </TopicTitle>
                        <br />
                        <span className="blog__date">21 June 2020</span>
                        <p>
                            Expedia Group recently announced its commitment to
                            help partners rebound from the impact of COVID-19
                            and fuel industry-wide recovery efforts.
                        </p>
                        <p>
                            Expedia Group’s recovery program is comprised of
                            global initiatives to support industry recovery and
                            property-level relief designed to help independent
                            partners and small chains rebuild their business,
                            attract high-value guests, and optimize cash flow.
                        </p>
                        <p>
                            You can view a summary of Expedia Group’s full
                            recovery program{' '}
                            <a href="https://www.prnewswire.com/news-releases/expedia-group-commits-275-million-to-partner-recovery-301066450.html?utm_campaign=later-linkinbio-expediaforproperties&amp;utm_content=later-7956284&amp;utm_medium=social&amp;utm_source=instagram">
                                here
                            </a>
                            .
                        </p>
                        <p>
                            Specific to lodging partners, Expedia Group is
                            committing $250 million in marketing credits and
                            financial relief.
                        </p>
                        <ul>
                            <li>
                                For each property that participates in the
                                program, we will reinvest 25% of the
                                compensation earned in 2019 from the property
                                into marketing credits for use with Expedia
                                Group.
                            </li>
                            <li>
                                We are also reducing compensation on all new
                                bookings made within the three-month program
                                period, regardless of the actual stay dates.
                            </li>
                            <li>
                                Lastly, we are extending payment terms for Hotel
                                Collect bookings to 90 days to provide
                                additional financial relief.
                            </li>
                        </ul>
                        <p>
                            The marketing credits and financial relief measures
                            will become available to lodging partners based on
                            recovery signals, including demand trends, from
                            their specific markets. This approach will provide
                            our partners with the support they need when it will
                            be most beneficial. There are minimum requirements
                            that partners need to fulfill to participate in the
                            program.
                        </p>
                        <p>
                            If you have questions about the Revive and Relief
                            Program for lodging partners and how it is being
                            implemented, please contact your Expedia Group
                            Account Manager.
                        </p>
                        <p>
                            Additional resources to guide recovery strategies
                            can be found in the{' '}
                            <a href="https://welcome.expediagroup.com/en/covid-resource-center">
                                Expedia Group COVID-19 resource center
                            </a>
                            .
                        </p>
                    </section>

                    <section
                        className="blog__entry"
                        id="covid-19-cancellation-policy-update-for-stays-after-july-1-2020"
                    >
                        <TopicTitle className="blog__title">
                            COVID-19: Cancellation policy update for stays after
                            July 1, 2020
                        </TopicTitle>
                        <br />
                        <span className="blog__date">21 June 2020</span>
                        <p>
                            The ability to travel in the wake of COVID-19 is
                            something we monitor constantly. And while we’re
                            thrilled to see restrictions lifting in many parts
                            of the world, our hearts go out to partners in
                            countries still battling to control the pandemic.
                            Once again, we’d like to update you on the
                            adjustments we’ve made to our cancellation policies
                            to reflect the current circumstances.
                        </p>
                        <p>
                            <strong>
                                Bookings made on <em>March 20,2020</em> and
                                beyond will honor property cancellation policies
                            </strong>
                        </p>
                        <p>
                            Reservations made after the risk of booking
                            non-refundable and partially refundable rooms was
                            widely known will not be subject to our COVID-19
                            force majeure policy. We will honor the property’s
                            cancellation policies for both domestic and
                            international reservations.
                        </p>
                        <p>
                            <strong>
                                Moving away from a global policy for bookings
                                made before <em>March 20, 2020</em>
                            </strong>
                        </p>
                        <p>
                            Now that individual governments are relaxing
                            controls and opening borders, we are evolving our
                            policies to align with their decision-making.
                        </p>
                        <ul>
                            <li>
                                <p>
                                    If travel restrictions have been lifted, we
                                    will honor the property’s published
                                    cancellation policies.
                                </p>
                            </li>
                            <li>
                                <p>
                                    However, if government requirements, flight
                                    cancellations or other COVID-19 restrictions
                                    remain in place, we will allow guests to
                                    cancel existing bookings.
                                </p>
                                <p>
                                    Previously, we gave properties the option to
                                    offer either vouchers or refunds for most
                                    non-refundable bookings cancelled under our
                                    policy. We are extending that decision (made
                                    by the property or their headquarters) to
                                    July and allowing them to change their
                                    selection in Partner Central.
                                </p>
                            </li>
                        </ul>
                        <p>
                            Please continue to visit{' '}
                            <a href="https://go.expediagroup.com/covid-19.html">
                                our COVID-19 force majeure page
                            </a>
                            to understand implications by market.
                        </p>
                        <p>
                            <strong>Helping with recovery strategies</strong>
                        </p>
                        <p>
                            As guests return to booking holidays, they will have
                            more choices than ever before. To help properties
                            and ensure they are ready to attract guests’
                            attention, we’ve created a{' '}
                            <a href="https://welcome.expediagroup.com/en/covid-resource-center">
                                COVID-19 Resource Center.
                            </a>{' '}
                            It consolidates the latest insights on guest
                            behaviors, travel trends and industry data, as well
                            as tips and best practices for bouncing back. Feel
                            free to share this resource with our shared
                            customers.
                        </p>
                        <p>
                            If you have questions, please contact your Expedia
                            Group Account Manager.
                        </p>
                    </section>

                    <section
                        className="blog__entry"
                        id="updates-to-eqc-connectivity-error-report"
                    >
                        <TopicTitle className="blog__title">
                            Updates to EQC connectivity error report
                        </TopicTitle>
                        <br />
                        <span className="blog__date">29 April 2020</span>
                        <p>
                            It’s important that all connected systems meet
                            standards and operate at top performance. A
                            connection that performs well reduces a property’s
                            risk of overbookings or relocations and helps avoid
                            issues with the competitiveness of their rates and
                            availability.
                        </p>
                        <p>
                            To help ensure that you understand the health of
                            your Expedia QuickConnect (EQC) connections with
                            Expedia Group and are aware of opportunities for
                            improvement, we’re updating our{' '}
                            <strong>EQC connectivity error report.</strong>
                        </p>
                        <p>
                            Based on feedback from our partners, we’ve made
                            improvements to the report that enables you to:
                        </p>
                        <p>
                            • Understand what information is in the report and
                            how to use it – including a dashboard view of your
                            overall performance, a summary view that identifies
                            the errors that are impacting your performance most
                            and detailed views of errors and warnings for each
                            API
                        </p>
                        <p>
                            • Use recommended solutions to troubleshoot and
                            resolve issues quickly – additional information and
                            technical documentation is also available at{' '}
                            <a href="https://expediaconnectivity.com/developer">
                                https://expediaconnectivity.com/developer
                            </a>
                            .
                        </p>
                        <p>
                            As a reminder, to assist properties with finding the
                            connectivity provider that best suits their needs,
                            our Connectivity Provider Guide shares insights into
                            your performance metrics; including Availability
                            success and Booking success. You can view the guide
                            at{' '}
                            <a href="https://provider.expediapartnercentral.com/guide">
                                https://provider.expediapartnercentral.com/guide
                            </a>
                            .
                        </p>
                        <p>
                            For more information or to subscribe to error
                            reports, please{' '}
                            <a href="https://expedia.gp/connectivity-contact-us">
                                contact us
                            </a>{' '}
                            or your Expedia Group Account Manager directly.
                        </p>
                    </section>

                    <section
                        className="blog__entry"
                        id="guest-credit-card-authorization-in-eea-countries"
                    >
                        <TopicTitle className="blog__title">
                            Guest credit card authorization in EEA countries
                        </TopicTitle>
                        <br />
                        <span className="blog__date">4 November 2019</span>
                        <p>
                            Starting in September 2019, the revised{' '}
                            <strong>Payment Services Directive (PSD2)</strong>{' '}
                            went into effect across all European Economic Area
                            countries. This regulation is related to
                            authentication of consumer credit card transactions.
                            It is designed to make electronic payments more
                            secure and reduce credit card fraud for consumers.
                            This change will have the most impact when charging
                            guest cards without having the guest physically
                            present – for pre-stay deposits, or for cancellation
                            or no-show fees. It will now be necessary to use
                            <strong>
                                Strong Customer Authentication (SCA)
                            </strong>{' '}
                            to validate credit card transactions.
                        </p>
                        <p>
                            For more information, please visit Expedia Group{' '}
                            <a href="https://apps.expediapartnercentral.com/lodging/help/help-article/billing/hotel-collects/about-guest-credit-card-authorization-in-eea-countries">
                                Partner Central
                            </a>{' '}
                            or reach out to your account manager. If a property
                            contacts you about opting in, please ask them to
                            reach out to their Expedia market manager.
                        </p>
                        <p>
                            The information presented here is for informational
                            purposes only. You should talk with your legal team
                            and banking partners for further information about
                            the requirements of PSD2 and SCA and its
                            implications for your business. Expedia and its
                            affiliated companies disclaim any liability or
                            responsibility for declined or unsuccessful
                            transactions, and any associated refunds, reversals,
                            chargebacks, costs or expenses.
                        </p>
                    </section>

                    <section
                        className="blog__entry"
                        id="unicode-support-launched"
                    >
                        <TopicTitle className="blog__title">
                            Unicode support launched
                        </TopicTitle>
                        <br />
                        <span className="blog__date">30 July 2019</span>
                        <p>
                            Beginning in August 2019, guests will be able to
                            enter their <strong>name</strong> and{' '}
                            <strong>special requests</strong> in local language
                            at the time of booking. This update will give guests
                            an easier, friendlier booking experience. In turn,
                            we project increased conversion potential for
                            properties; which could lead to higher production.
                        </p>
                        <p>
                            This will apply to all{' '}
                            <strong>domestic bookings</strong> made on{' '}
                            <strong>Expedia.com</strong> and
                            <strong>Hotels.com</strong> in{' '}
                            <strong>China</strong>, <strong>Hong Kong</strong>,{' '}
                            <strong>Japan</strong>,<strong>Korea</strong>, and{' '}
                            <strong>Taiwan</strong>.
                        </p>
                        <p>
                            For properties to benefit from this update, both the
                            properties’ and your systems will need to support
                            <strong>UTF-8 encoding</strong>, which is an
                            international encoding standard for different
                            languages. If they don’t, don’t worry; you can
                            opt-out of this feature by contacting your Expedia
                            Group account manager or you can consider updating
                            your integration to become UTF-8 compliant. Get more
                            details and see sample messages in the online
                            specifications for the{' '}
                            <a href="https://developers.expediagroup.com/supply/lodging/docs/booking_apis/booking_notification/getting_started/introduction/">
                                Booking Notification API
                            </a>{' '}
                            and{' '}
                            <a href="https://developers.expediagroup.com/supply/lodging/docs/booking_apis/booking_retrieval/getting_started/introduction/">
                                Expedia QuickConnect Booking Retrieval &amp;
                                Confirmation API
                            </a>
                            .
                        </p>
                        <p>
                            If a property contacts you to opt-out of this
                            feature, please ask them to speak to their Expedia
                            Group market manager.
                        </p>
                    </section>
                </div>
            }
        ></Layout>
    );
};

Blog.propTypes = {
    translations: PropTypes.object.isRequired,
};

export default Blog;
