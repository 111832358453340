module.exports = `<svg viewBox="0 0 193 28" fill="#000099" xmlns="http://www.w3.org/2000/svg">
<path d="M12.978 13.2014C12.978 11.6836 11.5982 9.50696 8.90407 9.50696C6.48937 9.50696 4.83013 11.5767 4.83013 13.7396C4.83013 16.2957 6.59976 18.1274 9.63192 18.1274H15.3789C14.6849 19.2028 13.7302 20.0852 12.6035 20.6926C11.4769 21.2999 10.215 21.6125 8.93511 21.6011C7.39184 21.6011 5.88323 21.1435 4.60005 20.2861C3.31687 19.4287 2.31675 18.2101 1.72617 16.7843C1.13558 15.3585 0.981059 13.7896 1.28214 12.2759C1.58321 10.7623 2.32637 9.37198 3.41762 8.28072C4.50888 7.18947 5.89922 6.44631 7.41284 6.14524C8.92646 5.84416 10.4954 5.99868 11.9212 6.58926C13.347 7.17985 14.5656 8.17997 15.423 9.46315C16.2804 10.7463 16.738 12.2549 16.738 13.7982C16.7271 14.6227 16.5993 15.4415 16.3586 16.2301H7.67257V15.3781L10.7358 15.2815C12.2846 15.2194 12.978 14.4295 12.978 13.2118V13.2014Z" fill="#000099"/>
<path d="M23.2092 10.8316L26.1793 6.47479H30.2843L25.2307 13.5188L30.6948 21.1285H26.338L23.085 16.3923L19.8011 21.1285H15.6926L21.0636 13.7051L15.8513 6.47479H20.2081L23.2092 10.8316Z" fill="#000099"/>
<path d="M35.2104 9.34832C36.2832 7.07506 38.2115 6.06433 40.1709 6.06433C43.7067 6.06433 46.9286 9.06546 46.9286 13.8017C46.9286 18.5379 43.7067 21.5391 40.1709 21.5391C38.2115 21.5391 36.2832 20.5283 35.2104 18.2551C35.1483 18.1275 35.0517 18.1585 35.0517 18.2861V27.1618H31.2572V6.46448H35.0517V9.31382C35.0517 9.43801 35.1483 9.4725 35.2104 9.34487V9.34832ZM39.1119 18.4138C41.323 18.4138 43.2513 16.4855 43.2513 13.8017C43.2513 11.1179 41.323 9.18964 39.1119 9.18964C36.9007 9.18964 34.9724 11.1179 34.9724 13.8017C34.9724 16.4855 36.9317 18.4138 39.1119 18.4138Z" fill="#000099"/>
<path d="M59.9712 13.2015C59.9712 11.6837 58.5914 9.50702 55.8973 9.50702C53.4826 9.50702 51.8233 11.5767 51.8233 13.7396C51.8233 16.2957 53.5895 18.1275 56.6217 18.1275H62.3721C61.6781 19.2029 60.7234 20.0853 59.5967 20.6926C58.4701 21.3 57.2082 21.6125 55.9283 21.6012C54.3849 21.6019 52.8759 21.1448 51.5923 20.2878C50.3087 19.4309 49.308 18.2124 48.7169 16.7867C48.1258 15.361 47.9708 13.792 48.2715 12.2781C48.5721 10.7643 49.315 9.37361 50.4062 8.28201C51.4973 7.1904 52.8876 6.4469 54.4013 6.14555C55.915 5.84419 57.4841 5.99851 58.9101 6.589C60.3361 7.17948 61.555 8.1796 62.4125 9.46286C63.27 10.7461 63.7278 12.2549 63.7278 13.7983C63.7168 14.6228 63.589 15.4416 63.3483 16.2302H54.6658V15.3782L57.729 15.2816C59.2744 15.2195 59.9712 14.4295 59.9712 13.2118V13.2015Z" fill="#000099"/>
<path d="M76.6776 18.2551C75.6014 20.5283 73.6765 21.5391 71.7172 21.5391C68.1814 21.5391 64.9595 18.5379 64.9595 13.8017C64.9595 9.06544 68.1814 6.06432 71.7172 6.06432C73.6765 6.06432 75.6014 7.07504 76.6776 9.3483C76.7397 9.47594 76.8329 9.44144 76.8329 9.31726V0.127625H80.6274V21.1286H76.8329V18.2827C76.8329 18.155 76.7397 18.124 76.6776 18.2516V18.2551ZM72.7589 9.18963C70.5512 9.18963 68.6195 11.1179 68.6195 13.8017C68.6195 16.4854 70.5478 18.4137 72.7589 18.4137C74.9701 18.4137 76.8984 16.4854 76.8984 13.8017C76.8984 11.1179 74.9391 9.18963 72.7589 9.18963Z" fill="#000099"/>
<path d="M83.0247 2.21117C83.0247 1.77384 83.1543 1.34633 83.3973 0.982705C83.6403 0.61908 83.9856 0.335677 84.3897 0.168319C84.7937 0.000960815 85.2383 -0.0428314 85.6672 0.042487C86.0961 0.127805 86.4901 0.338397 86.7994 0.647634C87.1086 0.956871 87.3192 1.35087 87.4045 1.77979C87.4898 2.20872 87.4461 2.65331 87.2787 3.05735C87.1113 3.46139 86.8279 3.80672 86.4643 4.04969C86.1007 4.29265 85.6732 4.42234 85.2358 4.42234C84.6516 4.41518 84.0933 4.17992 83.6802 3.76679C83.2671 3.35365 83.0318 2.79538 83.0247 2.21117ZM87.1297 6.47482V21.1286H83.3351V6.47482H87.1297Z" fill="#000099"/>
<path d="M100.742 18.2551C99.6655 20.5283 97.7406 21.5391 95.7812 21.5391C92.2454 21.5391 89.0236 18.5379 89.0236 13.8017C89.0236 9.06546 92.2454 6.06433 95.7812 6.06433C97.7406 6.06433 99.6655 7.07506 100.742 9.34832C100.804 9.47595 100.897 9.44146 100.897 9.31727V6.47483H104.691V21.1286H100.897V18.2827C100.897 18.155 100.804 18.124 100.742 18.2516V18.2551ZM96.823 9.18964C94.6118 9.18964 92.6835 11.1179 92.6835 13.8017C92.6835 16.4855 94.6084 18.4138 96.823 18.4138C99.0376 18.4138 100.962 16.4855 100.962 13.8017C100.962 11.1179 99.0031 9.18964 96.823 9.18964Z" fill="#000099"/>
<path d="M123.543 18.1585C123.543 18.0343 123.447 18.0033 123.385 18.1274C122.308 20.4007 120.415 21.4114 118.49 21.4114C114.982 21.4114 111.794 18.4448 111.794 13.7396C111.794 9.0344 114.982 6.06433 118.49 6.06433C120.415 6.06433 122.308 7.07506 123.385 9.34832C123.447 9.47595 123.543 9.44146 123.543 9.31727V6.47483H127.338V20.2731C127.338 24.347 124.527 27.5171 119.663 27.5171C117.147 27.5647 114.708 26.6505 112.843 24.961L115.116 22.498C116.63 23.6985 117.831 24.3298 119.601 24.3298C122.381 24.3298 123.55 22.3704 123.55 19.9385V18.1688L123.543 18.1585ZM119.532 9.18964C117.351 9.18964 115.458 11.0869 115.458 13.7396C115.458 16.3923 117.351 18.2827 119.532 18.2827C121.712 18.2827 123.605 16.3889 123.605 13.7362C123.605 11.0834 121.677 9.18619 119.532 9.18619V9.18964Z" fill="#000099"/>
<path d="M133.837 6.47477V9.65872C133.837 9.72081 133.899 9.75185 133.933 9.65872C134.437 7.03705 135.575 6.05737 137.531 6.05737H137.876V10.0347H136.169C134.623 10.0347 133.833 11.2352 133.833 12.9427V21.1216H130.039V6.47477H133.837Z" fill="#000099"/>
<path d="M145.931 5.99877C147.474 6.00149 148.981 6.46153 150.263 7.32073C151.544 8.17994 152.542 9.39974 153.13 10.826C153.719 12.2522 153.871 13.8208 153.568 15.3336C153.265 16.8464 152.521 18.2355 151.429 19.3252C150.336 20.415 148.946 21.1565 147.432 21.4561C145.919 21.7556 144.35 21.5998 142.926 21.0083C141.501 20.4168 140.283 19.4161 139.427 18.1328C138.57 16.8494 138.114 15.341 138.114 13.7982C138.116 12.7717 138.32 11.7556 138.715 10.8079C139.109 9.86026 139.686 8.99958 140.413 8.27501C141.141 7.55045 142.003 6.97619 142.952 6.58504C143.901 6.19389 144.918 5.9935 145.945 5.99531L145.931 5.99877ZM145.9 9.44833C143.627 9.44833 141.76 11.3456 141.76 13.8086C141.76 16.2716 143.623 18.1654 145.9 18.1654C148.177 18.1654 150.039 16.2716 150.039 13.8086C150.039 11.3456 148.177 9.44833 145.9 9.44833Z" fill="#000099"/>
<path d="M161.571 21.6011C157.877 21.6011 155.507 19.3589 155.507 16.0818V6.47479H159.301V15.7886C159.301 17.2409 160.25 18.2033 161.671 18.2033C161.979 18.207 162.284 18.1476 162.568 18.0285C162.852 17.9095 163.109 17.7335 163.322 17.5113C163.535 17.2891 163.7 17.0256 163.807 16.737C163.914 16.4484 163.961 16.1409 163.945 15.8335V6.48514H167.739V16.0749C167.739 19.3589 165.18 21.5942 161.578 21.5942L161.571 21.6011Z" fill="#000099"/>
<path d="M174.293 9.34832C175.366 7.07506 177.291 6.06433 179.25 6.06433C182.786 6.06433 186.008 9.06546 186.008 13.8017C186.008 18.5379 182.786 21.5391 179.25 21.5391C177.291 21.5391 175.366 20.5283 174.293 18.2551C174.228 18.1275 174.135 18.1585 174.135 18.2861V27.1618H170.34V6.46448H174.135V9.31382C174.135 9.43801 174.228 9.4725 174.293 9.34487V9.34832ZM178.209 18.4138C180.42 18.4138 182.348 16.4855 182.348 13.8017C182.348 11.1179 180.423 9.18964 178.209 9.18964C175.994 9.18964 174.069 11.1179 174.069 13.8017C174.069 16.4855 176.025 18.4138 178.209 18.4138Z" fill="#000099"/>
<path d="M189.286 8.49591V8.75079H188.855V9.93536H188.553V8.75079H188.12V8.49591H189.286ZM190.417 8.49591H190.85V9.93536H190.569V8.96173C190.569 8.93374 190.57 8.89467 190.57 8.84454C190.571 8.79376 190.571 8.7547 190.571 8.72736L190.299 9.93536H190.007L189.736 8.72736C189.736 8.7547 189.737 8.79376 189.737 8.84454C189.738 8.89467 189.738 8.93374 189.738 8.96173V9.93536H189.458V8.49591H189.896L190.157 9.62775L190.417 8.49591Z" fill="#000099"/>
</svg>`;
