import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import Layout from '../PageLayout';
import {triggerEdapPageView, eventPageName} from '../util/edap';
import toolsLgConnectivityIllustrationSvg from '../assets/tools-lg-connectivity-illustration.svg';
import Border from '../util/Border/Border.js';
import BenefitsBanner from './CppContent/BenefitsBanner';
import QualificationSection from './CppContent/QualificationSection';
import Footnote from './CppContent/Footnote';
import EligibilityCheck from './CppContent/EligibilityCheck';
import './stylesheets/styles.less';

const CppOverview = ({translations}) => {
    useEffect(() => {
        triggerEdapPageView(eventPageName.CPP_OVERVIEW_PAGE);
    }, []);

    const header = (
        <div className="cpp-page__header-content__wrapper">
            <div className="cpp-page__header-content">
                <div className="cpp-page__header-content__left">
                    <div className="heading">
                        Expedia Group’s Connectivity Partner Program
                    </div>
                    <div className="sub-heading">
                        Expedia Group’s Connectivity Partner Program is designed
                        to recognize our top connectivity partners and reward
                        them with exclusive benefits. The program offers
                        benefits for connectivity partners in two qualifying
                        tiers, Elite and Preferred, which may include business
                        advantages, special product access and collaboration,
                        and premium promotion. Connectivity partners can qualify
                        based on their standing across various market
                        enhancements, engagement quality, and technical criteria
                        metrics.
                    </div>
                </div>
                <div className="cpp-page__header-content__right">
                    <div className="image">
                        <img
                            src={toolsLgConnectivityIllustrationSvg}
                            alt="connectivity-illustration"
                        />
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <Layout
            translations={translations}
            header={header}
            content={[
                <Border key="border-1" inverse />,
                <BenefitsBanner key="benefits-banner" />,
                <Border key="border-2" />,
                <QualificationSection key="qualification-section" />,
                <Border key="border-3" inverse />,
                <EligibilityCheck
                    translations={translations}
                    key="eligibility-check"
                />,
                <Border key="border-4" />,
                <Footnote key="footnote-section" />,
            ]}
        />
    );
};

CppOverview.propTypes = {
    translations: PropTypes.object.isRequired,
};

export default CppOverview;
