import React, {useEffect} from 'react';
import {ButtonPrimary} from '@homeaway/react-buttons';
import {
    triggerEdapPageView,
    triggerEdapEvent,
    eventCategories,
} from '../../util/edap';
import allUserTypes from '../../../../res/userTypes';
import {extPrefix} from '../../../../util/prefix';
import PropTypes from 'prop-types';
import {userTypePropType} from '../../util/propTypes';
import formatContent from '../../util/formatContent';
import './styles.less';

const Complete = ({userType, translations}) => {
    useEffect(() => {
        triggerEdapPageView('LeadsForm-FinalPage');
    }, []);

    const handleButtonClick = (value) => () => {
        triggerEdapEvent('generic.event', {
            eventcategory: eventCategories.LEADS_FORM,
            eventaction: `${value} button clicked`,
        });
    };

    const getNextSteps = () => {
        const pmNextSteps = [];
        switch (userType) {
            case allUserTypes.B2B:
                return formatContent(translations['leadsForm.complete.b2b']);
            case allUserTypes.ACTIVITIES:
                return formatContent(
                    translations['leadsForm.complete.activities']
                );
            case allUserTypes.CPSP:
                return (
                    <>
                        <p>{translations['leadsForm.complete.cpsp1']}</p>
                        <p>{translations['leadsForm.complete.cpsp.active']}</p>
                    </>
                );
            case allUserTypes.PM:
                for (let i = 1; i <= 3; i++) {
                    pmNextSteps.push(
                        <div key={`pm-step-${i}`}>
                            <div className="next-steps-heading">
                                {
                                    translations[
                                        `leadsForm.complete.pm.heading${i}`
                                    ]
                                }
                            </div>
                            <ul>
                                <li>
                                    {formatContent(
                                        translations[
                                            `leadsForm.complete.pm.content${i}`
                                        ]
                                    )}
                                </li>
                            </ul>
                        </div>
                    );
                }
                return <div className="next-steps-pm">{pmNextSteps}</div>;
        }
    };
    return (
        <div className="complete-page-wrapper">
            <div className="next-steps-wrapper">
                <ul className="next-steps">{getNextSteps()}</ul>
                <div className="next-steps-footer">
                    <ButtonPrimary
                        className="action-button"
                        label={
                            translations['leadsForm.complete.backButton.label']
                        }
                        href={`/${extPrefix}/contact`}
                        onClick={handleButtonClick('Back')}
                    />
                    <ButtonPrimary
                        className="action-button"
                        label={
                            translations['leadsForm.complete.homeButton.label']
                        }
                        href={`/${extPrefix}`}
                        onClick={handleButtonClick('Home')}
                    />
                </div>
            </div>
        </div>
    );
};

Complete.propTypes = {
    translations: PropTypes.object,
    userType: userTypePropType,
};

export default Complete;
