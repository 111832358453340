import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from '@homeaway/react-tooltip';
import {SVGIcon} from '@homeaway/react-svg';
import {TOOLTIP_INFO__16} from '@homeaway/svg-defs';
import './styles.less';

export default class Tooltip extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let {content} = this.props;
        return (
            <div className="leads-form-tooltip-wrapper text-brand">
                <ReactTooltip content={content}>
                    <SVGIcon markup={TOOLTIP_INFO__16} />
                </ReactTooltip>
            </div>
        );
    }
}

Tooltip.propTypes = {
    content: PropTypes.string,
};
