import React from 'react';
import {Route, Switch} from 'react-router-dom';
import PropTypes from 'prop-types';
import LandingPage from '../LandingPage';
import {extPrefix, prefix} from '../../../util/prefix';
import ErrorBoundary from '../LoggingErrorBoundary';
import PageFallback from '../PageFallback';
import Blog from '../Blog';
import CppOverview from '../CppOverview';

import LicenseAgreement from '../LicenseAgreement';
import CODES from '../../../universal/client-error-logging/client/codes';
import LeadsFormModal from '../LeadsForm/LeadsFormModal';
import NotFound from '../NotFound';
import links from '../../../util/navLinks';

/* eslint-disable react/jsx-no-bind */
function App(props) {
    return (
        <Switch>
            <Route
                path={`/${prefix}/${links.NOT_FOUND}`}
                render={() => <NotFound translations={props.translations} />}
            />
            <Route
                path={`/${extPrefix}/${links.NOT_FOUND}`}
                render={() => <NotFound translations={props.translations} />}
            />
            <Route
                path={`/${prefix}/${links.BLOG}`}
                render={() => <Blog translations={props.translations} />}
            />
            <Route
                path={`/${extPrefix}/${links.BLOG}`}
                render={() => <Blog translations={props.translations} />}
            />
            <Route
                path={`/${prefix}/cpp/${links.CPP_OVERVIEW}`}
                render={() => <CppOverview translations={props.translations} />}
            />
            <Route
                path={`/${extPrefix}/cpp/${links.CPP_OVERVIEW}`}
                render={() => <CppOverview translations={props.translations} />}
            />
            <Route
                path={`/${prefix}/${links.LICENSE_AGREEMENT}`}
                render={() => (
                    <LicenseAgreement translations={props.translations} />
                )}
            />
            <Route
                path={`/${extPrefix}/${links.LICENSE_AGREEMENT}`}
                render={() => (
                    <LicenseAgreement translations={props.translations} />
                )}
            />
            <Route
                path={`/${prefix}/${links.CONTACT}`}
                render={({history}) => (
                    <ErrorBoundary
                        code={CODES.ERROR_CODE_1000}
                        fallbackView={
                            <PageFallback
                                title={
                                    props.translations['fallback.message.title']
                                }
                                message={
                                    props.translations[
                                        'fallback.message.content'
                                    ]
                                }
                            />
                        }
                    >
                        <LeadsFormModal
                            history={history}
                            translations={props.translations}
                            isOpen={true}
                        />
                    </ErrorBoundary>
                )}
            />
            <Route
                path={`/${extPrefix}/${links.CONTACT}`}
                render={({history}) => (
                    <ErrorBoundary
                        code={CODES.ERROR_CODE_1000}
                        fallbackView={
                            <PageFallback
                                title={
                                    props.translations['fallback.message.title']
                                }
                                message={
                                    props.translations[
                                        'fallback.message.content'
                                    ]
                                }
                            />
                        }
                    >
                        <LeadsFormModal
                            history={history}
                            translations={props.translations}
                            isOpen={true}
                        />
                    </ErrorBoundary>
                )}
            />
            <Route
                path={`/${prefix}`}
                render={() => (
                    <ErrorBoundary
                        code={CODES.ERROR_CODE_1000}
                        fallbackView={
                            <PageFallback
                                title={
                                    props.translations['fallback.message.title']
                                }
                                message={
                                    props.translations[
                                        'fallback.message.content'
                                    ]
                                }
                            />
                        }
                    >
                        <LandingPage
                            translations={props.translations}
                            prodTestConfig={props.prodTestConfig}
                        />
                    </ErrorBoundary>
                )}
            />
            <Route
                path={`/${extPrefix}`}
                render={() => (
                    <ErrorBoundary
                        code={CODES.ERROR_CODE_1000}
                        fallbackView={
                            <PageFallback
                                title={
                                    props.translations['fallback.message.title']
                                }
                                message={
                                    props.translations[
                                        'fallback.message.content'
                                    ]
                                }
                            />
                        }
                    >
                        <LandingPage
                            translations={props.translations}
                            prodTestConfig={props.prodTestConfig}
                        />
                    </ErrorBoundary>
                )}
            />
        </Switch>
    );
}

App.propTypes = {
    translations: PropTypes.object.isRequired,
    prodTestConfig: PropTypes.object,
};

export default App;
