import React from 'react';
import Layout from '../PageLayout';
import PropTypes from 'prop-types';

import './styles.less';

const LicenseAgreement = ({translations}) => {
    return (
        <Layout
            translations={translations}
            header={null}
            content={
                <div className="license-agreement">
                    <h1>Connectivity API License Agreement</h1>
                    <hr />
                    <p>
                        <em>Updated March 28, 2024</em>
                    </p>
                    <p>
                        This Connectivity API License Agreement (the “
                        <strong>License Agreement</strong>”) together with any
                        additional terms referenced in or attached to this
                        License Agreement (the “
                        <strong>Additional Terms</strong>”) (collectively the “
                        <strong>Agreement</strong>”) set out the terms on which
                        Expedia Lodging Partner Services Sàrl (“
                        <strong>Expedia</strong>”, “<strong>we</strong>”, “
                        <strong>us</strong>”) agrees to grant you a license for
                        and access to the application programming interfaces and
                        documentation (“<strong>APIs</strong>”) made available
                        by Expedia to you under this Agreement. The terms “you”
                        and “your” refers to the user of the APIs and any person
                        listed in any Additional Terms. Please read this
                        Agreement carefully, if you do not accept this
                        Agreement, you may not access or use the APIs.
                    </p>
                    <p>
                        1. <u>License Grant</u>. Subject to your compliance with
                        this Agreement, we shall grant you a limited, revocable,
                        non-exclusive, non-transferable, non-sublicensable
                        license during the Term (as defined below) to use the
                        APIs solely for your internal business purposes to
                        develop, test, maintain and operate an electronic
                        interface (your “<strong>Connection</strong>”) to
                        connect with our system and facilitate the exchange of
                        rates, availability, and other property-related
                        information between us and suppliers that we have
                        approved in writing (“
                        <strong>Properties</strong>”). You: (a) acknowledge that
                        this Agreement does not contain a license or right to
                        access or use the Content (as defined below) made
                        available through the APIs unless otherwise explicitly
                        granted in the Additional Terms, (b) acknowledge that we
                        have no obligation to display the Properties or any
                        Content, (c) represent and warrant that you are
                        accessing and using all such data and Content pursuant
                        to, and in compliance with, the terms of your agreement
                        with the applicable Property, and (d) acknowledge that
                        we reserve all rights that are not herein expressly
                        granted.
                    </p>
                    <p>
                        2. <u>Use Restrictions</u>. Except as expressly
                        authorized under this Agreement, you may not: (a)
                        without our written consent (i) copy, modify, or create
                        derivative works of the APIs, in whole or in part, or
                        (ii) rent, lease, lend, sell, license, sublicense,
                        assign, distribute, publish, transfer, or otherwise make
                        available the APIs; (b) reverse engineer, disassemble,
                        decompile, decode, adapt, or otherwise attempt to derive
                        or gain access to any software component of the APIs, in
                        whole or in part; (c) use the APIs in any manner or for
                        any purpose that infringes, misappropriates, or
                        otherwise violates any intellectual property right or
                        other right of any person, or that violates any
                        applicable law; or (d) combine or integrate the APIs
                        with any software, technology, services, or materials
                        not authorized by us. In addition, you will not use the
                        APIs in connection with any unlawful or improper
                        activities.
                    </p>
                    <p>
                        3. <u>Your Connection</u>.
                        <ul>
                            <li>
                                (a) <u>Connection Requirements</u>. Prior to
                                your Connection going live, and throughout the
                                Term, you must comply with the API connection
                                requirements and specifications provided to you
                                by us. If we provide you with user credentials,
                                a security key, or other information for you to
                                access the APIs (an “<strong>API Key</strong>”),
                                you may not share your API Key with any third
                                party and must (i) keep your API Key and all
                                log-in information secure and (ii) use the API
                                Key as your sole means of accessing the APIs.
                                You are solely responsible for any losses
                                associated with the loss or misuse of your API
                                Key. We may revoke your API Key at any time
                                during the term to maintain the security and
                                integrity of the API and/ or if you are in
                                material breach of these terms.
                            </li>
                            <li>
                                (b) <u>Provision of your Connection</u>. You are
                                solely responsible, at your cost and expense,
                                for the development, operation, and maintenance
                                of your Connection. You and your Connection will
                                comply with all terms and conditions of this
                                Agreement, all applicable laws, and all policies
                                and requirements that we make available to you
                                from time to time. We will comply with all
                                applicable laws in our performance under this
                                Agreement. You agree to monitor the use of your
                                Connection for any fraudulent or potentially
                                harmful activity and any activity that violates
                                applicable laws, or any terms and conditions of
                                this Agreement.
                            </li>
                            <li>
                                (c) <u>Customer Support</u>. You are solely
                                responsible for providing customer and technical
                                support to your end users.
                            </li>
                            <li>
                                (d) <u>Integrated Reviews</u>. If you make
                                available any reviews for a Property through
                                your Connection to us, you agree that you will
                                distribute to us all reviews that you have for
                                such Property.
                            </li>
                            <li>
                                (e) <u>Your Software</u>. You grant us, during
                                the Term, a non-exclusive, royalty-free, limited
                                license to access and use your Connection and
                                any other application programming interfaces or
                                software solutions that you use to exchange
                                Content or access the APIs (the “
                                <strong>Software</strong>”) to access and
                                exchange the Content, improve our APIs and other
                                products and services, and as necessary to
                                perform under this Agreement.
                            </li>
                        </ul>
                    </p>
                    <p>
                        4. <u>API Support; Updates</u>. This Agreement does not
                        entitle you to any support for the APIs, however, you
                        can find support information on our developer hub and
                        may contact your connectivity account manager. You
                        acknowledge that we may update or modify the APIs (or
                        add APIs) from time to time and at our sole discretion
                        (in each instance, an “<strong>Update</strong>”), and
                        may require you to obtain and use the most recent
                        version(s) within the reasonable time period set by us.
                        Updates may adversely affect how your Connection
                        communicates with the APIs and relevant details will be
                        posted on our developer hub. You will make any changes
                        to your Connection that are required for integration as
                        a result of such Update at your sole cost and expense.
                    </p>
                    <p>
                        5. <u>No Fees</u>. Unless otherwise specified under the
                        Additional Terms (if any), no fees or other payments
                        will be due under this Agreement.
                    </p>
                    <p>
                        6. <u>Content</u>. Except as authorized in writing by
                        us, you will not edit, modify, or copy, any Content that
                        you access via the APIs. At all times during the Term,
                        you will ensure that the accuracy and completeness of
                        any and all Content provided to you by a Property or
                        that you receive from us retains the level of accuracy
                        and completeness that such Content had when you received
                        it. If any Content as presented through your Connection
                        is inaccurate or incomplete, then you will correct (or
                        direct the Property to correct) the inaccuracy or
                        incompleteness as soon as reasonably practicable. “
                        <strong>Content</strong>” means any and all content,
                        data and information that you provide to or receive from
                        us under this Agreement, including without limitation,
                        rates, availability, lodging descriptions, photographs,
                        and customer Personal Data and Payment Information. You
                        will not use Content you access via the APIs for any
                        purpose other than to perform your obligations under
                        this Agreement.
                    </p>
                    <p>
                        7. <u>Taxes</u>. You shall be solely responsible for any
                        and all taxes and/or other obligations associated with
                        any amounts paid in connection with this Agreement. Any
                        payments made under this Agreement are inclusive of
                        value added (VAT), goods and services, sales, use,
                        excise, gross receipts, consumption, services and any
                        other similar tax, duty, imposition, fee or charge,
                        however designated, that is not imposed on, measured by,
                        or based on gross or net income (collectively “
                        <strong>Transaction Taxes</strong>”). If applicable, you
                        will deliver to us a completed IRS Form W-9, IRS Form
                        W-8BEN-E or IRS Form W-8ECI (“
                        <strong>Tax Documents</strong>”) to establish that you
                        are not subject to withholding or that you are entitled
                        to an exemption from, or reduction of, withholding tax,
                        as applicable. You shall promptly notify us of any
                        change in circumstance that impacts the validity of the
                        Tax Documents and update them accordingly. You shall
                        provide us with an updated version of the Tax Documents
                        promptly upon request. If you fail to provide Tax
                        Documents as required by this section, we will be
                        entitled to deduct and withhold from payments due to you
                        under this Agreement such amounts as we are required to
                        deduct and withhold under applicable law, and all such
                        amounts withheld by us shall be treated as though paid
                        to you.
                    </p>
                    <p>
                        8. <u>Your Privacy and Data Security Obligations</u>.
                        You are solely responsible for posting any privacy
                        notices and obtaining any consents from your end users
                        required under applicable laws, rules, and regulations
                        for their use of your Connection.
                        <ul>
                            <li>
                                (a) <u>Definitions</u>. The following terms are
                                used in this Section: “
                                <strong>Data Security Breach</strong>” means:
                                (i) the loss or misuse (by any means) of
                                Personal Data, including, without limitation any
                                unauthorized access or disclosure to
                                unauthorized individuals; (ii) the inadvertent,
                                unauthorized and/or unlawful processing,
                                corruption, modification, transfer, sale or
                                rental of Personal Data; or (iii) any other act
                                or omission that compromises the security,
                                confidentiality, or integrity of Personal Data.
                                Data Security Breach includes, without
                                limitation, a breach resulting from or arising
                                out of your internal use, processing or other
                                transmission of Personal Data, whether between
                                or among your subsidiaries and affiliates or any
                                other person or entity acting on your behalf. “
                                <strong>Personal Data</strong>” means any
                                information that relates to an individual,
                                including an employee, customer, end-user or any
                                other individual, including, without limitation:
                                (i) first and last name; (ii) home or other
                                physical address; (iii) telephone number; (iv)
                                email address; (v) identification number,
                                location data, or online identifier associated
                                with an individual; (vi) Payment Information (as
                                defined below); or (vii) any other information
                                relating to an individual, including cookie
                                information and usage and traffic data or
                                profiles, that is combined with any of the
                                foregoing.
                            </li>
                            <li>
                                (b) <u>Roles and responsibilities</u>. The
                                parties acknowledge that you operate solely as
                                service provider of the Property and not for us.
                                As between us and you, you are responsible for
                                complying with all applicable data protection
                                laws and obligations as they relate to your
                                storage, use and safeguarding of all Personal
                                Data contemplated by this Agreement. For the
                                purpose of data protection obligations, you
                                recognize and acknowledge that to the extent you
                                process Personal Data relating to this
                                Agreement, you do so at the direction of the
                                Property and not at the direction of us.
                            </li>
                            <li>
                                (c) <u>Use of Personal Data</u>. Unless you
                                receive consent directly from the customer, you
                                will not engage and you will not cause any of
                                your affiliates to, directly or indirectly,
                                engage in any solicited or unsolicited
                                marketing, promotional, or similar
                                communications, with any customer that has
                                booked a room through our platform.
                            </li>
                            <li>
                                (d) <u>PCI DSS</u>. If you process, store,
                                transmit or otherwise have access to any payment
                                information (including, without limitation,
                                credit and debit card numbers and other
                                financial information) (“
                                <strong>Payment Information</strong>”) you
                                represent and warrant that you are presently in
                                compliance and will remain in compliance with
                                the current Payment Card Information Data
                                Security Standard (“<strong>PCI DSS</strong>”).
                                You will provide us with a copy of your PCI DSS
                                Attestation of Compliance annually at the time
                                of filing or upon request.
                            </li>
                            <li>
                                (e) <u>Data Security</u>. You will establish and
                                maintain appropriate administrative, technical,
                                and physical safeguards to protect the security,
                                confidentiality and integrity of Confidential
                                Information (as defined below) and Personal Data
                                in your possession. Without limiting the
                                foregoing, you will ensure that all Personal
                                Data is encrypted in transit and storage using
                                industry standard encryption. Each party shall
                                have in place and will maintain, or will
                                establish and maintain, adequate security
                                procedures and controls to prevent the
                                unintended disclosure of, and the unauthorized
                                access to or misappropriation of, any Personal
                                Data.
                            </li>
                            <li>
                                (f) <u>Data Security Breaches</u>. In case of
                                any kind of data security incident, data breach,
                                or violation of data protection laws,
                                regulations or other requirements regarding
                                data, you shall, in your role as service
                                provider to the Property, promptly notify such
                                Property and work expeditiously with the
                                Property to address all obligations, legal and
                                otherwise, related to such incident, breach or
                                violation.
                            </li>
                        </ul>
                    </p>
                    <p>
                        9. <u>Confidentiality</u>.
                        <ul>
                            <li>
                                (a) <u>Confidential Information</u>. Each party
                                may gain access to Confidential Information in
                                connection with this Agreement. “
                                <strong>Confidential Information</strong>” means
                                any non-public, confidential, proprietary or
                                trade secret information in any form that is
                                designated as “confidential” or that a
                                reasonable person knows or reasonably should
                                understand to be confidential. Our Confidential
                                Information includes without limitation the
                                Content. Confidential Information does not
                                include information of a disclosing party that
                                (i) becomes publicly available without the
                                receiving party’s breach of any obligation owed
                                to the disclosing party, (ii) became known to
                                receiving party prior to disclosing party’s
                                disclosure of such information, (iii) became
                                known to receiving party from a source other
                                than disclosing party where such source did not
                                breach an obligation of confidentiality owed to
                                disclosing party, or (iv) is independently
                                developed by the receiving party.
                            </li>
                            <li>
                                (b){' '}
                                <u>
                                    Use and Disclosure of Confidential
                                    Information
                                </u>
                                . Neither party may use Confidential Information
                                for any purpose other than to perform its
                                obligations under this Agreement. Except as
                                provided in this Agreement, neither party will
                                disclose Confidential Information to anyone
                                without the other party’s prior written consent.
                                Each party will restrict the possession,
                                knowledge, and use of Confidential Information
                                to each of its employees, representatives, and
                                subcontractors who (a) has a need to know the
                                Confidential Information and (b) is legally
                                obligated to protect the Confidential
                                Information to the same or greater degree as
                                required under this Agreement. Each party may
                                disclose Confidential Information as required to
                                comply with orders of governmental entities with
                                jurisdiction over it, if such party: (a) gives
                                the other party prior written notice sufficient
                                to allow that party to seek a protective order
                                or other remedy; (b) discloses only such
                                information as is required by the governmental
                                entity; and (c) uses commercially reasonable
                                efforts to obtain confidential treatment for any
                                Confidential Information so disclosed.
                            </li>
                            <li>
                                (c) <u>Notice of Unauthorized Use</u>. Without
                                limiting your obligations under this Section,
                                you will notify us immediately upon discovery of
                                any unauthorized use or disclosure of
                                Confidential Information and will cooperate with
                                us in every reasonable way to help us prevent
                                further unauthorized use and disclosure.
                            </li>
                        </ul>
                    </p>
                    <p>
                        10. <u>Intellectual Property Ownership</u>. You
                        acknowledge that, as between you and us, (a) we own all
                        right, title, and interest, including all intellectual
                        property rights, in and to the APIs, the Expedia Marks,
                        and our Confidential Information, and (b) you own all
                        right, title, and interest, including all intellectual
                        property rights, in and to your Connection(s) and your
                        Confidential Information.
                    </p>
                    <p>
                        11. <u>Expedia Marks</u>. Subject to your compliance
                        with all terms and conditions set forth in this
                        Agreement, we grant you a limited, revocable,
                        non-exclusive, non-transferable, non-sublicensable
                        license during the Term to display Expedia Marks solely
                        to identify us as a channel to which you provide access
                        or as otherwise authorized in writing by us. Without
                        limiting the foregoing, your use of the Expedia Marks
                        must comply with our trademark guidelines as provided to
                        you from time to time. “<strong>Expedia Marks</strong>”
                        means our proprietary trademarks, trade names, branding,
                        or logos that we make available to you for use in
                        connection with the APIs pursuant to this Agreement. You
                        must correct or cease use of the Expedia Marks within 48
                        hours of notice from us. You will not register, adopt,
                        or use any name or other designation that includes all
                        or part of any Expedia Mark, or any term that is
                        confusingly similar to an Expedia Mark. You may use the
                        Expedia Marks only: (i) in the form provided by us; (ii)
                        for purposes as set forth above; and (iii) in accordance
                        with the terms of this Agreement. You may not use our
                        brand names or any of the Expedia Marks in connection
                        with the transmission or distribution of unsolicited
                        commercial email, in any manner that would violate local
                        law or custom or conflict with our policies, or in any
                        way that disparages or devalues Our reputation or
                        goodwill.
                    </p>
                    <p>
                        12. <u>Disclaimer of Warranties</u>. THE APIs AND
                        EXPEDIA MARKS ARE PROVIDED “AS IS” AND WE SPECIFICALLY
                        DISCLAIM ALL WARRANTIES, WHETHER EXPRESS, IMPLIED,
                        STATUTORY, OR OTHERWISE. WE SPECIFICALLY DISCLAIM ALL
                        IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                        PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, AND ALL
                        WARRANTIES ARISING FROM COURSE OF DEALING, USAGE, OR
                        TRADE PRACTICE. EXPEDIA MAKES NO WARRANTY OF ANY KIND
                        THAT THE APIs WILL OPERATE WITHOUT INTERRUPTION, OR BE
                        SECURE, ACCURATE, COMPLETE, FREE OF HARMFUL CODE, OR
                        ERROR-FREE, OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE
                        CORRECTED. NOTHING IN THIS AGREEMENT EXCLUDES, RESTRICTS
                        OR MODIFIES ANY RIGHT OR REMEDY, OR ANY CONDITION,
                        GUARANTEE, WARRANTY OR OTHER TERM OR CONDITION, IMPLIED
                        OR IMPOSED BY ANY LEGISLATION WHICH CANNOT BE LAWFULLY
                        EXCLUDED OR LIMITED.
                    </p>
                    <p>
                        13. <u>Indemnification</u>. You will indemnify, defend,
                        and hold us and our officers, directors, employees,
                        agents, affiliates, successors, and assigns, harmless
                        from and against any and all losses, damages,
                        liabilities, deficiencies, claims, actions, judgments,
                        settlements, interest, awards, penalties, fines, costs,
                        or expenses, arising from or relating to (a) your misuse
                        of the APIs or Expedia Marks; (b) your breach of
                        Sections 1 (License Grant), 2 (Use Restrictions),
                        3(a)(Connection Requirements), 6 (Content), 8 (Your
                        Privacy and Data Security Obligations), or 9
                        (Confidentiality) of this Agreement; and (c) your
                        Connection. However, you are not obliged to indemnify us
                        in respect of any claims, liabilities, damages, losses,
                        debts, obligations, and expenses, to the extent caused
                        by our default, negligence or wrongful act or omission.
                        If we seek indemnification or defense from you, we will
                        promptly notify you in writing of the claim(s) brought
                        against us for which we seek indemnification or defense.
                        We reserve the right, at our option and in our sole
                        discretion, to assume full control of the defense of
                        claims with legal counsel of our choice. You may not
                        enter into any third-party agreement that would,
                        constitute an admission of fault by us or bind us in any
                        manner, without our prior written consent. In the event
                        we assume control of the defense of such claim, we will
                        not settle any such claim requiring payment from you
                        without your prior written approval.
                    </p>
                    <p>
                        14. <u>Limitations of Liability</u>. TO THE FULLEST
                        EXTENT PERMITTED BY LAW, NEITHER PARTY SHALL BE LIABLE
                        FOR ANY LOST PROFITS, COST OF RE-PROCUREMENT, LOSS OF
                        EXPECTED SAVINGS, OR ANY CONSEQUENTIAL, INCIDENTAL,
                        SPECIAL, INDIRECT, ECONOMIC, EXEMPLARY, AGGRAVATED OR
                        PUNITIVE DAMAGES WHATSOEVER ARISING OUT OF OR IN ANY WAY
                        RELATED TO THIS AGREEMENT EVEN IF SUCH PARTY HAS BEEN
                        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR IF SUCH
                        DAMAGES WERE FORESEEABLE.
                    </p>
                    <p>
                        15. <u>Term and Termination</u>. This Agreement will
                        continue until terminated as permitted in this Agreement
                        (the “<strong>Term</strong>”). We may terminate or
                        suspend this Agreement, any rights granted herein,
                        and/or your licenses under this Agreement at any time by
                        providing thirty (30) days’ written notice to you,
                        citing the reason. You may terminate this Agreement at
                        any time by providing thirty (30) days’ written notice
                        to us. Upon termination of this Agreement for any reason
                        all licenses and rights granted to each party under this
                        Agreement will terminate and each party must delete all
                        Confidential Information of the other. Any terms that by
                        their nature are intended to continue beyond the
                        termination or expiration of this Agreement, will
                        survive termination.
                    </p>
                    <p>
                        16. <u>Modifications</u>. We reserve the right to modify
                        and impose new or additional terms to this Agreement at
                        any time. We will provide reasonable written notice of
                        any such changes. If you do not accept such
                        modifications or new or additional terms and conditions,
                        you may terminate this Agreement upon written notice to
                        Expedia. Your failure to exercise your right to
                        terminate this Agreement within 30 days after notice of
                        any change to this Agreement will constitute your
                        acceptance of such changes. You may not modify this
                        Agreement without Expedia’s prior written consent.
                    </p>
                    <p>
                        17. <u>Governing Law and Jurisdiction</u>. This
                        Agreement is governed by the laws of the State of
                        Washington without giving effect to any choice of
                        conflict of law provision or rule that would require or
                        permit the application of the laws of any jurisdiction
                        other than those of the State of Washington. Any legal
                        suit, action, or proceeding arising out of or related to
                        this Agreement or the licenses granted hereunder will be
                        instituted exclusively in the federal courts of the
                        United States or the courts of the State of Washington
                        in each case located in the County of King, and each
                        party irrevocably submits to the exclusive jurisdiction
                        of such courts in any such suit, action, or proceeding.
                        This section does not invalidate your rights under local
                        consumer laws as applicable.
                    </p>
                    <p>
                        18. <u>Miscellaneous</u>. This Agreement constitutes the
                        entire agreement and understanding between the parties
                        hereto with respect to the subject matter hereof and
                        supersedes all prior and contemporaneous understandings,
                        agreements, representations, and warranties, both
                        written and oral, with respect to such subject matter.
                        The language of this Agreement is English and any
                        translation of this Agreement into a language other than
                        English will be for reference purposes only. In the
                        event of a conflict of interpretation, the English
                        language will prevail. We will send notice to you by
                        email or express mail at the address that you provided
                        to us in connection with your use of the APIs. Emails
                        shall be deemed to have been received within 1 working
                        day from being sent. Any notices to us must be sent via
                        nationally recognized courier to: Expedia, 1111 Expedia
                        Group Way, Seattle, WA 98199 Attn: General Counsel. The
                        invalidity, illegality, or unenforceability of any
                        provision herein does not affect any other provision
                        herein or the validity, legality, or enforceability of
                        such provision in any other jurisdiction. There are no
                        third-party beneficiaries to this Agreement. Any failure
                        to act by either party with respect to a breach of this
                        Agreement does not constitute a waiver and will not
                        limit their rights with respect to such breach or any
                        subsequent breaches. This Agreement is personal to you
                        and may not be assigned or transferred for any reason
                        whatsoever without our prior written consent and any
                        assignment or transfer violation of the foregoing will
                        be void and without effect. We expressly reserve the
                        right to assign this Agreement and to delegate any of
                        our obligations hereunder to members of the Expedia
                        Group. By agreeing to the License Agreement, you also
                        agree to the Additional Terms (Connectivity Partner
                        Program Terms) located in Exhibit A.
                    </p>
                    <div className="heading center">
                        <strong>EXHIBIT A</strong>
                        <br />
                        <strong>Connectivity Partner</strong>
                        <br />
                        <strong>Program Terms (“Additional Terms”)</strong>
                    </div>
                    <p>
                        These Additional Terms (the “
                        <strong>Connectivity Program Partner Terms</strong>”)
                        govern your participation in Expedia’s Connectivity
                        Partner Program (the “<strong>Program</strong>”) and are
                        part of the Connectivity API License Agreement (the “
                        <strong>License Agreement</strong>”). All capitalized
                        terms not defined in these terms shall have the meanings
                        provided in the License Agreement.
                    </p>
                    <p>
                        1.{' '}
                        <strong>
                            Eligibility and Program Financial Incentives
                        </strong>
                        . Your eligibility to participate in the Connectivity
                        Partner Program will be determined by us in our sole
                        discretion in accordance with our current eligibility
                        guidelines which will be communicated to you. After your
                        participation in the Partner Program is confirmed by
                        Expedia in writing, and you are assigned to a Program
                        tier by Expedia, then provided that you remain in
                        compliance with the Agreement at all times during the
                        Term, you may be eligible to earn benefits and/or an
                        amount set by us as notified to you (a “
                        <strong>Financial Incentive</strong>”) for achieving
                        certain Objectives as defined by us and notified to you
                        from time to time.
                    </p>
                    <p>
                        An “<strong>Objective</strong>” means a target, metric
                        or completed action that you achieve or complete using a
                        method prescribed by us (if applicable), including by
                        target dates set by us. Benefits, Objectives and
                        Financial Incentives will be determined and calculated
                        by us based on our books and records.
                    </p>
                    <p>
                        2. <strong>Payments</strong>. . In the event you’re
                        eligible for a financial incentive, we will at the end
                        of each eligible calendar quarter, provide you with a
                        report of Objectives achieved and the Financial
                        Incentives earned during such quarter. You will provide
                        us with an invoice for such Financial Incentives within
                        60 days of receipt of such report. If you do not submit
                        your invoice in accordance with this Section 2 within 12
                        months of receipt of our report, then you waive your
                        right to payment for the relevant quarter.
                    </p>
                    <p>
                        3. <strong>Miscellaneous</strong>. Without limiting
                        anything in the Agreement, we may immediately terminate
                        or suspend your participation in the Program upon
                        written notice in the event that you breach the
                        Agreement or your Connection is associated with
                        fraudulent or illegal activity or might otherwise harm
                        other properties or travelers. If you are not in
                        compliance with your requirements under this Program at
                        any point during the Term as determined by us acting
                        reasonably, we may terminate your participation in the
                        Program upon 14 days’ written notice unless you come
                        back into compliance during that period. If your
                        participation in this Program is terminated, you will no
                        longer be entitled to any benefits or Financial
                        Incentives from that point onwards, however the License
                        Agreement will remain in effect. If your License
                        Agreement is terminated, these Connectivity Partner
                        Program Terms shall also by terminated. We make no
                        representations or warranties regarding any financial
                        results associated with this Program or the Financial
                        Incentives that the Program might generate for you. We
                        may make changes to this Program or these terms at any
                        time and will provide you with notice of any such
                        changes. Any Benefits or Financial Incentives provided
                        under this Program at any time, including before these
                        Additional Terms come into effect shall be deemed part
                        of this Agreement. You may terminate your participation
                        in this Program at any time by providing 14 days prior
                        written notice to us.
                    </p>
                </div>
            }
        ></Layout>
    );
};

LicenseAgreement.propTypes = {
    translations: PropTypes.object.isRequired,
};

export default LicenseAgreement;
