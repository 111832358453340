class XDIDHelper {
    // var adl = window.analyticsdatalayer,
    constructor(adl) {
        this.adl = adl;
    }

    /**
     * Wrapper for extracting ADL values with logic for falling back to other keys if necessary
     *
     * @param  {Array}  adlFields    List of fields in order of preference
     * @param  {*}      defaultValue The default value to use if no keys work
     * @return {*}                   The value a the first key to work or the default value
     */
    adlExtractorFallback = (adlFields, defaultValue) => {
        try {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < adlFields.length; ++i) {
                const val = this.adl[adlFields[i]];

                if (val && val !== '-1') {
                    return val;
                }
            }

            return defaultValue;
        } catch (e) {
            return defaultValue;
        }
    };

    /**
     * Wrapper for extracting ADL values
     *
     * @param  {*} adlField     The key to get a value for
     * @param  {*} defaultValue The default to use if the key is unset
     * @return {*}              The value for the key or the default value
     */
    adlExtractor = (adlField, defaultValue) =>
        this.adlExtractorFallback([adlField], defaultValue);

    /**
     *  Gets the contents of a cookie
     *
     *  @example
     *    getCookie('mycookie');
     *
     *  @param {String} [name] - name of the cookie
     *  @returns {Object} - The String cookie data or null
     */
    getCookie = (name) => {
        // Modified from: https://stackoverflow.com/a/25490531/34329
        let b = null;
        try {
            b = document.cookie.match(`(^|[^;]+)\\s*${name}\\s*=\\s*([^;]+)`);
        } catch (err) {
            // Silently eat these errors.
            // We will return null on failure.
        }

        return b ? b.pop() : null;
    };

    /**
     * Encapsulates the logic for getting a value based on fallbacks
     *
     * @return {String} The first id in the fallback chain that isn't null
     */
    getIdWithFallback = (fallbacks) => {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < fallbacks.length; ++i) {
            if (fallbacks[i] !== null) {
                return fallbacks[i];
            }
        }

        return '-1';
    };

    /**
     * Encapsulates logic of fallback for determining visitor ID
     * Fallback order: ADL.visitorid, 'hav' cookie, '-1'
     *
     * @return {String} The visitor ID
     */

    getVisitorId = () => {
        const idFallbacks = [
            this.adlExtractor('visitorid', null),
            this.getCookie('hav'),
            this.getCookie('DUAID'),
        ];

        return this.getIdWithFallback(idFallbacks);
    };

    /**
     * Load java script dynamically
     * @param {String}   url      The URL of the script to load
     * @param {*}        callback The code to call when the script is loaded. Can be null.
     * @oaram {*}        location The location in the dom to load the script. Can be null.
     *
     * @returns The created script element
     */
    loadScript = (url, callback, location) => {
        // Modified from: https://stackoverflow.com/a/31374433/34329
        const scriptTag = document.createElement('script');

        scriptTag.src = url;

        if (callback) {
            scriptTag.onload = callback;
            scriptTag.onreadystatechange = callback;
        }

        const loc = location || document.body;
        loc.appendChild(scriptTag);
        return scriptTag;
    };
}

export {XDIDHelper as default};
