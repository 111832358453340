import React, {useState} from 'react';
import Lottie from 'react-lottie';
import mainAnimationData1 from '../assets/lottie/01_MainScene.json';
import mainAnimationData2 from '../assets/lottie/02_MainScene_FloatCycle.json';
import mainAnimationData3 from '../assets/lottie/03_MainScene_Replay.json';

const animationDataArr = [
    mainAnimationData1,
    mainAnimationData2,
    mainAnimationData2,
    mainAnimationData2,
    mainAnimationData3,
];

const HeaderAnimation = () => {
    const [animationTargetIndex, setAnimationTargetIndex] = useState(0);

    return (
        <Lottie
            options={{
                loop: true,
                autoplay: true,
                animationData: animationDataArr[animationTargetIndex],
            }}
            eventListeners={[
                {
                    eventName: 'loopComplete',
                    callback: () => {
                        const loopStartIndex = 1;
                        const loopEndIndex = animationDataArr.length - 1;
                        if (animationTargetIndex < loopEndIndex) {
                            setAnimationTargetIndex((index) => index + 1);
                        } else if (animationTargetIndex === loopEndIndex) {
                            setAnimationTargetIndex(loopStartIndex);
                        }
                    },
                },
            ]}
        />
    );
};

export default HeaderAnimation;
