import PropTypes from 'prop-types';
import leadsFormStates from '../../../res/leadsFormStates';
import allUserTypes from '../../../res/userTypes';
import pciResponses from '../../../res/pciResponses';
import solutionTypeOptions from '../../../res/solutionTypes';

export const appStatePropType = PropTypes.oneOf(Object.values(leadsFormStates));

export const userTypePropType = PropTypes.oneOf(Object.values(allUserTypes));

export const pciCompliancePropType = PropTypes.oneOf(
    Object.values(pciResponses)
);

export const solutionPropType = PropTypes.oneOf(
    Object.values(solutionTypeOptions)
);
