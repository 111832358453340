import React, {Component} from 'react';
import Modal from 'react-modal';
import EGConnectivityIcon from '../../assets/connectivity_logo_blue_markup.js';
import UserType from '../UserType';
import ContactInfo from '../ContactInfo';
import ContactInfoNotice from '../ContactInfo/ContactInfoNotice';
import CompanyInfo from '../CompanyInfo';
import Complete from '../Complete';
import {LoadingOverlay} from '@homeaway/react-loading-overlay';
import AlertBox from '../../UIToolkit/AlertBox';
import {SVGIcon} from '@homeaway/react-svg';
import {CLOSE__24} from '@homeaway/svg-defs';
import LeadsFormModalFooter from '../LeadsFormModalFooter';
import {getEnv} from '../../../../util/getEnv';
import keys from '../../../../res/keys';
import leadsFormStates from '../../../../res/leadsFormStates';
import PropTypes from 'prop-types';
import {extPrefix} from '../../../../util/prefix';
import links from '../../../../util/navLinks';
import './styles.less';
import CurrentCustomer from '../CurrentCustomer/index.js';
import CurrentCustomerCheck from '../CurrentCustomerCheck';

class LeadsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            appState: leadsFormStates.CURRENT_CUSTOMER_CHECK,
            countryCode: '1',
        };
        this.leadsFormRef = React.createRef();
        this.recaptchaRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.appState !== this.state.appState) {
            this.leadsFormRef.current &&
                this.leadsFormRef.current.scrollTo(0, 0);
        }
    }

    componentDidMount() {
        let env = getEnv(window.location.host);
        const siteKey = keys[env].recaptcha.site_key_invisible;
        this.setState({
            siteKey,
        });
        Modal.setAppElement('body');
    }

    updateLeadsFormField = (field, value) => {
        this.setState({
            [`${field}`]: value,
        });
    };

    renderLeadsFormSection() {
        let toRender;
        const {translations} = this.props;
        const {appState} = this.state;
        const updateLeadsFormField = this.updateLeadsFormField;
        switch (appState) {
            case leadsFormStates.CURRENT_CUSTOMER_CHECK: {
                const handleCurrentCustomer = () => {
                    updateLeadsFormField(
                        'appState',
                        leadsFormStates.CURRENT_CUSTOMER
                    );
                };

                const handleNewCustomer = () => {
                    updateLeadsFormField('appState', leadsFormStates.USER_TYPE);
                };

                toRender = (
                    <CurrentCustomerCheck
                        translations={translations}
                        onCurrentCustomer={handleCurrentCustomer}
                        onNewCustomer={handleNewCustomer}
                    />
                );
                break;
            }
            case leadsFormStates.CURRENT_CUSTOMER: {
                const handleClose = () => {
                    location.href = `/${extPrefix}${links.WELCOME}`;
                };
                const handleBack = () => {
                    updateLeadsFormField(
                        'appState',
                        leadsFormStates.CURRENT_CUSTOMER_CHECK
                    );
                };
                toRender = (
                    <CurrentCustomer
                        translations={translations}
                        onBack={handleBack}
                        onClose={handleClose}
                    />
                );
                break;
            }
            case leadsFormStates.USER_TYPE:
                toRender = (
                    <UserType
                        translations={translations}
                        updateLeadsForm={this.updateLeadsFormField}
                        userType={this.state.userType}
                    />
                );
                break;
            case leadsFormStates.CONTACT_INFO_NOTICE:
                toRender = <ContactInfoNotice translations={translations} />;
                break;
            case leadsFormStates.CONTACT_INFO:
                toRender = (
                    <ContactInfo
                        translations={translations}
                        updateLeadsForm={this.updateLeadsFormField}
                        firstName={this.state.firstName}
                        firstNameError={this.state.firstNameError}
                        lastName={this.state.lastName}
                        lastNameError={this.state.lastNameError}
                        jobTitle={this.state.jobTitle}
                        jobTitleError={this.state.jobTitleError}
                        companyName={this.state.companyName}
                        companyNameError={this.state.companyNameError}
                        website={this.state.website}
                        websiteError={this.state.websiteError}
                        emailAddress={this.state.emailAddress}
                        emailAddressError={this.state.emailAddressError}
                        countryCode={this.state.countryCode}
                        country={this.state.country}
                        phoneNumber={this.state.phoneNumber}
                        phoneNumberError={this.state.phoneNumberError}
                        propertyCount={this.state.propertyCount}
                        propertyCountError={this.state.propertyCountError}
                        addressCountry={this.state.addressCountry}
                        addressLine1={this.state.addressLine1}
                        addressLine1Error={this.state.addressLine1Error}
                        addressLine2={this.state.addressLine2}
                        city={this.state.city}
                        cityError={this.state.cityError}
                        zip={this.state.zip}
                        zipError={this.state.zipError}
                        state={this.state.state}
                        stateError={this.state.stateError}
                    />
                );
                break;
            case leadsFormStates.COMPANY_INFO:
                toRender = (
                    <CompanyInfo
                        translations={translations}
                        recaptchaRef={this.recaptchaRef}
                        updateLeadsForm={this.updateLeadsFormField}
                        firstName={this.state.firstName}
                        solutionType={this.state.solutionType}
                        otherDistributors={this.state.otherDistributors}
                        otherDistributorsError={
                            this.state.otherDistributorsError
                        }
                        pciCompliant={this.state.pciCompliant}
                        userMessage={this.state.userMessage}
                        userMessageError={this.state.userMessageError}
                        siteKey={this.state.siteKey}
                    />
                );
                break;
            case leadsFormStates.COMPLETE:
                toRender = (
                    <Complete
                        translations={translations}
                        userType={this.state.userType}
                    />
                );
                break;
            default:
                toRender = null;
                break;
        }
        return toRender;
    }

    render() {
        const {appState} = this.state;
        const {translations, isOpen} = this.props;
        const isScreeningQuestions = [
            leadsFormStates.CURRENT_CUSTOMER_CHECK,
            leadsFormStates.CURRENT_CUSTOMER,
        ].some((step) => step === appState);
        const hideFooter = isScreeningQuestions;

        // Styles
        const completeStyle =
            this.state.appState === 'COMPLETE' ? ' complete-page-wrapper' : '';
        const screeningStyle = isScreeningQuestions ? ' screening' : '';
        const leadsFormModalContentStyles = `leads-form-modal-content${completeStyle}${screeningStyle}`;

        return (
            <Modal isOpen={isOpen} className="leads-form-modal">
                <div className="leads-form-modal-header">
                    <a href={`/${extPrefix}${links.WELCOME}`}>
                        <div className="header-logo-svg">
                            <SVGIcon markup={EGConnectivityIcon} />
                        </div>
                    </a>
                    {/** Do not change data-testid attr */}
                    <a
                        href={`/${extPrefix}`}
                        data-testid="leads-form-close-btn"
                    >
                        <SVGIcon markup={CLOSE__24} />
                    </a>
                </div>
                <div
                    className="leads-form-content-wrapper"
                    ref={this.leadsFormRef}
                >
                    <div className={leadsFormModalContentStyles}>
                        {this.state.loading && (
                            <LoadingOverlay loading={true} />
                        )}
                        {this.state.error && (
                            <AlertBox
                                message={this.state.error}
                                updateLeadsForm={this.updateLeadsFormField}
                            />
                        )}
                        {this.renderLeadsFormSection()}
                    </div>
                </div>
                {hideFooter ? null : (
                    <LeadsFormModalFooter
                        updateLeadsForm={this.updateLeadsFormField}
                        {...this.state}
                        recaptchaRef={this.recaptchaRef}
                        translations={translations}
                    />
                )}
            </Modal>
        );
    }
}

LeadsForm.propTypes = {
    isOpen: PropTypes.bool,
    translations: PropTypes.object,
};

export default LeadsForm;
