import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {extPrefix} from '../../../util/prefix';
import links from '../../../util/navLinks';
import Button from '../UIToolkit/Button';
import Layout from '../PageLayout';
import formatContent from '../util/formatContent';
import {ButtonPrimary} from '@homeaway/react-buttons';
import {
    triggerEdapPageView,
    triggerEdapButtonClick,
    eventCategories,
    triggerEdapLinkClick,
    eventPageName,
} from '../util/edap';
import {getDocsPageHostUrl} from '../util/redirectUrls';
import serverSvg from '../assets/server.svg';
import phoneSvg from '../assets/phone.svg';
import laptopSvg from '../assets/laptop.svg';
import reservationsSvg from '../assets/reservations.svg';
import sheildSvg from '../assets/sheild.svg';
import personPinSvg from '../assets/person_pin.svg';
import settingsSvg from '../assets/settings.svg';
import worldwideSvg from '../assets/worldwide.svg';
import skewedBoxSvg from '../assets/skewed_box.svg';
import ImageBgSvg from '../assets/image_bg.svg';
import Border from '../util/Border/Border.js';
import HeaderAnimation from './HeaderAnimation';
import './stylesheets/styles.less';

const FAQ_COUNT = 3;
const COMINGSOON_COUNT = 6;

const audienceBannerOptions = [
    {
        img: serverSvg,
    },
    {
        img: phoneSvg,
    },
    {
        img: laptopSvg,
    },
];

const stepOptions = [
    {
        img: reservationsSvg,
    },
    {
        img: sheildSvg,
    },
    {
        img: personPinSvg,
    },
    {
        img: settingsSvg,
    },
    {
        img: worldwideSvg,
    },
];

const LandingPage = ({translations, prodTestConfig}) => {
    const [hostUrl, setHostUrl] = useState('');
    const [prodTest, setProdTestConfig] = useState({});
    const comingSoonOptions = [];
    for (
        let comingSoonIndex = 1;
        comingSoonIndex <= COMINGSOON_COUNT;
        comingSoonIndex++
    ) {
        comingSoonOptions.push({
            heading:
                translations[
                    `landingPage.coming.soon.option${comingSoonIndex}.heading`
                ],
            label:
                translations[
                    `landingPage.coming.soon.option${comingSoonIndex}.label`
                ],
            truncatedLabel:
                translations[
                    `landingPage.coming.soon.option${comingSoonIndex}.truncatedlabel`
                ],
            link:
                translations[
                    `landingPage.coming.soon.option${comingSoonIndex}.link`
                ],
            content:
                translations[
                    `landingPage.coming.soon.option${comingSoonIndex}.content`
                ],
        });
    }
    if (prodTest.enableCertificationTool) {
        comingSoonOptions[1].heading =
            translations['landingPage.coming.soon.option3.cert.heading'];
        comingSoonOptions[1].label =
            translations['landingPage.coming.soon.option3.cert.label'];
        comingSoonOptions[1].truncatedLabel =
            translations['landingPage.coming.soon.option3.cert.truncatedlabel'];
        comingSoonOptions[1].link =
            translations['landingPage.coming.soon.option3.cert.link'];
        comingSoonOptions[1].content =
            translations['landingPage.coming.soon.option3.cert.content'];
    }
    useEffect(() => {
        triggerEdapPageView('Landing Page');
        const hostUrl = getDocsPageHostUrl();
        if (hostUrl) {
            setHostUrl(hostUrl);
        }
    }, []);
    useEffect(() => {
        if (prodTestConfig) {
            setProdTestConfig({...prodTestConfig});
        }
    }, [prodTestConfig]);

    const header = (
        <div className="landing-page__header-content__wrapper">
            <div className="landing-page__header-content">
                <div className="landing-page__header-content__left">
                    <div className="sub-heading">
                        {translations['landingPage.header.subheading']}
                    </div>
                    <div className="heading">
                        {translations['landingPage.header.title']}
                    </div>
                    {/** Do not change dataTestId prop */}
                    <Button
                        dataTestId="get-started-header-btn"
                        additionalClassNames="header-button"
                        label={
                            translations['landingPage.header.getStarted.label']
                        }
                        link={{
                            href: `/${extPrefix}/${links.CONTACT}`,
                            external: true,
                        }}
                        onClick={triggerEdapButtonClick(
                            eventCategories.LANDING,
                            'Header section - Get started'
                        )}
                    />
                </div>
                <div className="landing-page__header-content__right">
                    <HeaderAnimation />
                </div>
            </div>
            <Border />
        </div>
    );

    const introSection = (
        <div className="landing-page__content__intro">
            <h2 className="section-heading">
                {translations['landingPage.intro.heading']}
            </h2>
            <p className="section-content">
                {translations['landingPage.intro.desc']}
            </p>
        </div>
    );

    const audienceBanner = (
        <div className="landing-page__content__audience-banner">
            <div className="audience-banner__options">
                {audienceBannerOptions.map(({img}, i) => (
                    <div
                        key={`audience-banner-${i}`}
                        className="audience-banner__option"
                    >
                        <h3>
                            {
                                translations[
                                    `landingPage.audience.banner.option${
                                        i + 1
                                    }.heading`
                                ]
                            }
                        </h3>
                        <div className="audience-banner__option__img">
                            <img src={img} alt="" />
                        </div>
                        <p className="audience-banner__option__content section-content">
                            {
                                translations[
                                    `landingPage.audience.banner.option${
                                        i + 1
                                    }.content`
                                ]
                            }
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );

    const stepSection = (
        <div className="landing-page__content__step">
            <h2 className="section-heading">
                {translations['landingPage.step.heading']}
            </h2>
            <p className="section-content">
                {translations['landingPage.step.desc']}
            </p>
            <div className="step__options__wrapper">
                <div className="step__options">
                    {stepOptions.map(({img}, i) => (
                        <div
                            key={`step-${i}`}
                            className="step__option__wrapper"
                        >
                            <div className="step__option">
                                <div className="step-option-skewed-box">
                                    <img src={skewedBoxSvg} alt="" />
                                </div>
                                <div className="step-option-bg-img">
                                    <img src={ImageBgSvg} alt="" />
                                </div>
                                <h6 className="heading">
                                    {
                                        translations[
                                            `landingPage.step.option${
                                                i + 1
                                            }.heading`
                                        ]
                                    }
                                </h6>
                                <p className="section-content">
                                    {
                                        translations[
                                            `landingPage.step.option${
                                                i + 1
                                            }.content`
                                        ]
                                    }
                                </p>
                                <div className="step-option-img">
                                    <img src={img} alt="" />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {/** Do not change dataTestId prop */}
            <Button
                primary
                dataTestId="get-started-step-btn"
                additionalClassNames="get-started-button"
                label={'Get started'}
                link={{
                    href: `/${extPrefix}/${links.CONTACT}`,
                    external: true,
                }}
                onClick={triggerEdapButtonClick(
                    eventCategories.LANDING,
                    'Integration section - Get started'
                )}
            />
        </div>
    );

    const cppOverviewSection = (
        <div className="landing-page__content__cpp">
            <h2 className="section-heading">
                {translations['landingPage.cpp.heading']}
            </h2>
            <p className="section-content">
                {translations['landingPage.cpp.content']}
            </p>
            {/** Do not change dataTestId prop */}
            <Button
                primary
                dataTestId="cpp-overview-learn-more-btn"
                additionalClassNames="learn-more-button"
                label={translations['landingPage.cpp.button.label']}
                link={{
                    href: `/${extPrefix}/cpp/${links.CPP_OVERVIEW}`,
                    external: true,
                }}
                onClick={triggerEdapLinkClick(
                    eventCategories.CONTENT_LINKS,
                    eventPageName.CPP_OVERVIEW_PAGE
                )}
            />
        </div>
    );

    const faqs = [];
    for (let faqIndex = 1; faqIndex <= FAQ_COUNT; faqIndex++) {
        faqs.push(
            <div
                key={`faq-${faqIndex}`}
                className={
                    faqIndex < FAQ_COUNT - 1 ? 'faq-item' : 'faq-item bottom'
                }
            >
                {translations[`landingPage.faq.question${faqIndex}link`] ? (
                    <p className="faq-question">
                        <a
                            href={
                                translations[
                                    `landingPage.faq.question${faqIndex}link`
                                ]
                            }
                        >
                            {
                                translations[
                                    `landingPage.faq.question${faqIndex}`
                                ]
                            }
                        </a>
                    </p>
                ) : (
                    <p className="faq-question">
                        {translations[`landingPage.faq.question${faqIndex}`]}
                    </p>
                )}
                <p className="faq-answer">
                    {formatContent(
                        translations[`landingPage.faq.answer${faqIndex}`]
                    )}
                </p>
            </div>
        );
    }

    const faqSection = (
        <div className="landing-page__content__faq">
            <h3 className="section-heading">
                {translations['landingPage.faq.title']}
            </h3>
            <div className="faq-wrapper">{faqs}</div>
        </div>
    );

    const comingSoonSection = (
        <div className="landing-page__content__coming-soon">
            <h3 className="section-heading">
                {translations['landingPage.coming.soon.heading']}
            </h3>
            <div className="coming-soon__options">
                {comingSoonOptions.map(
                    (
                        {
                            heading,
                            label,
                            truncatedLabel,
                            link,
                            content,
                            disableButton,
                        },
                        i
                    ) => (
                        <div
                            className="coming-soon__each-option"
                            key={i.toString()}
                        >
                            <h6 className="heading">{heading}</h6>
                            <p className="section-content">{content}</p>
                            {disableButton ? (
                                <span>
                                    <ButtonPrimary
                                        size="xs"
                                        label={
                                            <>
                                                <span className="longerBtnLabel">
                                                    {
                                                        translations[
                                                            `landingPage.coming.soon.option.comingsoon`
                                                        ]
                                                    }
                                                </span>
                                                <span className="shorterBtnLabel">
                                                    {
                                                        translations[
                                                            `landingPage.coming.soon.option.comingsoon`
                                                        ]
                                                    }
                                                </span>
                                            </>
                                        }
                                    />
                                </span>
                            ) : (
                                <ButtonPrimary
                                    size="xs"
                                    label={
                                        <>
                                            <span className="longerBtnLabel">
                                                {label}
                                            </span>
                                            <span className="shorterBtnLabel">
                                                {truncatedLabel}
                                            </span>
                                        </>
                                    }
                                    href={`${hostUrl}${link}`}
                                    onClick={triggerEdapButtonClick(
                                        eventCategories.LANDING,
                                        label
                                    )}
                                    target="_blank"
                                />
                            )}
                        </div>
                    )
                )}
            </div>
        </div>
    );

    return (
        <Layout
            activeIndex={0}
            translations={translations}
            header={header}
            prodTestConfig={prodTest}
            content={[
                introSection,
                <Border key="border-1" inverse />,
                audienceBanner,
                <Border key="border-2" />,
                stepSection,
                cppOverviewSection,
                <Border key="border-3" inverse />,
                faqSection,
                <Border key="border-4" />,
                comingSoonSection,
            ]}
        />
    );
};

LandingPage.propTypes = {
    translations: PropTypes.object.isRequired,
    prodTestConfig: PropTypes.object,
};

export default LandingPage;
