import React from 'react';
import PropTypes from 'prop-types';
import {Navigation} from '@homeaway/react-navigation';
import {ButtonIcon} from '@homeaway/react-buttons';
import {MENU__24, SEARCH__16} from '@homeaway/svg-defs';
import EGConnectivityIcon from '../assets/connectivity_logo_white.svg';

const Header = ({
    primary,
    activeIndex,
    children,
    logoLink,
    navLinks,
    onMobileMenuClick,
    onHeaderLinkClick,
    onSearchIconClick,
}) => {
    return (
        <div className={`layout__header ${primary && 'primary'}`}>
            <div className="site__header">
                <div className="header__left">
                    <ButtonIcon
                        className="header__navmenu"
                        noBorder
                        label="menu"
                        svgMarkup={MENU__24}
                        onClick={onMobileMenuClick}
                    />
                    <a href={logoLink}>
                        <img
                            className="header-logo"
                            src={EGConnectivityIcon}
                            alt="expedia group logo"
                        />
                    </a>
                </div>
                <div
                    className={`header__right ${
                        activeIndex >= 0 ? 'has-active-index' : null
                    }`}
                >
                    <div className="navigation-section">
                        <Navigation
                            activeIndex={activeIndex < 0 ? 0 : activeIndex}
                            links={navLinks}
                            onLinkClick={onHeaderLinkClick}
                        />
                    </div>
                </div>
                <div className="header__right-search">
                    <ButtonIcon
                        id="searchIcon"
                        className="header__search-icon"
                        noBorder
                        tabIndex="1"
                        label="search icon"
                        svgMarkup={SEARCH__16}
                        onClick={onSearchIconClick}
                    />
                </div>
            </div>
            {children}
        </div>
    );
};

Header.propTypes = {
    primary: PropTypes.bool,
    activeIndex: PropTypes.number.isRequired,
    logoLink: PropTypes.string.isRequired,
    navLinks: PropTypes.arrayOf(
        PropTypes.shape({
            node: PropTypes.element.isRequired,
        })
    ).isRequired,
    onMobileMenuClick: PropTypes.func.isRequired,
    onHeaderLinkClick: PropTypes.func.isRequired,
    onSearchIconClick: PropTypes.func.isRequired,
    children: PropTypes.element.isRequired,
    tutorialUrl: PropTypes.string,
    enableLogin: PropTypes.bool,
};

export default Header;
