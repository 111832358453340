const countries = require('i18n-iso-countries');

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const countryNameOverrides = {
    VN: 'Vietnam',
};

module.exports = {
    countries: {
        ...countries.getNames('en'),
        ...countryNameOverrides,
    },
};
