import React from 'react';
import PropTypes from 'prop-types';
import PostCard from '../assets/postcard.svg';
import Layout from '../PageLayout';
import './styles.less';

const NotFound = ({translations}) => {
    const content = (
        <div className="bermuda-triangle">
            <div className="postcard__envelope container">
                <div className="postcard__wrapper">
                    <img className="postcard" src={PostCard} alt="" />
                </div>
            </div>
        </div>
    );

    return <Layout translations={translations} content={content} />;
};

NotFound.propTypes = {
    translations: PropTypes.object.isRequired,
};

export default NotFound;
