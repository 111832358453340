const Request = require('superagent');
const ErrorStackParser = require('error-stack-parser');
const {CLIENT_ERROR_LOGGING_ENDPOINT, CRUMB_KEY} = require('../config');
const ERROR_CODES = require('./codes');

/**
 * Prepare payload for sending to server
 * @param {string} code
 * @param {Error} error
 *
 * @returns {{code:string, stack_trace: *[]}}
 */
const process_client_error = (code, error) => {
    try {
        return {
            code,
            stack_trace: ErrorStackParser.parse(error),
        };
    } catch (err) {
        return {
            code: code,
            stack_trace: ErrorStackParser.parse(err),
        };
    }
};

/**
 * Log error to server
 * @param {string} code
 * @param {Error} error
 */
const logError = ({code, error}) => {
    const payload = process_client_error(code, error);
    Request.post(CLIENT_ERROR_LOGGING_ENDPOINT)
        .send(payload)
        .set(CRUMB_KEY, window.crumb)
        .catch(() => {
            /* do nothing*/
        });
};

if (!(typeof window === 'undefined')) {
    window.onerror = function (msg, url, lineNo, columnNo, error) {
        logError({code: ERROR_CODES.ERROR_CODE_1001, error});
        return false;
    };

    window.onunhandledrejection = function (event = {}) {
        const hasStack =
            event.detail && event.detail.reason && event.detail.reason.stack;

        const error = new Error('unhandled promise rejection');

        if (hasStack) {
            error.stack = event.detail.reason.stack;
        }

        logError({
            code: ERROR_CODES.ERROR_CODES_1002,
            error,
        });
    };
}

export default {
    logError,
};
